import {Svgs} from "@assets";
import {APP_NAVIGATE, IProduct, ScreenId} from "@data";
import {useDownload, useRNPost} from "@hook";
import {COLOR, encryptQuery, getProductPrice, handleClickBubble} from "@utils";
import {FontText, Img} from "@components";
import _ from "lodash";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "./style.module.scss";

interface IProps {
  item: IProduct;
}

export default function ProductItem({item}: IProps) {
  const navigation = useNavigate();
  const {openDownloadAppModal} = useDownload();
  const {RNpostMessage} = useRNPost();
  const {isApp} = useRecoilValue(getParameterState);

  const isSoldOut =
    (item.stock || 0) <= 0 && (item.stock_date?.stock_count || 0) <= 0;
  const mainBadgeList = (item.badge || []).filter(
    badge => badge.badgeInfo?.main_yn === "Y" && !!badge.badgeInfo.img_url,
  );
  const badgeList = (item.badge || []).filter(
    badge => badge.badgeInfo?.main_yn === "N",
  );
  const img_url = item?.item_thumbnail_img || item?.item_img;

  const productPrice = getProductPrice({product: item});

  async function insertCart() {
    if (!item.no) {
      return;
    }
    if (isApp) {
      RNpostMessage({
        action: "insert_cart",
        id: item.no,
        product: item,
      });
    } else {
      openDownloadAppModal();
    }
  }

  async function gotoDetail() {
    if (!item.no) {
      return;
    }
    if (isApp) {
      RNpostMessage({
        action: "navigate",
        screenId: APP_NAVIGATE.PRODUCT,
        id: item.no,
        product: item,
        data: {item_no: item.no},
      });
    } else {
      navigation(
        `${ScreenId.PRODUCT_DETAIL}?k=${encryptQuery({query: `${item.no}`})}`,
        {state: {preview_item: item}},
      );
    }
  }

  const throttleInsertCart = _.throttle(insertCart, 1000);
  const throttleGotoDetail = _.throttle(gotoDetail, 1000);

  return (
    <div className={styles.container} onClick={throttleGotoDetail}>
      <div className={styles.imgContainer}>
        {img_url ? <Img src={img_url} className={styles.img} /> : <></>}
        <div
          className={styles.cart}
          onClick={e => {
            handleClickBubble(e);
            throttleInsertCart();
          }}>
          <Svgs.CartIcon />
        </div>
        <div className={styles.mainBadgeContainer}>
          {(mainBadgeList || []).slice(0, 3).map(_item => {
            return (
              <div key={_.uniqueId()} className={styles.badgeItem}>
                <Img src={_item.badgeInfo?.img_url} />
              </div>
            );
          })}
        </div>
      </div>
      <FontText lineHeight={150} className={styles.title}>
        [{item.partners?.partner_name}] {item.item_name}
      </FontText>
      <div className={styles.priceContainer}>
        {!isSoldOut ? (
          <>
            {item.view_price !== productPrice && (
              <FontText
                lineHeight={150}
                fontWeight={700}
                color={COLOR.red}
                className={styles.percent}>
                {Math.ceil(
                  100 -
                    (Number(productPrice || 0) / Number(item.view_price || 0)) *
                      100,
                )}
                %
              </FontText>
            )}
            <div className={styles.price}>
              {productPrice.toLocaleString()}원
            </div>
            {item.view_price !== productPrice && (
              <div className={styles.viewPrice}>
                {(item.view_price || 0).toLocaleString()}원
              </div>
            )}
          </>
        ) : (
          <div className={styles.soldOut}>일시품절</div>
        )}
      </div>
      {(item.review_cnt || 0) > 0 && (
        <div className={styles.reviewContainer}>
          <div className={styles.starIcon}>
            <Svgs.StarIcon fill={COLOR.green} width={10} />
          </div>
          <div className={styles.score}>{item.avg_score}</div>
          <div className={styles.reviewCnt}>
            후기{" "}
            {(item.review_cnt || 0) > 9999 ? "9999+" : item.review_cnt || 0}
          </div>
        </div>
      )}
      {badgeList.length > 0 && (
        <div className={styles.badgeContainer}>
          {(badgeList || []).map(_item => (
            <div
              key={_.uniqueId()}
              className={styles.badgeItem}
              style={{backgroundColor: _item.badgeInfo?.backgroundColor}}>
              {_item.badgeInfo?.icon === "zoopi" ? (
                <Svgs.ZoopiIcon width={13} />
              ) : (
                <></>
              )}
              <span style={{color: _item.badgeInfo?.textColor}}>
                {_item.badgeInfo?.title}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
