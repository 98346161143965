import React from "react";

export default function Svg({
  width = 20,
  stroke = "#222222",
  fill,
}: {
  width?: number;
  stroke?: string;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={(width / 20) * 22}
      viewBox="0 0 20 22"
      fill="none">
      <path
        d="M18.5 9.875V18.875C18.5 19.3723 18.3025 19.8492 17.9508 20.2008C17.5992 20.5525 17.1223 20.75 16.625 20.75H3.375C2.87772 20.75 2.40081 20.5525 2.04917 20.2008C1.69754 19.8492 1.5 19.3723 1.5 18.875V9.875M13.75 5L10 1.25M10 1.25L6.25 5M10 1.25V14.0469"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
