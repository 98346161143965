import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 20,
  stroke = COLOR.gray4,
}: {
  width?: number;
  stroke?: string;
}) {
  return (
    <svg
      width={width}
      height={(Number(width) / 20) * 21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 5.5L5 15.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5.5L15 15.5"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
