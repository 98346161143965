import React from "react";
import styles from "./style.module.scss";
import FontText from "components/FontText";
import {COLOR} from "@utils";

function getFontText({
  text,
  color = COLOR.gray4,
  link,
}: {
  text: string;
  color?: COLOR;
  link?: string;
}) {
  function onClick() {
    if (link) {
      window.open(link, "_blank");
    }
  }

  return (
    <div
      onClick={onClick}
      style={{display: "flex", ...(link ? {cursor: "pointer"} : {})}}>
      <FontText fontSize={12} lineHeight={132} fontWeight={500} color={color}>
        {text}
      </FontText>
    </div>
  );
}

export default function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.linkContainer}>
        <div className={styles.item}>
          {getFontText({
            text: "이용약관",
            link: "https://zootopia-event.s3.ap-northeast-2.amazonaws.com/policy/terms_of_service1.pdf",
          })}
        </div>
        <div className={styles.item}>
          {getFontText({
            text: "개인정보처리방침",
            link: "https://zootopia-event.s3.ap-northeast-2.amazonaws.com/policy/policy1.pdf",
            color: COLOR.gray3,
          })}
        </div>
      </div>
      <div className={styles.descContainer}>
        <div className={styles.item}>
          {getFontText({text: "주식회사 주토피아프레시"})}
          <div className={styles.division}></div>
          {getFontText({text: "대표자 : 김하유"})}
        </div>
        <div className={styles.item}>
          {getFontText({text: "사업자등록번호 : 170-88-02101"})}
        </div>
        <div className={styles.item}>
          {getFontText({text: "통신판매업 : 2021-서울강남-04609"})}
        </div>
        <div className={styles.item}>
          {getFontText({
            text: "주소 : 서울특별시 강남구 영동대로 602, 6층 에이치111호 (삼성동)",
          })}
        </div>
        <div className={styles.item}>
          {getFontText({text: "팩스 : 070-4024-0202"})}
          <div className={styles.division}></div>
          {getFontText({text: "이메일 :"})}
          {getFontText({text: "contact@zootopia.co.kr", color: COLOR.green})}
        </div>
        <div className={styles.item}>
          {getFontText({text: "개인정보보호책임자 : 김하유"})}
        </div>
        <div className={styles.item}>
          {getFontText({text: "구매안전(에스크로)서비스"})}
          {getFontText({
            text: "가입 사실 확인 >",
            color: COLOR.gray5,
            link: "https://pg.nicepay.co.kr/issue/IssueEscrow.jsp?CoNo=1708802101",
          })}
        </div>
      </div>
    </div>
  );
}
