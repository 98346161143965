import React, {useEffect} from "react";
import {RootNavigation} from "@navigation";
import {
  getParameterObject,
  getCookie,
  setCookie,
  cookie_token_key,
  deleteCookie,
} from "@utils";
import {GATracker} from "@container";
import {useMember} from "@hook";
import {useSetRecoilState} from "recoil";
import {getParameterState} from "recoils/atoms";
import {createInstance, HackleProvider} from "@hackler/react-sdk";
import {envUtil} from "@utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";

const hackleConfig = {
  debug: envUtil.STAGE !== "production",
};
const hackleClient = createInstance(envUtil.HACKLE_BROWSER_KEY, hackleConfig);

export default function App() {
  const {user, getUser} = useMember();
  const setParameterValue = useSetRecoilState(getParameterState);
  GATracker();

  useEffect(() => {
    const {
      token: app_token,
      isApp,
      OS,
      isHeader,
      isBottom,
      safeTop,
      safeBottom,
      type,
      no,
    } = getParameterObject({
      url: window.location.search,
    });

    setParameterValue({
      isApp: isApp === "true",
      OS,
      isHeader: isHeader !== "false",
      isBottom: isBottom !== "false",
      safeArea: {
        top: !isNaN(Number(safeTop)) ? Number(safeTop) : 0,
        bottom: !isNaN(Number(safeBottom)) ? Number(safeBottom) : 0,
      },
      ...(!!type && {landing: {type, blog_no: no}}),
    });

    if (isApp) {
      deleteCookie({name: cookie_token_key});
      if (app_token) {
        setCookie({name: cookie_token_key, value: app_token});
        getUser();
      }
      document.documentElement.style.setProperty("--MAX-WIDTH-NUMBER", "10000");
    } else {
      const token = getCookie({name: cookie_token_key});
      if (token) {
        getUser();
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      hackleClient.setUser({
        userId: user.phone_number,
        properties: {
          is_member: true,
          nickname: user.nickname,
          created_at: String(user.createdAt),
        },
      });
    } else {
      hackleClient.resetUser();
    }
  }, [user]);

  return (
    <HackleProvider hackleClient={hackleClient}>
      <RootNavigation />
    </HackleProvider>
  );
}
