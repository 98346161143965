import {Svgs} from "@assets";
import {DotText, FontText} from "@components";
import {IProductDetailGuarantee, TProductItemDetail} from "@data";
import {useCommon} from "@hook";
import {COLOR} from "@utils";
import moment from "moment";
import React from "react";
import styles from "../style.module.scss";

export function GuaranteeItem({
  data,
  stock,
  stock_date,
  insert_date,
  made_date,
  limit_date,
}: {
  data?: TProductItemDetail[];
  stock: number;
  stock_date?: moment.Moment;
  insert_date?: moment.Moment;
  made_date?: string;
  limit_date?: string;
}) {
  const {isproductGuaranteeHide, toggleIsproductGuaranteeHide} = useCommon();
  const list = ((data || []).filter(
    _data => _data?.type === "productGuarantee",
  ) || []) as IProductDetailGuarantee[];
  const item = list?.[0];
  const isStock = stock > 0;
  const stockDate = isStock
    ? !!insert_date && moment(insert_date).isValid()
      ? moment(insert_date)
      : undefined
    : !!stock_date && moment(stock_date).isValid()
    ? moment(stock_date)
    : undefined;

  function toggle() {
    toggleIsproductGuaranteeHide({hide: !isproductGuaranteeHide});
  }

  if (!item) {
    return <></>;
  }

  return (
    <div className={styles.productGuarantee}>
      <div onClick={toggle} className={styles.touchContainer}>
        <div className={styles.left}>
          <div className={styles.svg}>
            <Svgs.ProductGuaranteeSvg />
          </div>
          <div>
            <FontText fontSize={16} fontWeight={700} color={COLOR.green}>
              {item.data_json?.title || "주토피아프레시 소비 보장제"}
            </FontText>
          </div>
        </div>
        <div
          className={[
            styles.chevron,
            !isproductGuaranteeHide && styles.open,
          ].join(" ")}>
          <Svgs.ChevronIcon />
        </div>
      </div>
      <div
        className={[
          styles.guaranteeInfo,
          !isproductGuaranteeHide && styles.open,
        ].join(" ")}>
        {(item?.data_json?.data || []).map((_text, index) => (
          <DotText key={index} text={_text} size={14} color={COLOR.gray3} />
        ))}
      </div>
      {
        <div className={styles.dateRow}>
          <div className={styles.stockDate}>
            <div className={styles.title}>
              <FontText lineHeight={150} color={COLOR.gray4}>
                {isStock ? "입고일자" : "입고예정일"}
              </FontText>
            </div>
            <div>
              <FontText
                fontSize={16}
                lineHeight={150}
                fontWeight={700}
                color={COLOR.orangeSub2}>
                {!!stockDate && moment(stockDate).isValid()
                  ? moment(stockDate).format("YYYY.MM.DD")
                  : "-"}
              </FontText>
            </div>
          </div>
          <div className={styles.stockDate}>
            <div className={styles.title}>
              <FontText lineHeight={150} color={COLOR.gray4}>
                제조일자
              </FontText>
            </div>
            <div>
              <FontText
                fontSize={16}
                lineHeight={150}
                fontWeight={500}
                color={COLOR.gray1}>
                {isStock ? made_date : "-"}
              </FontText>
            </div>
          </div>
          <div className={styles.stockDate}>
            <div className={styles.title}>
              <FontText lineHeight={150} color={COLOR.gray4}>
                소비기한
              </FontText>
            </div>
            <div className={styles.content}>
              <FontText
                fontSize={16}
                lineHeight={150}
                fontWeight={500}
                color={COLOR.gray1}>
                {isStock ? limit_date : "-"}
              </FontText>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
