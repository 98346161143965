import {Svgs} from "@assets";
import {IProductDetailRecipe, TProductItemDetail} from "@data";
import {useCommon, useHackleTrack} from "@hook";
import {COLOR, isDefined} from "@utils";
import {FontText, Img, Tooltip} from "@components";
import _ from "lodash";
import React, {useState} from "react";
import {IProductImageModal} from "..";
import styles from "../style.module.scss";

const imgClass: {[key in number]: string} = {
  1: styles.one,
  2: styles.two,
  3: styles.three,
  4: styles.four,
  5: styles.five,
};

export function ItemOriginItem({
  item_no,
  data,
  setImageModal,
}: {
  item_no: number;
  data: TProductItemDetail[];
  setImageModal?: (value: IProductImageModal) => void;
}) {
  const {isCheckpointTooltip, closeCheckpointTooltip} = useCommon();
  const {hackleTrack} = useHackleTrack();
  const [open, setOpen] = useState(true);

  const itemOrigin = ((data || []).filter(
    item => item?.type === "itemOrigin",
  )[0] || {}) as IProductDetailRecipe;
  const imgList = (itemOrigin.data_json?.data || [])
    .map(_item => _item.thumbnail_img_url || _item.img_url)
    .filter(isDefined)
    .slice(0, 5);

  function getDetail() {
    hackleTrack({
      key: "click__material_detail",
      properties: {
        product_no: String(item_no),
      },
    });
    setImageModal?.({
      isVisible: true,
      title: "원산지",
      image: itemOrigin.data_json?.data,
      sub_title: itemOrigin.data_json?.title,
    });
  }

  if (!itemOrigin && imgList.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.itemOriginItem}>
      <div className={styles.container}>
        <div className={styles.titleContainer} onClick={() => setOpen?.(!open)}>
          <div className={styles.right}>
            <Svgs.PlantsSvg />
            <FontText
              fontSize={16}
              lineHeight={150}
              fontWeight={700}
              color={COLOR.green}>
              재료를 눈으로 확인해보세요
            </FontText>
          </div>
          <div className={styles.chevron}>
            <Svgs.ChevronIcon rotate={open ? 180 : 0} />
          </div>
        </div>

        {!!open && isCheckpointTooltip && imgList.length > 0 && (
          <div className={styles.tootipContainer}>
            <div>
              <Tooltip
                content={
                  <FontText
                    lineHeight={150}
                    fontWeight={600}
                    color={COLOR.white}>
                    누르면 영상으로 원재료를 확인할 수 있어요!
                  </FontText>
                }
                width={180}
                close={closeCheckpointTooltip}
                backgroundColor={COLOR.orangeSub}
              />
            </div>
          </div>
        )}

        <div
          className={[styles.contentContainer, open && styles.open].join(" ")}>
          {imgList.length === 0 ? (
            <FontText lineHeight={150}>사진을 준비중입니다.</FontText>
          ) : (
            <>
              <div className={styles.imgContainer} onClick={getDetail}>
                <div
                  className={[styles.row, imgClass[imgList.length]].join(" ")}>
                  {imgList
                    .filter((img_url, i) => [0].includes(i))
                    .map((img_url, i) => (
                      <div key={_.uniqueId()} className={styles.item}>
                        <Img src={img_url} />
                      </div>
                    ))}
                  <div className={styles.left}>
                    {imgList
                      .filter((img_url, i) =>
                        [3, 5].includes(imgList.length)
                          ? [1, 2].includes(i)
                          : [1].includes(i),
                      )
                      .map((img_url, i) => (
                        <div key={_.uniqueId()} className={styles.item}>
                          <Img src={img_url} />
                        </div>
                      ))}
                  </div>
                </div>
                <div
                  className={[styles.bottom, imgClass[imgList.length]].join(
                    " ",
                  )}>
                  {[4, 5].includes(imgList.length) &&
                    imgList
                      .filter((img_url, i) =>
                        imgList.length === 4
                          ? [2, 3, 4].includes(i)
                          : [3, 4].includes(i),
                      )
                      .map((img_url, i) => (
                        <div key={_.uniqueId()} className={styles.item}>
                          <Img src={img_url} />
                        </div>
                      ))}
                </div>
              </div>
              {!!itemOrigin.data_json?.title && (
                <div className={styles.title}>
                  <FontText>{itemOrigin.data_json?.title}</FontText>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
