import {Svgs} from "@assets";
import React from "react";
import styles from "./style.module.scss";

export function EmptyProductImage() {
  return (
    <div className={styles.emptyProductImage}>
      <Svgs.ZoopiIcon />
    </div>
  );
}
