import React from "react";
import styles from "./style.module.scss";
import FontText from "components/FontText";
import {COLOR} from "@utils";
import {APP_NAVIGATE} from "@data";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import {useDownload, useMember, useRNPost, useReactGA} from "@hook";

export default function SignOnCouponSection() {
  const {isApp} = useRecoilValue(getParameterState);
  const {goToStore, openDownloadAppModal} = useDownload();
  const {GAEventTrigger} = useReactGA();
  const {user} = useMember();
  const {RNpostMessage} = useRNPost();

  function onClick() {
    if (isApp) {
      gotoNavigate({screenId: APP_NAVIGATE.LOGIN});
    } else {
      goToStore();
      GAEventTrigger({
        action: "click_step4",
        category: "click_step4",
        label: "회원가입유도 띠배너 클릭",
        value: 1,
      });
    }
  }

  function gotoNavigate({
    screenId,
    data,
  }: {
    screenId: APP_NAVIGATE;
    data?: {[key: string]: string | number | undefined};
  }) {
    if (isApp) {
      RNpostMessage({action: "navigate", screenId, data});
    } else {
      openDownloadAppModal();
    }
  }

  if (user) {
    return <></>;
  }

  return (
    <div onClick={onClick} className={styles.signOnCouponSection}>
      <div className={styles.container}>
        <div className={styles.text}>
          <FontText color={COLOR.white} fontWeight={500}>
            지금 가입하고{" "}
          </FontText>
          <FontText color={COLOR.orangeSub2} fontWeight={500}>
            6천원 쿠폰, 또또우유 100원
          </FontText>
          <FontText color={COLOR.white} fontWeight={500}>
            {" "}
            혜택 받기 {">"}
          </FontText>
        </div>
      </div>
    </div>
  );
}
