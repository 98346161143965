import React from "react";

export default function Svg({
  width = 22,
  stroke = "#BDBDBD",
  fill,
}: {
  width?: number;
  stroke?: string;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M8.14373 19.7821V16.7152C8.14372 15.9381 8.77567 15.3067 9.55844 15.3018H12.4326C13.2189 15.3018 13.8563 15.9346 13.8563 16.7152V16.7152V19.7732C13.8563 20.4473 14.404 20.9951 15.0829 21H17.0438C17.9596 21.0023 18.8388 20.6428 19.4872 20.0007C20.1356 19.3586 20.5 18.4868 20.5 17.5775V8.86585C20.5 8.13139 20.1721 7.43471 19.6046 6.9635L12.943 1.67427C11.7785 0.749123 10.1154 0.779008 8.98539 1.74538L2.46701 6.9635C1.87274 7.42082 1.51755 8.11956 1.5 8.86585V17.5686C1.5 19.4637 3.04738 21 4.95617 21H6.87229C7.19917 21.0023 7.51349 20.8751 7.74547 20.6464C7.97746 20.4178 8.10793 20.1067 8.10792 19.7821H8.14373Z"
        stroke={stroke}
        strokeWidth="1.5"
        fill={fill}
      />
    </svg>
  );
}
