import React, {useLayoutEffect} from "react";
import {WebNavigation} from "@navigation";
import {Wrapper} from "@components";
import {useCommon} from "@hook";

export default function RootNavigation() {
  const {getCommon} = useCommon();

  useLayoutEffect(() => {
    getCommon();
  }, []);

  return (
    <Wrapper>
      <WebNavigation />
    </Wrapper>
  );
}
