import {ZOOTOPIA_APP_LINK} from "@assets";
import {envUtil} from "@utils";
import React from "react";
import {atom, useRecoilState} from "recoil";
import useReactGA from "./useReactGA";

const downloadModalState = atom<boolean>({
  key: "downloadModalKey",
  default: false,
});

export default function useDownload() {
  const [isOpen, setIsOpen] = useRecoilState(downloadModalState);
  const {GAEventTrigger} = useReactGA();

  function goToDownload() {
    GAEventTrigger({
      action: "click_step3",
      category: "click_step3",
      label: "쿠폰 받으러 가기 클릭",
      value: 1,
    });
    if (envUtil.STAGE !== "development") {
      goToStore();
    }
  }

  function goToStore() {
    window.location.href = ZOOTOPIA_APP_LINK;
  }

  function openDownloadAppModal() {
    setIsOpen(true);
  }

  function closeDownloadAppModal() {
    setIsOpen(false);
  }

  return {
    isDownloadAppModal: isOpen,
    openDownloadAppModal,
    closeDownloadAppModal,
    goToDownload,
    goToStore,
  };
}
