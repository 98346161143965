import {api, deleteCookie, cookie_token_key} from "@utils";
import React from "react";
import {useRecoilState} from "recoil";
import {userState} from "recoils/atoms";

export default function useMember() {
  const [user, setUser] = useRecoilState(userState);

  async function login({sns_id, sns_type}: {sns_id: string; sns_type: string}) {
    // TODO
  }

  async function getUser() {
    try {
      const {data, success} = await api.v1.member.getMember();
      if (!!success && !!data) {
        setUser(data);
      } else if (!success) {
        logOut();
      }
    } catch (error) {
      console.error("useUser getUser error", error);
    }
  }

  async function logOut() {
    try {
      await deleteCookie({name: cookie_token_key});
      setUser(undefined);
    } catch (error) {
      console.error("useUser logOut error", error);
    }
  }

  return {
    user,
    getUser,
    login,
    logOut,
  };
}
