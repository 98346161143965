import {Svgs} from "@assets";
import {FontText, Modal} from "@components";
import {COLOR} from "@utils";
import React from "react";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "../style.module.scss";

export function MaterialModal({
  visibleMaterialModal,
  close,
}: {
  visibleMaterialModal: boolean;
  close: () => void;
}) {
  const {safeArea} = useRecoilValue(getParameterState);

  return (
    <Modal
      visible={visibleMaterialModal}
      close={close}
      position={{bottom: 0}}
      className={styles.materialModal}>
      <div className={styles.wrapper}>
        <div className={styles.titleRow}>
          <FontText
            color={COLOR.black}
            fontSize={20}
            fontWeight={700}
            lineHeight={150}>
            원재료가 왜 중요한가요?
          </FontText>
          <div onClick={close}>
            <Svgs.CloseFullSvg stroke={COLOR.gray1} />
          </div>
        </div>
        <div className={styles.contentRow}>
          <Svgs.CheckBoxOnSvg fill={COLOR.middleGreenColor} />
          <FontText
            fontWeight={600}
            fontSize={16}
            lineHeight={150}
            color={COLOR.gray3}>
            어떤 재료가 단백질원으로 사용되었는지 알 수 있어요.
          </FontText>
        </div>
        <div className={styles.contentRow}>
          <Svgs.CheckBoxOnSvg fill={COLOR.middleGreenColor} />
          <FontText
            fontWeight={600}
            fontSize={16}
            lineHeight={150}
            color={COLOR.gray3}>
            재료의 종류에 따라 단백질의 함량과 품질이 달라요.
          </FontText>
        </div>
        <div className={styles.contentRow}>
          <Svgs.CheckBoxOnSvg fill={COLOR.middleGreenColor} />
          <FontText
            fontWeight={600}
            fontSize={16}
            lineHeight={150}
            color={COLOR.gray3}>
            단백질 원에 따라 소화율이 달라요.
          </FontText>
        </div>
        <div className={styles.bottomBtn} onClick={close}>
          <FontText fontWeight={600} fontSize={16} lineHeight={150}>
            닫기
          </FontText>
        </div>
        <div style={{height: safeArea?.bottom || 0}} />
      </div>
    </Modal>
  );
}
