import React from "react";

export default function Svg({
  width = 19,
  stroke = "#16161D",
  fill,
}: {
  width?: number;
  stroke?: string;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 19 19"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3019 16.168C5.68873 16.168 6.00315 16.4824 6.00315 16.8692C6.00315 17.2561 5.68873 17.5696 5.3019 17.5696C4.91506 17.5696 4.60156 17.2561 4.60156 16.8692C4.60156 16.4824 4.91506 16.168 5.3019 16.168Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6153 16.168C16.0021 16.168 16.3166 16.4824 16.3166 16.8692C16.3166 17.2561 16.0021 17.5696 15.6153 17.5696C15.2285 17.5696 14.9141 17.2561 14.9141 16.8692C14.9141 16.4824 15.2285 16.168 15.6153 16.168Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.01953 0.980469L2.9262 1.31047L3.80895 11.8274C3.88045 12.6845 4.59636 13.3426 5.4562 13.3426H15.4589C16.2802 13.3426 16.9769 12.7395 17.0951 11.9255L17.965 5.91397C18.0723 5.17239 17.4975 4.50872 16.7486 4.50872H3.23236"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
