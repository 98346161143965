import React from "react";

export default function Svg({
  width = 25,
  stroke = "#BDBDBD",
  fill,
}: {
  width?: number;
  stroke?: string;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={(width / 25) * 24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8015 11.0992C14.845 11.0992 16.5016 9.44267 16.5016 7.39922C16.5016 5.35577 14.845 3.69922 12.8015 3.69922C10.7581 3.69922 9.10156 5.35577 9.10156 7.39922C9.10156 9.44267 10.7581 11.0992 12.8015 11.0992Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1011 20.2977C18.5011 20.2977 19.5011 18.7977 18.9011 17.4977C17.8011 15.1977 15.501 13.5977 12.801 13.5977C10.101 13.5977 7.70107 15.1977 6.70107 17.4977C6.10107 18.7977 7.10106 20.2977 8.50106 20.2977H17.1011Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
