import {envUtil, getParameterObject} from "@utils";
import ReactGA from "react-ga4";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";

export default function useReactGA() {
  const {isApp} = useRecoilValue(getParameterState);
  const {isApp: tempIsParamsApp} = getParameterObject({
    url: window.location.search,
  });
  const isParamsApp = (tempIsParamsApp || "").toLocaleLowerCase() === "true";

  const GAEventTrigger = ({
    action,
    category,
    label,
    value,
  }: {
    action: string;
    category: string;
    label: string;
    value: number;
  }) => {
    if (!envUtil.IS_DEV && !isApp && !isParamsApp) {
      ReactGA.event({
        action: action,
        category: category,
        label: label,
        value: value,
      });
    }
  };

  return {
    GAEventTrigger,
  };
}
