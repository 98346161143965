import {FontText} from "@components";
import {IProductDetailInformation, TProductItemDetail} from "@data";
import {COLOR, isDefined} from "@utils";
import React from "react";
import styles from "../style.module.scss";

export function InformationItem({
  data,
  isInformationnNextDate = false,
}: {
  data: TProductItemDetail[];
  isInformationnNextDate?: boolean;
}) {
  const item =
    data &&
    ((data.filter(_data => _data.type === "information")[0] ||
      {}) as IProductDetailInformation);

  const itemList = (item?.data_json?.data || [])
    .filter(_item => _item.content)
    .filter(isDefined);

  if (!item || itemList.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.informationItem}>
      <div className={styles.container}>
        <div className={styles.title}>
          <FontText fontSize={16} lineHeight={150} fontWeight={700}>
            상품정보
          </FontText>
        </div>
        {itemList
          .filter(_item => !!_item.content)
          .map((_item, index) => (
            <div key={index} className={styles.item}>
              <div className={styles.itemTitle}>
                <FontText lineHeight={150} color={COLOR.gray4}>
                  {_item.title}
                </FontText>
              </div>
              <div className={styles.contentContainer}>
                <FontText lineHeight={150} fontWeight={500}>
                  {_item.content}
                  {!!_item.subcontent && ` ${_item.subcontent}`}
                </FontText>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
