import {Svgs} from "@assets";
import {FontText, Modal} from "@components";
import {
  APP_NAVIGATE,
  IDefaultProduct,
  IToast,
  TDeliveryType,
  TPrevPage,
} from "@data";
import {
  useCommon,
  useDownload,
  useHackleTrack,
  useMember,
  useRNPost,
} from "@hook";
import {
  calcDeliveryDate,
  calcDeliveryDateText,
  COLOR,
  dateWeekdaySimpleFormat,
  getCalcOrderPrice,
  getProductPrice,
} from "@utils";
import React, {useState} from "react";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import {toast, ToastContainer} from "react-toastify";
import styles from "./style.module.scss";
import moment from "moment";

interface IProps {
  visible: boolean;
  item: IDefaultProduct;
  close?: () => void;
  rating?: string;
  pre_page_name?: TPrevPage;
  nowDeliveryType: TDeliveryType;
  plusDeliveryFee?: number;
}

export function ProductBuyModal({
  visible,
  item,
  close,
  rating,
  pre_page_name,
  nowDeliveryType,
  plusDeliveryFee = 0,
}: IProps) {
  const {deliveryBlockDate} = useCommon();
  const {user} = useMember();
  const {hackleTrack} = useHackleTrack();
  const {openDownloadAppModal} = useDownload();
  const {RNpostMessage} = useRNPost();
  const {safeArea, isApp} = useRecoilValue(getParameterState);

  const [count, setCount] = useState(1);

  const nowStock =
    ((item.stock || 0) > 0 ? item.stock : item.stock_date?.stock_count) || 0;
  const isSoldOut = (item.stock || 0) <= 0 && !!item.stock_date?.stock_date;
  const isNextStockDate =
    isSoldOut ||
    (nowDeliveryType === "일반" &&
      moment().diff(moment(item.common_delivery__order_limit_datetime)) > 0) ||
    (nowDeliveryType === "도서산간" &&
      moment().diff(moment(item.plus_delivery__order_limit_datetime)) > 0);
  const nextStockDate = calcDeliveryDate({
    delivery_type: nowDeliveryType,
    orderDate: moment(item.stock_date?.stock_date),
    common_delivery__order_limit_datetime:
      item.common_delivery__order_limit_datetime,
    plus_delivery__order_limit_datetime:
      item.plus_delivery__order_limit_datetime,
    next_stock_date: item.next_stock_date,
    delivery_block_date: deliveryBlockDate,
  });
  const isIncludeFrozen = item?.item_fresh === "N";
  const isIncludeFresh = item?.item_fresh === "Y";
  const {
    delivery_price: nowDeliveryFee,
    free_delivery_price: FREE_DELIVERY_PRICE,
  } = getCalcOrderPrice({
    plus_price: plusDeliveryFee,
    item_total_price: 0,
    delivery_type: nowDeliveryType,
    isIncludeFrozen,
    isIncludeFresh,
  });

  const productPrice = getProductPrice({product: item});

  function checkUserInfo({callback}: {callback?: () => void}) {
    if (user) {
      callback?.();
    } else {
      gotoNavigate({screenId: APP_NAVIGATE.LOGIN});
    }
  }

  function gotoNavigate({
    screenId,
    data,
  }: {
    screenId: APP_NAVIGATE;
    data?: {[key: string]: string | number | undefined};
  }) {
    if (isApp) {
      RNpostMessage({action: "navigate", screenId, data});
    } else {
      openDownloadAppModal();
    }
  }

  async function createCart() {
    hackleTrack({
      key: "click__add_to_cart",
      properties: {
        is_member: !!user,
        phone_number: user?.id,
        pre_page_name,
        product_no: String(item?.no),
        category_no: String((item.category_list || []).join()),
        product_name: item?.item_name,
        price: productPrice,
        view_price: item?.view_price || 0,
        rating: !isNaN(Number(rating)) ? Number(rating) : undefined,
        is_delivery: nowDeliveryType ?? "일반",
      },
    });
    if (isApp) {
      RNpostMessage({action: "insert_cart", data: {count}, product: item});
      close?.();
    } else {
      openDownloadAppModal();
    }
  }

  function pressPlus() {
    if (count >= nowStock) {
      return setToast({
        svg: <Svgs.WarningSvg />,
        text: `${nowStock}개 까지만 추가할 수 있어요!`,
        bottomButton: true,
      });
    }
    if (count >= (item.item_order_limit || 0)) {
      return setToast({
        svg: <Svgs.WarningSvg />,
        text: `${item.item_order_limit || 0}개 까지만 추가할 수 있어요!`,
        bottomButton: true,
      });
    }
    setCount(count + 1);
  }

  function setToast(data: IToast) {
    try {
      if (isApp) {
        const {svg, ...toastParam} = data;
        const toastData = {
          data: {
            ...(toastParam || {}),
            svgIcon: data.svg === <Svgs.CheckSvg /> ? "check" : "warning",
          },
        };
        RNpostMessage({action: "toast", ...toastData});
      } else {
        toast(data.text, {toastId: "toast"});
      }
    } catch (error) {
      console.error("setToast error", error);
    }
  }

  return (
    <>
      <Modal
        visible={visible}
        close={close}
        className={styles.productBuyModal}
        position={{bottom: 0}}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <div className={styles.topContainer}>
              <FontText
                fontSize={16}
                lineHeight={150}
                fontWeight={600}
                color={COLOR.black}>
                {item.item_name}
              </FontText>
              <div className={styles.priceRow}>
                <div className={styles.price}>
                  <FontText
                    fontSize={18}
                    lineHeight={150}
                    fontWeight={700}
                    color={COLOR.green}>
                    {productPrice.toLocaleString()}
                  </FontText>
                  <FontText
                    fontSize={18}
                    lineHeight={150}
                    fontWeight={700}
                    color={COLOR.black}>
                    원
                  </FontText>
                </div>
                <div className={styles.btnContainer}>
                  <div
                    className={styles.countChangeBtn}
                    onClick={() => count > 1 && setCount(count - 1)}>
                    <Svgs.MinusIconSvg />
                  </div>
                  <div
                    className={[styles.countChangeBtn, styles.count].join(" ")}>
                    <FontText>{count}</FontText>
                  </div>
                  <div className={styles.countChangeBtn} onClick={pressPlus}>
                    <Svgs.PlusIconSvg />
                  </div>
                </div>
              </div>
              <div>
                {(item.stock || 0) <= 5 && (item.stock || 0) > 2 && (
                  <div className={styles.stockAlert}>
                    <Svgs.WarningSvg fill={COLOR.stockYellow} width={12} />
                    <FontText
                      fontSize={12}
                      lineHeight={150}
                      fontWeight={500}
                      color={COLOR.stockYellow}>
                      {item.stock}개 남았어요!
                    </FontText>
                  </div>
                )}
                {(item.stock || 0) <= 2 && (item.stock || 0) > 0 && (
                  <div className={styles.stockAlert}>
                    <Svgs.WarningSvg fill={COLOR.red500} width={12} />
                    <FontText
                      fontSize={12}
                      lineHeight={150}
                      fontWeight={500}
                      color={COLOR.red500}>
                      품절 임박! 얼른 구매해주세요.
                    </FontText>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.line} />
            {isNextStockDate ? (
              <div className={styles.nextStockDate}>
                <div className={styles.title}>
                  <FontText lineHeight={150} fontWeight={600}>
                    배송일
                  </FontText>
                </div>
                <div className={styles.box}>
                  <Svgs.WarningSvg width={20} fill={COLOR.redDD1616} />
                  <div className={styles.text}>
                    <div className={styles.textTitle}>
                      <FontText
                        lineHeight={150}
                        fontWeight={700}
                        color={COLOR.redDD1616}>
                        {dateWeekdaySimpleFormat(nextStockDate.toDate())}
                      </FontText>
                      <FontText
                        lineHeight={150}
                        fontWeight={600}
                        color={COLOR.redDD1616}>
                        {" "}
                        도착 가능
                      </FontText>
                    </div>
                    <FontText lineHeight={150}>
                      다른 제품과 구매 시 배송 도착일이 변경됩니다. 상품을
                      신선하게 준비해 보내 드리겠습니다.
                    </FontText>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.stockDate}>
                <div className={styles.title}>
                  <FontText lineHeight={150} fontWeight={600}>
                    {nowDeliveryType === "도서산간" ? "도착예정일" : "배송일"}
                  </FontText>
                </div>
                <FontText lineHeight={150} fontWeight={600}>
                  {nowDeliveryType === "도서산간"
                    ? calcDeliveryDateText({
                        delivery_type: nowDeliveryType,
                        delivery_block_date: deliveryBlockDate,
                      })
                    : dateWeekdaySimpleFormat(
                        calcDeliveryDate({
                          delivery_type: nowDeliveryType,
                          delivery_block_date: deliveryBlockDate,
                        }).toDate(),
                      )}
                </FontText>
              </div>
            )}
            <div className={styles.rowItem}>
              <div className={styles.title}>
                <FontText lineHeight={150} fontWeight={600}>
                  배송유형
                </FontText>
              </div>
              <div className={styles.content}>
                <Svgs.DeliveryIcon width={20} />
                <FontText fontWeight={700} color={COLOR.green}>
                  {nowDeliveryType === "새벽"
                    ? "주피배송"
                    : nowDeliveryType === "도서산간"
                    ? "도서산간지역 배송"
                    : "일반배송"}
                </FontText>
              </div>
            </div>
            <div className={styles.deliveryFeeContainer}>
              <div className={styles.title}>
                <FontText lineHeight={150} fontWeight={600}>
                  배송비
                </FontText>
              </div>
              <div className={styles.content}>
                <div className={styles.deliveryFee}>
                  <FontText lineHeight={150} fontWeight={600}>
                    {`${nowDeliveryFee.toLocaleString()}원`}
                  </FontText>
                </div>
                {nowDeliveryType !== "도서산간" && (
                  <>
                    <FontText
                      fontSize={12}
                      lineHeight={150}
                      fontWeight={500}
                      color={COLOR.grayC5C5C5}>
                      {FREE_DELIVERY_PRICE.toLocaleString()}원 이상 구매 시 무료
                    </FontText>
                    <FontText
                      fontSize={12}
                      lineHeight={150}
                      fontWeight={500}
                      color={COLOR.grayC5C5C5}>
                      {nowDeliveryType === "새벽" &&
                        "*제주 외 도서산간지역 배송 시 추가비용 발생"}
                      {nowDeliveryType === "일반" &&
                        `*제주 외 도서산간지역 추가 ${(1000).toLocaleString()}원 이상 추가`}
                    </FontText>
                  </>
                )}
              </div>
            </div>
            <div className={styles.bottomContainer}>
              <div
                className={styles.btn}
                onClick={() => checkUserInfo({callback: createCart})}>
                <FontText fontSize={16} color={COLOR.white} fontWeight={700}>
                  장바구니 담기
                </FontText>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        theme="light"
        containerId="copyToast"
        toastClassName={styles.toast}
        style={{marginBottom: `${safeArea?.bottom || 0}px`}}
      />
    </>
  );
}
