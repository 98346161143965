import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 20,
  stroke = COLOR.gray1,
}: {
  width?: number;
  stroke?: string;
}) {
  return (
    <svg
      width={width}
      height={(Number(width) / 20) * 18}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 16L1 9M1 9L9 2M1 9H19"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
