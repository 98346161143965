import {useInfiniteScroll, useRNPost} from "@hook";
import {FontText, Img, Loader, PullToRefresh} from "@components";
import {IPage, IPostListResponse} from "data";
import _ from "lodash";
import React, {useEffect, useRef, useState} from "react";
import styles from "./style.module.scss";
import {PostItem} from "./PostItem";
import {Svgs} from "@assets";
import {PostItemSkeleton} from "./PostItemSkeleton";
import {api, COLOR} from "@utils";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";

const containerId = "postPageContainer";

export default function PostPage() {
  const {isApp} = useRecoilValue(getParameterState);
  const {RNpostMessage} = useRNPost();
  const [listData, setListData] = useState<IPostListResponse[]>([]);
  const [paging, setPaging] = useState<IPage>();
  const [scrollTop, setScrollTop] = useState(0);
  const [loading, setLoading] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    throttleGetList({page: (paging?.cur || 0) + 1});
  };
  const {isLoading} = useInfiniteScroll({
    refElement: listRef,
    fetchData,
  });

  const throttleGetList = _.throttle(getList, 1000);

  async function getList({
    page = 1,
    loading = false,
  }: {page?: number; loading?: boolean} = {}) {
    try {
      if (!!paging && (paging?.maxpage || 0) <= (paging?.cur || 0)) {
        return;
      }
      if (loading) {
        setLoading(true);
      }
      const {data, success, message} = await api.v1.post.getList({
        page,
      });
      if (success) {
        setLoading(false);
        if ((paging?.cur || 0) < (data.result.page?.cur || 1)) {
          setListData([...listData, ...(data?.result?.result || [])]);
          setPaging(data?.result?.page);
        }
      } else {
        alert(message);
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  function toTop() {
    document
      .getElementById(containerId)
      ?.scrollTo({top: 0, behavior: "smooth"});
  }

  function messageListener(event: any) {
    if (!event || !event.data) {
      return;
    }

    try {
      const {action} = JSON.parse(event.data);
      if (action === "reload") {
        getList();
      }
    } catch (error) {
      console.warn("messageListener error", error);
    }
  }

  function linkingBanner() {
    try {
      const link = "https://forms.gle/hvE5BUUK6qkdbbfh9";
      if (isApp) {
        RNpostMessage({action: "linking", data: link});
      } else {
        window.open(link);
      }
    } catch (error) {
      console.error("linkingBanner error", error);
    }
  }

  useEffect(() => {
    getList();

    document?.addEventListener("message", messageListener);
    window?.addEventListener("message", messageListener);

    return () => {
      document?.removeEventListener("message", messageListener);
      window?.removeEventListener("message", messageListener);
    };
  }, []);

  return (
    <>
      <div
        id={containerId}
        className={styles.container}
        ref={containerRef}
        onScroll={e => {
          setScrollTop(e.currentTarget.scrollTop);
        }}>
        <div className={styles.bannerContainer} onClick={linkingBanner}>
          <Img src="https://zootopia-event.s3.ap-northeast-2.amazonaws.com/util/postBanner.png" />
          <div className={styles.textContainer}>
            <FontText fontSize={18} fontWeight={600}>
              주피탐험대 모집
            </FontText>
            <FontText color={COLOR.gray4} fontSize={12}>
              매달 3만원 활동 지원금 제공해 드려요.
            </FontText>
          </div>
        </div>
        {/* <PullToRefresh
          onRefresh={() => getList({loading: true})}
          el={containerRef}
        /> */}
        {loading ? (
          <>
            <PostItemSkeleton />
            <PostItemSkeleton />
            <PostItemSkeleton />
          </>
        ) : (
          <>
            {listData.map((item, index) => (
              <PostItem key={String(item.no)} item={item} index={index} />
            ))}
          </>
        )}
        <div ref={listRef} />
        {isLoading && (
          <div className="center">
            <Loader />
          </div>
        )}
      </div>
      <div
        className={[
          styles.floatingContainer,
          scrollTop > 0 && styles.show,
        ].join(" ")}>
        <div className={styles.toTopIcon} onClick={toTop}>
          <Svgs.ArrowTop />
        </div>
      </div>
    </>
  );
}
