import {DEFAULT_PROFILE_IMG_URL, Svgs} from "@assets";
import {
  api,
  COLOR,
  copyURL,
  encryptQuery,
  formatTime,
  handleClickBubble,
  isDefined,
} from "@utils";
import {APP_NAVIGATE, IPostListResponse, ScreenId} from "data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import {useDownload, useHackleTrack, useMember, useRNPost} from "@hook";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import {toast, ToastContainer} from "react-toastify";
import styles from "./style.module.scss";
import {Img} from "@components";

interface IProps {
  item: IPostListResponse;
  index?: number;
}

export function PostItem({item, index}: IProps) {
  const {user} = useMember();
  const {hackleTrack} = useHackleTrack();
  const {openDownloadAppModal} = useDownload();
  const {RNpostMessage} = useRNPost();
  const navigation = useNavigate();
  const {isApp} = useRecoilValue(getParameterState);
  const [isLike, setIsLike] = useState(!!item.my_like_history);
  const [isRecipeShow, setIsRecipeShow] = useState(true); // useState(index === 0);

  const imageList = (item.detail || [])
    .sort((a, b) => (a.ordering || 0) - (b.ordering || 0))
    .map(_item => _item.photos)
    .filter(isDefined)
    .reduce((acc, cur) => [...acc, ...cur], [])
    .map(_item => _item.thumbnail_img_url || _item.img_url)
    .filter(isDefined)
    .slice(0, 3);
  const isDetail = (item.detail || []).length > 0;

  const likeMemberList = [
    ...(!!isLike && !!user ? [user] : []),
    ...(item.like_history?.member_list || []).filter(
      _member => _member.no !== user?.no,
    ),
  ];
  const likeCount = likeMemberList.length;

  async function setLike() {
    try {
      if (!item.no) {
        return;
      }
      if (!user) {
        if (isApp) {
          RNpostMessage({
            action: "navigate",
            screenId: APP_NAVIGATE.LOGIN,
          });
        } else {
          openDownloadAppModal();
        }
        return;
      }
      const {success} = await api.v1.post.setLike({
        post_no: item.no,
        isLike: !isLike,
      });
      if (success) {
        setIsLike(!isLike);
      }
    } catch (error) {
      console.log("setLike error", error);
    }
  }

  async function goDetail() {
    if (!item.no) {
      return;
    }
    hackleTrack({
      key: "click__post_detail",
      properties: {
        post_no: String(item.no),
        isApp: isApp ? "TRUE" : "FALSE",
      },
    });
    if (isApp) {
      RNpostMessage({
        action: "navigate",
        screenId: APP_NAVIGATE.POST_DETAIL,
        id: item.no,
        data: {no: item.no},
      });
    } else {
      navigation(
        `${ScreenId.POST_DETAIL}?k=${encryptQuery({query: `${item.no}`})}`,
      );
    }
  }

  async function openCombinationModal() {
    if (!item.no) {
      return;
    }
    if (isApp) {
      RNpostMessage({
        action: "combination_modal",
        data: (item.recipe_item || []).map(_item => _item.product),
        id: item.no,
      });
    } else {
      openDownloadAppModal();
    }
  }

  function copyShareLink() {
    if (!item.no) {
      return;
    }
    hackleTrack({
      key: "click__post_share",
      properties: {
        post_no: String(item.no),
        isApp: isApp ? "TRUE" : "FALSE",
      },
    });
    if (isApp) {
      RNpostMessage({
        action: "share",
        data: copyURL(
          `${ScreenId.POST_DETAIL}?k=${encryptQuery({query: `${item.no}`})}`,
        ),
      });
    } else {
      window.navigator.clipboard.writeText(
        copyURL(
          `${ScreenId.POST_DETAIL}?k=${encryptQuery({query: `${item.no}`})}`,
        ),
      );
      toast("복사되었습니다.", {toastId: "copyToast"});
    }
  }

  const throttleSetLike = _.throttle(setLike, 1000);
  const throttleGoDetail = _.throttle(goDetail, 1000);
  const throttleOpenCombinationModal = _.throttle(openCombinationModal, 1000);

  useEffect(() => {
    setTimeout(() => setIsRecipeShow(false), 0);
  }, []);

  useEffect(() => {
    setIsLike(!!item?.my_like_history);
  }, [item]);

  return (
    <>
      <div className={styles.itemContainer}>
        <div className={styles.profileContainer}>
          <div className={styles.profileImgContainer}>
            <Img src={item.member?.profile_img || DEFAULT_PROFILE_IMG_URL} />
          </div>
          <div className={styles.profileText}>
            <div className={styles.profileName}>
              {item.member?.nickname || item.member?.name}
            </div>
            <div className={styles.profileTime}>
              {formatTime(item.open_date || item.createdAt)}
            </div>
          </div>
        </div>
        {imageList.length > 0 && (
          <div className={styles.imgContainer} onClick={throttleGoDetail}>
            <Slider
              swipeToSlide={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              dots={true}
              dotsClass={[
                styles.sliderDots,
                isDetail && styles.isPlusBottom,
              ].join(" ")}
              focusOnSelect={false}
              appendDots={(dots: any) => (
                <div>
                  <ul>{dots}</ul>
                </div>
              )}>
              {imageList.map(img_url => (
                <div key={_.uniqueId()}>
                  <Img src={img_url} />
                </div>
              ))}
            </Slider>
            <div
              className={[styles.recipeContainer].join(" ")}
              onClick={e => {
                handleClickBubble(e);
                throttleOpenCombinationModal();
              }}>
              <Svgs.RecipeSvg />
              <div>
                <span
                  className={
                    isRecipeShow ? styles.recipeShow : styles.recipeHide
                  }>
                  모든 재료 보기
                </span>
              </div>
            </div>
          </div>
        )}
        {!!isDetail && (
          <div className={styles.detailSection} onClick={throttleGoDetail}>
            <div className={styles.detailSectionText}>레시피 구경가기</div>
            <div className={styles.detailSectionArrow}>{">"}</div>
          </div>
        )}
        <div className={styles.iconSection}>
          <div className={styles.likeIcon} onClick={throttleSetLike}>
            {isLike ? (
              <Svgs.HeartIcon width={20} fill={COLOR.red} stroke={COLOR.red} />
            ) : (
              <Svgs.HeartIcon width={20} stroke={COLOR.gray3} />
            )}
          </div>
          <div className={styles.shareIcon} onClick={copyShareLink}>
            <Svgs.ShareIcon width={18} stroke={COLOR.gray3} />
          </div>
        </div>
        {likeCount > 0 && (
          <div className={styles.replySection}>
            <div className={styles.replyProfile}>
              {[
                ...(!!isLike && !!user ? [user] : []),
                ...(item.like_history?.member_list || []).filter(
                  _member => _member.no !== user?.no,
                ),
              ]
                .slice(0, 3)
                .reverse()
                .map(_member => (
                  <Img key={_.uniqueId()} src={_member.profile_img} />
                ))}
            </div>
            <div className={styles.replyCount}>
              {likeCount > 2 ? "여러" : likeCount.toLocaleString()}명이
              좋아합니다.
            </div>
          </div>
        )}
        <div className={styles.titleSection} onClick={throttleGoDetail}>
          <div className={styles.title}>{item.title}</div>
          <div className={styles.content}>{item.content}</div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        theme="light"
        containerId="copyToast"
      />
    </>
  );
}
