import {MILK_ITEM_NO_LIST, REVIEW_EDIT_LIMIT_DAY, Svgs} from "@assets";
import {
  EmptyProductImage,
  FontText,
  Img,
  ProductItem,
  ProductQnaItem,
  ReviewItem,
  Tooltip,
  CommonModal,
  SignOnCouponSection,
} from "@components";
import {
  APP_NAVIGATE,
  IDawnShippingAreaFlag,
  IDBItemsOption,
  IDefaultProduct,
  IHomeProduct,
  IProduct,
  IProductDetail,
  IProductDetailImgDetail,
  IProductOptions,
  IProductQna,
  IProductReview,
  IReviewPhoto,
  ISelectItemMaterial,
  IToast,
  ResultPagingResponse,
  ScreenId,
} from "@data";
import {
  useDownload,
  useHackleTrack,
  useMember,
  useParams,
  useReactGA,
  useRNPost,
} from "@hook";
import {
  api,
  COLOR,
  copyURL,
  encryptQuery,
  getParameterObject,
  isDefined,
  calcDeliveryDateText,
  decryptQuery,
  calcDeliveryDate,
  dateFormat,
  handleClickBubble,
  getCalcOrderPrice,
  getProductPrice,
} from "@utils";
import _ from "lodash";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import Slider from "react-slick";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {getParameterState, headerState} from "recoils/atoms";
import styles from "./style.module.scss";
import {toast, ToastContainer} from "react-toastify";
import moment from "moment";
import useCommon from "hook/useCommon";
import {
  CheckPointItem,
  CheckPointNewItem,
  GuaranteeItem,
  ImgDetailItem,
  MaterialItem,
  NoticeItem,
  PostSection,
  RecipeItem,
  TopReviewSection,
  Interview2Item,
  ItemOriginItem,
  CompositionItem,
  FeedAmountItem,
  FeedItem,
  InformationItem,
  StorageItem,
  MaterialModal,
} from "./items";
import {ProductBuyModal} from "./ProductBuyModal";
import {ProductBuySubscribeModal} from "./ProductBuySubscribeModal";
import {ProductDetailSignOnCouponModal} from "./ProductDetailSignOnCouponModal";
import {ImagePlayModal} from "./ImagePlayModal";

type TTabs = "성분/급여" | "원재료" | "상품설명" | "후기" | "문의";
interface ITabs {
  title: TTabs;
  visible: boolean;
  offsetY: number;
  active: boolean;
  id: string;
}
export interface IProductImageModal {
  isVisible: boolean;
  title?: "제조방법" | "원산지";
  image?: {
    title?: string;
    img_url?: string;
  }[];
  sub_title?: string;
}

interface IAppToast extends IToast {
  svgIcon?: "check" | "warning";
}

const scrollDivId = "productDetailScrollDiv";
const optionDivId = "productOptionDiv";

export default function ProductDetailPage() {
  const location = useLocation();
  const {preview_item} = useParams<{preview_item?: IProduct}>();
  const {hackleTrack} = useHackleTrack();
  const {openDownloadAppModal} = useDownload();
  const setHeader = useSetRecoilState(headerState);
  const {
    isApp,
    delivery_type: nowDeliveryType = "새벽",
    safeArea,
  } = useRecoilValue(getParameterState);
  const {user} = useMember();
  const {
    getCommon,
    deliveryBlockDate,
    etc,
    toggleIsproductGuaranteeHide,
    isMaterialTooltip,
    isSubscribeTooltip,
    closeSubscribeTooltip,
    isNoneSubscribeTooltip,
    closeNoneSubscribeTooltip,
    closeMaterialTooltip,
    closeCheckpointTooltip,
    closeFirstAccessDetail,
    isFirstAccessDetail,
  } = useCommon();
  const {k} = getParameterObject({url: location.search});
  const no = decryptQuery({query: k});
  const {RNpostMessage} = useRNPost();

  const scrollRef = useRef<HTMLDivElement>(null);

  const [prevItemData, setPrevItemData] = useState<IDefaultProduct>();
  const [productDetail, setProductDetail] = useState<IProductDetail>();
  const [itemImages, setItemImages] = useState<string[]>([]);
  const [isPointAddOpen, setIsPointAddOpen] = useState(false);
  const [reviewList, setReviewList] = useState<IProductReview[]>([]);
  const [bestReviewList, setBestReviewList] = useState<IProductReview[]>([]);
  const [photoReviewList, setPhotoReviewList] = useState<IProductReview[]>([]);
  const [imageReviewList, setImageReviewList] =
    useState<ResultPagingResponse<IReviewPhoto[]>>();
  const [recommandList, setRecommandList] =
    useState<ResultPagingResponse<IHomeProduct[]>>();
  const [qnaList, setQnaList] = useState<ResultPagingResponse<IProductQna[]>>();
  const [options, setOptions] = useState<IProductOptions[]>();
  const [optionTabNo, setOptionTabNo] = useState<number>();
  const [isSelectBuyItem, setIsSelectBuyItem] = useState(false);
  const [isSelectSubscribeBuyItem, setIsSelectSubscribeBuyItem] =
    useState(false);
  const [selectDeliveryAddress, setSelectDeliveryAddress] =
    useState<IDawnShippingAreaFlag>();
  const [activeQnaNo, setActiveQnaNo] = useState<number>();
  const [plusDeliveryFee, setPlusDeliveryFee] = useState(0);

  const [visibleMaterialModal, setVisibleMaterialModal] = useState(false);
  const [isMilkDeliveryDateAlert, setIsMilkDeliveryDateAlert] = useState(false);
  const [visibleFirstAccessDetail, setVisibleFirstAccessDetail] =
    useState(false);
  const [imageModal, setImageModal] = useState<IProductImageModal>({
    isVisible: false,
  });
  const [isBackHandler, setIsBackHandler] = useState(false);
  const [productMaterialList, setProductMaterialList] = useState<
    ISelectItemMaterial[]
  >([]);

  const {GAEventTrigger} = useReactGA();

  const [tab, setTab] = useState<ITabs[]>([
    {title: "상품설명", visible: true, offsetY: 0, active: true, id: "tab01"},
    {title: "원재료", visible: true, offsetY: 0, active: false, id: "tab02"},
    {
      title: "성분/급여",
      visible: true,
      offsetY: 0,
      active: false,
      id: "tab03",
    },
    {title: "후기", visible: true, offsetY: 0, active: false, id: "tab04"},
    {title: "문의", visible: true, offsetY: 0, active: false, id: "tab05"},
  ]);

  const viewItem = productDetail || prevItemData;
  const productSubscribePrice = getProductPrice({
    product: viewItem,
    isSubscribe: true,
  });
  const productPrice = getProductPrice({product: viewItem});

  const isIncludeFrozen = viewItem?.item_fresh === "N";
  const isIncludeFresh = viewItem?.item_fresh === "Y";

  const {free_delivery_price: FREE_DELIVERY_PRICE} = getCalcOrderPrice({
    plus_price: plusDeliveryFee,
    item_total_price: 0,
    delivery_type: nowDeliveryType,
    isIncludeFrozen,
    isIncludeFresh,
  });

  const mainBadgeList = (viewItem?.badge || []).filter(
    badge => badge.badgeInfo?.main_yn === "Y" && !!badge.badgeInfo.img_url,
  );
  const subBadgeList = (viewItem?.badge || []).filter(
    badge => badge.badgeInfo?.main_yn !== "Y",
  );
  const isNextStockDate =
    (viewItem?.stock || 0) <= 0 && viewItem?.stock_date?.stock_date;

  const previewReview = [
    ...(bestReviewList || []),
    ...(photoReviewList || []),
  ][0];

  const visibleTabs = tab.filter(_tab => _tab.visible);

  const nowScrollTop = scrollRef.current?.scrollTop || 0;
  const isOptionFixedTab =
    nowScrollTop >
      (document.getElementById(optionDivId)?.offsetTop || 0) - 30 &&
    nowScrollTop < tab[3].offsetY;

  const nowStock =
    ((viewItem?.stock || 0) > 0
      ? viewItem?.stock
      : viewItem?.stock_date?.stock_count) || 0;

  const isSoldOut = nowStock <= 0;

  const isSubscribe =
    (
      ((user?.subscribe_list || []).filter(
        item => item?.delivery_address_no === selectDeliveryAddress?.no,
      ) || [])[0]?.subscribeItem || []
    ).filter(item => item?.item_no === viewItem?.no).length > 0;
  const isSubscribeAble = !!user && viewItem?.subscribe_yn === "Y";
  const subscribeNo = (user?.subscribe_list || [])?.[0]?.no;

  const isInformationnNextDate = (() => {
    if (
      nowDeliveryType === "일반" &&
      !!viewItem?.next_stock_date &&
      !!viewItem?.common_delivery__order_limit_datetime &&
      moment().diff(viewItem?.common_delivery__order_limit_datetime) > 0
    ) {
      return true;
    } else if (
      nowDeliveryType === "도서산간" &&
      !!viewItem?.next_stock_date &&
      !!viewItem?.plus_delivery__order_limit_datetime &&
      moment().diff(viewItem?.plus_delivery__order_limit_datetime) > 0
    ) {
      return true;
    }
    return false;
  })();

  const isCouponUseAble =
    viewItem?.coupon_useable === "Y" &&
    viewItem?.partners?.coupon_useable === "Y";

  //

  async function getDetail() {
    try {
      if (!no && isNaN(Number(no))) {
        return;
      }
      const {data, success, message} = await api.v1.product.productDetail({
        item_no: no,
      });
      if (success) {
        setProductDetail(data);
        setProductMaterialList(
          (data?.all_material || data?.material || []).sort(
            (a, b) => (a.ordering || 0) - (b.ordering || 0),
          ),
        );

        if ((data.options || []).length > 0) {
          setOptionTabNo(data.options?.[0]?.no);
        }

        setHeader({
          title: data.item_name || "",
          rightElements: [
            {
              icon: (
                <div className={styles.headerCartIcon}>
                  <Svgs.CartIcon width={20} />
                </div>
              ),
              onPress: () => {
                gotoNavigate({screenId: APP_NAVIGATE.CART});
              },
            },
          ],
        });

        setItemImages(
          [
            data?.item_img || data?.item_thumbnail_img,
            ...(
              (
                ((data?.detail || []).filter(
                  _data => _data?.type === "imgDetail",
                )[0] || {}) as IProductDetailImgDetail
              ).data_json?.data || []
            ).map(_item => _item.thumbnail_img_url || _item.img_url),
          ].filter(isDefined),
        );

        if (
          MILK_ITEM_NO_LIST.includes(Number(no)) &&
          !!data?.stock_date?.stock_date &&
          moment(data?.stock_date?.stock_date).isValid()
        ) {
          setIsMilkDeliveryDateAlert(true);
        }
      } else {
        alert(message);
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function getReviewList(page = 1) {
    const {data, success} = await api.v1.review.productionReviewList({
      no: Number(no),
      page,
    });
    if (success) {
      if (page === 1) {
        setOptions(data.options);
        setReviewList(data.result);
      } else {
        setReviewList([...reviewList, ...data.result]);
      }
    }
  }

  async function getBestReviewList(page = 1) {
    const {data, success} = await api.v1.review.productionReviewList({
      no: Number(no),
      page,
      best_yn: "Y",
    });
    if (success) {
      if (page === 1) {
        setOptions(data.options);
        setBestReviewList(data.result);
      } else {
        setBestReviewList([...bestReviewList, ...data.result]);
      }
    }
  }

  async function getPhotoReviewList(page = 1) {
    const {data, success} = await api.v1.review.productionReviewList({
      no: Number(no),
      page,
      photo_yn: "Y",
    });
    if (success) {
      if (page === 1) {
        setOptions(data.options);
        setPhotoReviewList(data.result);
      } else {
        setPhotoReviewList([...photoReviewList, ...data.result]);
      }
    }
  }

  async function getReviewPhotoList(page = 1) {
    const {data, success} = await api.v1.review.productionReviewPhotoList({
      no: Number(no),
      page,
    });
    if (success) {
      if (page === 1 || !imageReviewList?.result) {
        setImageReviewList(data);
      } else {
        setImageReviewList({
          result: [...(imageReviewList?.result || []), ...data.result],
          page: data.page,
        });
      }
    }
  }

  async function getRecommandList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success} = await api.v1.product.recommandProductList({
        no: Number(no),
        page,
      });
      if (success) {
        setRecommandList(data);
      }
    } catch (error) {
      console.log("getRecommandList error", error);
    }
  }

  async function getItemQnaList(page: number) {
    try {
      const {data, success} = await api.v1.product.productionQnaList({
        item_no: Number(no),
        page,
      });
      if (success) {
        setQnaList(data);
      }
    } catch (error) {
      console.log("getItemQnaList error", error);
    }
  }

  async function productLike() {
    if (!productDetail) {
      return;
    }
    if (!user) {
      gotoNavigate({screenId: APP_NAVIGATE.LOGIN});
      return;
    }

    const {
      data: {result},
      success,
    } = await api.v1.likeHistory.toggleLikeHistory({
      type: "item",
      target_no: Number(no),
      del: productDetail?.likeHistory ? "Y" : "N",
    });
    if (success) {
      setProductDetail({...productDetail, likeHistory: result});
    }
  }

  async function deleteQna() {
    if (!activeQnaNo) {
      return;
    }
    const {success} = await api.v1.product.productionQnaDelete({
      no: activeQnaNo,
    });
    if (success) {
      setActiveQnaNo(undefined);
      getItemQnaList(1);
      setToast({
        text: "문의가 삭제되었어요.",
        svg: <Svgs.CheckSvg />,
        svgIcon: "check",
      });
    }
  }

  async function addSubscribeTemp() {
    if (!subscribeNo) {
      return;
    }
    if (!productDetail?.no) {
      return;
    }
    try {
      const {
        success,
        data: {result},
      } = await api.v1.order.getAddSubscribeTemp({
        count: 1,
        item_no: productDetail.no,
        subscribe_no: subscribeNo,
      });
      if (success) {
        const estimate_delivery_date = calcDeliveryDate({
          delivery_type: nowDeliveryType,
          orderDate: moment(result.delivery_date?.delivery_datetime),
          delivery_block_date: deliveryBlockDate,
        });
        setToast({
          svg: <Svgs.CheckSvg />,
          svgIcon: "check",
          text: `상품을 ${dateFormat(
            moment(estimate_delivery_date).toDate(),
          )} 도착 정기배송에 함께 담았습니다.`,
          leftText: "바로가기",
          leftNavigate: APP_NAVIGATE.ORDER_LIST_SUBSCRIBE,
        });
      } else if (result && result.delivery_date && result.near_delivery_date) {
        if (
          !!result &&
          moment(result.delivery_date?.delivery_datetime)
            .startOf("day")
            .diff(moment(result?.stock_date?.stock_date).startOf("day")) > 0
        ) {
          setToast({
            svg: <Svgs.WarningSvg />,
            svgIcon: "warning",
            text: `정기배송 일정(${moment(
              result.near_delivery_date?.delivery_datetime,
            ).format("M/D")})보다 출고일(${moment(
              result?.stock_date?.stock_date,
            ).format("M/D")})이 뒤에 있는 상품은 함께 담을 수 없어요.`,
            bottomButton: true,
          });
        } else {
          setToast({
            svg: <Svgs.WarningSvg />,
            svgIcon: "warning",
            text: `정기배송 일정(${moment(
              result.near_delivery_date?.delivery_datetime,
            ).format("M/D")})보다 출고일(${moment(
              result?.delivery_date?.delivery_datetime,
            ).format("M/D")})이 뒤에 있는 상품은 함께 담을 수 없어요.`,
            bottomButton: true,
          });
        }
      } else {
        throw Error("api success false!");
      }
    } catch (error) {
      console.error("addSubscribeTemp error", error);
      setToast({
        svg: <Svgs.WarningSvg />,
        svgIcon: "warning",
        text: "함께 담기에 실패했어요",
        bottomButton: true,
      });
    }
  }

  function gotoNavigate({
    screenId,
    data,
  }: {
    screenId: APP_NAVIGATE;
    data?: {[key: string]: string | number | undefined};
  }) {
    if (isApp) {
      RNpostMessage({action: "navigate", screenId, data});
    } else {
      openDownloadAppModal();
    }
  }

  function copyShareLink() {
    if (!productDetail?.no) {
      return;
    }
    hackleTrack({
      key: "click__product_share",
      properties: {
        product_no: String(productDetail?.no),
        isApp: isApp ? "TRUE" : "FALSE",
        is_member: user ? "TRUE" : "FALSE",
      },
    });
    if (isApp) {
      RNpostMessage({
        action: "share",
        data: copyURL(
          `${ScreenId.PRODUCT_DETAIL}?k=${encryptQuery({
            query: `${productDetail?.no}`,
          })}`,
        ),
      });
    } else {
      window.navigator.clipboard.writeText(
        copyURL(
          `${ScreenId.PRODUCT_DETAIL}?k=${encryptQuery({
            query: `${productDetail?.no}`,
          })}`,
        ),
      );
      setToast({
        svg: <Svgs.CheckSvg />,
        svgIcon: "check",
        text: "복사되었습니다",
        bottomButton: true,
      });
    }
  }

  async function getFirstAccessDetail() {
    if (!user && !isFirstAccessDetail) {
      setTimeout(() => {
        setVisibleFirstAccessDetail(true);
      }, 5000);
    }
  }

  function moveToScrollSection(id: string) {
    const offsetTop = (document.getElementById(id)?.offsetTop || 0) - 50;
    scrollRef.current?.scroll({top: offsetTop});
  }

  const handleScroll = () => {
    const scrollTop = scrollRef.current?.scrollTop || 0;
    let newTabs = tab.map(_tab => {
      const offsetY = document.getElementById(_tab.id)?.offsetTop || 0;
      let active = false;
      if (scrollTop > offsetY - 150) {
        // 근사치 도달 시로 계산하기 위해 150 빼줌
        active = true;
      }
      return {..._tab, offsetY, active};
    });
    const lastActiveIndex = newTabs.filter(_tab => _tab.active).length - 1;
    if (lastActiveIndex === -1) {
      newTabs[0].active = true;
    } else {
      newTabs = newTabs.map((_tab, index) =>
        index !== lastActiveIndex ? {..._tab, active: false} : _tab,
      );
    }
    setTab(newTabs);
  };

  const throttleHandleScroll = _.throttle(handleScroll, 300);

  function backHandler() {
    if (imageModal?.isVisible) {
      setImageModal({isVisible: false});
    } else if (visibleFirstAccessDetail) {
      setVisibleFirstAccessDetail(false);
    } else if (isMilkDeliveryDateAlert) {
      setIsMilkDeliveryDateAlert(false);
    } else if (activeQnaNo) {
      setActiveQnaNo(undefined);
    } else if (isSelectSubscribeBuyItem) {
      setIsSelectSubscribeBuyItem(false);
    } else if (isSelectBuyItem) {
      setIsSelectBuyItem(false);
    } else if (visibleMaterialModal) {
      setVisibleMaterialModal(false);
    } else {
      RNpostMessage({action: "back"});
    }
    setIsBackHandler(false);
  }

  function messageListener(event: any) {
    if (!event || !event.data) {
      return;
    }

    try {
      const {
        action,
        selectDeliveryAddress: appSelectDeliveryAddress,
        isSubscribeTooltip: appIsSubscribeTooltip,
        isNoneSubscribeTooltip: appIsNoneSubscribeTooltip,
        isproductGuaranteeHide: appIsproductGuaranteeHide,
        isMaterialTooltip: appIsMaterialTooltip,
        isCheckpointTooltip: appIsCheckpointTooltip,
        plusDeliveryFee: appPlusDeliveryFee,
        isFirstAccessDetail: appIsFirstAccessDetail,
        previewItemData,
      } = JSON.parse(event.data);
      if (action === "reload") {
        getReviewPhotoList(1);
        getReviewList(1);
        getPhotoReviewList(1);
        getItemQnaList(1);
      }
      if (action === "back") {
        setIsBackHandler(true);
      }
      if (appSelectDeliveryAddress) {
        setSelectDeliveryAddress(appSelectDeliveryAddress);
      }
      if (!appIsSubscribeTooltip) {
        closeSubscribeTooltip();
      }
      if (!appIsNoneSubscribeTooltip) {
        closeNoneSubscribeTooltip();
      }
      toggleIsproductGuaranteeHide({
        hide: appIsproductGuaranteeHide,
        isAppMessage: false,
      });
      if (!appIsMaterialTooltip) {
        closeMaterialTooltip();
      }
      if (!appIsCheckpointTooltip) {
        closeCheckpointTooltip();
      }
      if (!isNaN(Number(appPlusDeliveryFee))) {
        setPlusDeliveryFee(Number(appPlusDeliveryFee));
      }
      if (appIsFirstAccessDetail) {
        closeFirstAccessDetail();
      }
      if (!!previewItemData && !productDetail) {
        setPrevItemData(previewItemData);
        setHeader({
          title: previewItemData?.item_name || "",
          rightElements: [
            {
              icon: (
                <div className={styles.headerCartIcon}>
                  <Svgs.CartIcon width={20} />
                </div>
              ),
              onPress: () => {
                gotoNavigate({screenId: APP_NAVIGATE.CART});
              },
            },
          ],
        });
      }
    } catch (error) {
      console.warn("messageListener error", error);
    }
  }

  function setToast(data: IAppToast) {
    try {
      if (isApp) {
        RNpostMessage({action: "toast", data});
      } else {
        toast(data.text, {toastId: "toast"});
      }
    } catch (error) {
      console.error("setToast error", error);
    }
  }

  useLayoutEffect(() => {
    RNpostMessage({action: "loaded"});
  }, []);

  useEffect(() => {
    getCommon();
    getFirstAccessDetail();

    if (preview_item) {
      setPrevItemData(preview_item);
      const temp_thumbnail_img =
        preview_item.item_img || preview_item.item_thumbnail_img;
      if (temp_thumbnail_img) {
        setItemImages([temp_thumbnail_img]);
      }
    }

    scrollRef.current?.addEventListener("scroll", throttleHandleScroll);

    document?.addEventListener("message", messageListener);
    window?.addEventListener("message", messageListener);

    return () => {
      scrollRef.current?.removeEventListener("scroll", throttleHandleScroll);
      document?.removeEventListener("message", messageListener);
      window?.removeEventListener("message", messageListener);
    };
  }, []);

  useEffect(() => {
    getDetail();

    getReviewPhotoList(1);
    getReviewList(1);
    getBestReviewList(1);
    getPhotoReviewList(1);
    getRecommandList();
    getItemQnaList(1);

    scrollRef.current?.scrollTo({top: 0, behavior: undefined});
  }, [no]);

  useEffect(() => {
    if (isBackHandler) {
      backHandler();
    }
  }, [isBackHandler]);

  const footer = (
    <Footer
      isLike={!!productDetail?.likeHistory}
      productLike={productLike}
      isSoldOut={isSoldOut}
      setSelectBuyItem={() => {
        if (!isSoldOut) {
          GAEventTrigger({
            action: "click_step1",
            category: "click_step1",
            label: "일반구매 클릭",
            value: 1,
          });
          setIsSelectBuyItem(true);
        }
      }}
      subscribeNo={subscribeNo}
      isSubscribeAble={isSubscribeAble}
      addSubscribeTemp={addSubscribeTemp}
      isSubscribe={isSubscribe}
      setSelectSubscribeBuyItem={() => {
        setIsSelectSubscribeBuyItem(true);
      }}
      isSubscribeTooltip={isSubscribeTooltip}
      closeSubscribeTooltip={closeSubscribeTooltip}
      isNoneSubscribeTooltip={isNoneSubscribeTooltip}
      closeNoneSubscribeTooltip={closeNoneSubscribeTooltip}
      discountPrice={productPrice - productSubscribePrice}
    />
  );

  return (
    <>
      <div className={styles.containerDiv}>
        <TabMenu
          visibleTabs={visibleTabs}
          review_cnt={productDetail?.review_cnt || 0}
          moveToScrollSection={moveToScrollSection}
        />
        <div className={styles.fixedTabs}>
          {!!isOptionFixedTab && (
            <OptionTabMenu
              options={productDetail?.options}
              optionTabNo={optionTabNo}
              setOptionTabNo={setOptionTabNo}
              moveToScrollSection={() => moveToScrollSection(optionDivId)}
            />
          )}
        </div>
        <div className={styles.container} ref={scrollRef} id={scrollDivId}>
          <div className={styles.topSection}>
            <div className={styles.productImage}>
              {(itemImages || []).length > 0 ? (
                <Slider
                  swipeToSlide={true}
                  speed={500}
                  slidesToShow={1}
                  slidesToScroll={1}
                  dots={true}
                  dotsClass={styles.sliderDots}
                  focusOnSelect={false}
                  appendDots={(dots: any) => (
                    <div>
                      <ul>{dots}</ul>
                    </div>
                  )}>
                  {itemImages.map((img_url, index) => (
                    <div key={index}>
                      <Img src={img_url} />
                    </div>
                  ))}
                </Slider>
              ) : viewItem?.item_img || viewItem?.item_thumbnail_img ? (
                <div>
                  <Img
                    src={viewItem?.item_img || viewItem?.item_thumbnail_img}
                  />
                </div>
              ) : (
                <EmptyProductImage />
              )}
              {mainBadgeList.length > 0 && (
                <div className={styles.mainBadgeContainer}>
                  {(mainBadgeList || []).map((item, index) => {
                    return (
                      <div key={index}>
                        <Img src={item.badgeInfo?.img_url} />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className={styles.productInfo}>
              <div
                className={styles.partnerName}
                onClick={() => {
                  if (viewItem?.partners?.no)
                    gotoNavigate({
                      screenId: APP_NAVIGATE.PARTNER,
                      data: {no: viewItem?.partners?.no},
                    });
                }}>
                {viewItem?.partners?.partner_name}
              </div>
              <div className={styles.itemNameRow}>
                <div className={styles.itemName}>{viewItem?.item_name}</div>
                <div className={styles.icon} onClick={copyShareLink}>
                  <Svgs.ShareIcon stroke={COLOR.gray1} />
                </div>
              </div>
              <div className={styles.productPrice}>
                {(viewItem?.view_price || 0) !== productPrice && (
                  <div className={styles.percent}>
                    {Number(
                      (1 - productPrice / (viewItem?.view_price || 0)) * 100,
                    ).toFixed(0)}
                    %
                  </div>
                )}
                <div className={styles.price}>
                  {productPrice.toLocaleString()}원
                </div>
                {(viewItem?.view_price || 0) !== productPrice && (
                  <div className={styles.viewPrice}>
                    {(viewItem?.view_price || 0).toLocaleString()}원
                  </div>
                )}
              </div>
              {!isCouponUseAble && (
                <div className={styles.couponDisabled}>
                  <FontText color={COLOR.green}>
                    쿠폰 적용 불가능 상품입니다.
                  </FontText>
                </div>
              )}
              {(viewItem?.review_cnt || 0) > 0 && (
                <div
                  className={styles.productReviewInfo}
                  onClick={() => {
                    if (viewItem?.no)
                      gotoNavigate({
                        screenId: APP_NAVIGATE.REVIEW_LIST,
                        data: {no: viewItem?.no},
                      });
                  }}>
                  <Svgs.StarIcon width={14} fill={COLOR.green} />
                  <div className={styles.avgScore}>
                    {viewItem?.avg_score || 0}
                  </div>
                  <div className={styles.reviewCnt}>
                    후기{" "}
                    {(viewItem?.review_cnt || 0) > 9999
                      ? "9999+"
                      : viewItem?.review_cnt || 0}
                    개
                  </div>
                  <Svgs.ChevronIcon
                    rotate={270}
                    stroke={COLOR.gray4}
                    width={12}
                  />
                </div>
              )}
              {subBadgeList.length > 0 && (
                <div className={styles.badgeList}>
                  {subBadgeList.map((item, index) => {
                    return (
                      <div key={index} className={styles.badgeItem}>
                        {item.badgeInfo?.icon === "zoopi" && (
                          <Svgs.ZoopiIcon width={16} />
                        )}
                        <span>{item?.badgeInfo?.title}</span>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className={styles.line} />
              <div className={styles.deliverySection}>
                <Svgs.DeliveryIcon />
                <div className={styles.deliveryText}>
                  <div className={styles.deliveryTypeContent}>
                    <div className={styles.deliveryType}>
                      {nowDeliveryType === "새벽"
                        ? "주피배송"
                        : nowDeliveryType === "도서산간"
                        ? "도서산간지역 배송"
                        : "일반배송"}
                    </div>
                    {nowDeliveryType !== "도서산간" && (
                      <>
                        <div className={styles.dot} />
                        <div className={styles.freeDeliveryInfo}>
                          {Number(FREE_DELIVERY_PRICE / 10000)}만원 이상
                          무료배송
                        </div>
                      </>
                    )}
                  </div>
                  <div className={styles.estimateText}>
                    지금 주문하면{" "}
                    <span>
                      {calcDeliveryDateText({
                        delivery_type: nowDeliveryType,
                        stockDate: moment(
                          isNextStockDate
                            ? viewItem?.stock_date?.stock_date
                            : undefined,
                        ),
                        common_delivery__order_limit_datetime:
                          viewItem?.common_delivery__order_limit_datetime,
                        plus_delivery__order_limit_datetime:
                          viewItem?.plus_delivery__order_limit_datetime,
                        next_stock_date: viewItem?.next_stock_date,
                        delivery_block_date: deliveryBlockDate,
                      })}
                      {" 도착"}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={styles.pointAddInfo}
                onClick={() => setIsPointAddOpen(!isPointAddOpen)}>
                <div className={styles.pointIcon}>
                  <Svgs.PointSvg />
                </div>
                <div className={styles.slideContent}>
                  <div className={styles.fixedContent}>
                    <div className={styles.estimatePoint}>
                      최대{" "}
                      {Number(
                        (
                          productPrice * 0.01 +
                          (etc?.photo_review_point || 0)
                        ).toFixed(0),
                      ).toLocaleString()}
                      P 적립
                    </div>
                    <div
                      className={[
                        styles.chevron,
                        isPointAddOpen && styles.open,
                      ].join(" ")}>
                      <Svgs.ChevronIcon />
                    </div>
                  </div>
                  <div
                    className={[
                      styles.slideToggle,
                      isPointAddOpen && styles.open,
                    ].join(" ")}>
                    <div className={styles.estimateOrderPoint}>
                      구매 적립{" "}
                      {Number(productPrice * 0.01)
                        .toFixed(0)
                        .toLocaleString()}
                      P
                    </div>
                    <div className={styles.estimateOrderPoint}>
                      후기 작성 시 최대{" "}
                      {Number(
                        (
                          productPrice * 0.01 +
                          (etc?.photo_review_point || 0)
                        ).toFixed(0),
                      ).toLocaleString()}
                      P 적립
                    </div>
                  </div>
                </div>
              </div>
              {(viewItem?.stock || 0) <= 5 && (viewItem?.stock || 0) > 0 && (
                <div className={styles.stockAlert}>
                  <Svgs.ClockFourSvg />
                  <div className={styles.red}>품절임박</div>
                  <div className={styles.dot} />
                  <div className={styles.stock}>
                    {viewItem?.stock || 0}개 남았어요!
                  </div>
                </div>
              )}
            </div>
            {!!productDetail && (
              <GuaranteeItem
                data={productDetail?.detail || []}
                stock={viewItem?.stock || 0}
                stock_date={
                  !!viewItem?.stock_date?.stock_date &&
                  moment(viewItem?.stock_date?.stock_date).isValid()
                    ? moment(viewItem?.stock_date?.stock_date)
                    : undefined
                }
                insert_date={
                  !!productDetail?.insert_date &&
                  moment(productDetail?.insert_date).isValid()
                    ? moment(productDetail?.insert_date)
                    : undefined
                }
                made_date={
                  (productDetail?.stock || 0) > 0
                    ? moment(productDetail?.made_date).isValid()
                      ? moment(productDetail?.made_date).format("YYYY.MM.DD")
                      : productDetail?.made_date
                    : moment(productDetail?.stock_date?.made_date).isValid()
                    ? moment(productDetail?.stock_date?.made_date).format(
                        "YYYY.MM.DD",
                      )
                    : undefined
                }
                limit_date={
                  (productDetail?.stock || 0) > 0
                    ? moment(productDetail?.limit_date).isValid()
                      ? moment(productDetail?.limit_date).format("YYYY.MM.DD")
                      : productDetail?.limit_date
                    : moment(productDetail?.stock_date?.limit_date).isValid()
                    ? moment(productDetail?.stock_date?.limit_date).format(
                        "YYYY.MM.DD",
                      )
                    : undefined
                }
              />
            )}
            {/* information - 상품정보 */}
            <InformationItem
              data={productDetail?.detail || []}
              isInformationnNextDate={isInformationnNextDate}
            />
            {!!productDetail?.post && (productDetail?.post || []).length > 0 ? (
              <PostSection
                post={productDetail?.post}
                item_name={
                  productDetail?.short_name || productDetail?.item_name || ""
                }
              />
            ) : previewReview ? (
              <TopReviewSection
                previewReview={previewReview}
                review_cnt={viewItem?.review_cnt || 0}
              />
            ) : undefined}
          </div>
          <div>
            {/* 상세정보 ***********************/}
            <div id={tab[0].id} />
            <div>
              {/* 상품공지 */}
              <NoticeItem data={productDetail?.detail} />
              {/* 주피's pick */}
              {(productDetail?.detail || []).find(
                item => item.type === "checkpointNew",
              ) ? (
                <CheckPointNewItem data={productDetail?.detail || []} />
              ) : (
                <>
                  <CheckPointItem data={productDetail?.detail || []} />
                </>
              )}
              {/* 상세정보 */}
              <ImgDetailItem
                data={productDetail?.detail || []}
                recipeItem={
                  <RecipeItem
                    data={productDetail?.detail || []}
                    item_no={productDetail?.no || 0}
                  />
                }
              />
            </div>
            <div>
              <div className={styles.break} />
              <div id={tab[1].id} />
              {/* inerview - 인터뷰 */}
              <Interview2Item data={productDetail?.detail || []} />
              {/* material - 원재료 */}
              <MaterialItem
                data={productMaterialList}
                setVisibleMaterialModal={() => setVisibleMaterialModal(true)}
              />
              {/* itemOrigin - 원산지 */}
              <ItemOriginItem
                data={productDetail?.detail || []}
                item_no={productDetail?.no || 0}
                setImageModal={setImageModal}
              />
            </div>
            <div className={styles.break} />
            <div id={tab[2].id} />
            {/* 급여정보 ***********************/}
            <div>
              <div id={optionDivId} />
              <div className={styles.optionTabs}>
                <OptionTabMenu
                  options={productDetail?.options}
                  optionTabNo={optionTabNo}
                  setOptionTabNo={setOptionTabNo}
                  moveToScrollSection={() => moveToScrollSection(optionDivId)}
                />
              </div>
              {/* composition - 영양 성분 정보 */}
              <CompositionItem
                data={productDetail?.detail || []}
                option_no={optionTabNo}
                item_dmb_list={productDetail?.item_dmb_list || []}
              />
              {/* feedAmount - 급여량 */}
              <FeedAmountItem
                data={productDetail?.detail || []}
                option_no={optionTabNo}
              />
              {/* feed - 급여방법 */}
              <FeedItem data={productDetail?.detail || []} />
              {/* storage - 보관방법 */}
              <StorageItem data={productDetail?.detail || []} />
            </div>
          </div>
          <div className={styles.break} />
          <div id={tab[3].id} />
          <div className={styles.reviewContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>후기</div>
              <div className={styles.subTitle}>
                {(viewItem?.review_cnt || 0) > 9999
                  ? "9999+"
                  : viewItem?.review_cnt || 0}
              </div>
            </div>
            {reviewList?.length > 0 && (
              <>
                <div className={styles.storeConateinr}>
                  <div className={styles.starConatiner}>
                    <div className={styles.emptyStar}>
                      <Svgs.ScoreSvg width={160} />
                    </div>
                    <div className={styles.fillStar}>
                      <div
                        style={{
                          width: `${Number(viewItem?.avg_score || 0) * 20}%`,
                        }}>
                        <Svgs.ScoreSvg width={160} fill={COLOR.green} />
                      </div>
                    </div>
                  </div>
                  <div className={styles.totalRating}>
                    {viewItem?.avg_score || 0}
                    <span>{" / 5"}</span>
                  </div>
                </div>
                <div>
                  {options?.map((value, index) => {
                    return (
                      <div key={index} className={styles.optionItem}>
                        <div className={styles.categoryTitle}>
                          {value.category_title}
                        </div>
                        <div className={styles.optionTitle}>
                          {value.option_title}
                        </div>
                        <div className={styles.content}>
                          <div className={styles.barContainer}>
                            <div className={styles.emptyBar} />
                            <div
                              className={styles.fillBar}
                              style={{
                                width: `${value.percent || 0}%`,
                              }}
                            />
                          </div>
                          <div className={styles.percent}>
                            {Math.floor(Number(value.percent) || 0)}%
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className={styles.line} />
                </div>
              </>
            )}
            <div className={styles.photoReviewContainer}>
              {(imageReviewList?.result || []).slice(0, 3).map((_item, i) => {
                const maxLength = imageReviewList?.page?.maxcnt || 0;
                const isMore = i === 2 && maxLength - 3 > 0;
                const img_url = _item.thumbnail_img_url || _item.img_url;
                return (
                  <div key={i} className={styles.item}>
                    {!isMore ? (
                      <div
                        onClick={() =>
                          gotoNavigate({
                            screenId: APP_NAVIGATE.REVIEW_DETAIL,
                            data: {item_no: no, no: _item.target_no},
                          })
                        }>
                        <Img key={i} src={img_url} />
                        <div className={styles.buyCnt}>
                          {`${_item.payment_cnt || 1}회 구매`}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={styles.more}
                        onClick={() =>
                          gotoNavigate({
                            screenId: APP_NAVIGATE.PHOTO_REVIEW_LIST,
                            data: {no, item_no: no},
                          })
                        }>
                        <Img key={i} src={img_url} />
                        <div className={styles.moreGray}>
                          {`${
                            (imageReviewList?.page?.maxcnt || 0) - 3
                          }개\n더보기`}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={styles.bottomReviewContainer}>
              {reviewList && reviewList.length > 0 ? (
                reviewList.slice(0, 3).map(value => (
                  <ReviewItem
                    key={_.uniqueId()}
                    item={value}
                    goDetail={() =>
                      gotoNavigate({
                        screenId: APP_NAVIGATE.REVIEW_DETAIL,
                        data: {no: value.no, item_no: no},
                      })
                    }
                    goDeclarationPage={() =>
                      gotoNavigate({
                        screenId: APP_NAVIGATE.CREATE_DECLARATION_REVIEW,
                        data: {no: value.no},
                      })
                    }
                    goWritePage={() => {
                      if (!!value.order_no && !!value.orderDate) {
                        gotoNavigate({
                          screenId: APP_NAVIGATE.WRITE_REVIEW,
                          data: {
                            item_no: no,
                            no: value.no,
                            order_no: value.order_no,
                            orderDate: String(value.orderDate),
                            count: value.order_count || 1,
                          },
                        });
                      }
                    }}
                    isEditAble={
                      moment().diff(moment(value.createdAt), "days") <
                      REVIEW_EDIT_LIMIT_DAY
                    }
                    isWriteUser={user?.no === value.member_no}
                  />
                ))
              ) : (
                <div className={styles.emptyReview}>
                  상품 후기가 아직 없습니다.
                </div>
              )}
            </div>
            <div
              className={styles.reviewBtn}
              onClick={() =>
                gotoNavigate({
                  screenId: APP_NAVIGATE.REVIEW_LIST,
                  data: {no: no},
                })
              }>
              후기 전체보기
              <Svgs.ChevronIcon rotate={270} />
            </div>
          </div>
          <div className={styles.break} />
          {/* 추천상품 ***********************/}
          {!!recommandList?.result && recommandList.result.length > 0 && (
            <>
              <div className={styles.recommandContainer}>
                <div className={styles.title}>이 상품과 함께 구매했어요</div>
                <div className={styles.listContainer}>
                  {(recommandList?.result.slice(0, 4) || []).map(
                    (_product, _index) => (
                      <div
                        key={_index}
                        className={styles.item}
                        style={{
                          width: "50%",
                          alignItems:
                            _index % 2 === 0 ? "flex-start" : "flex-end",
                        }}>
                        <ProductItem item={_product} />
                      </div>
                    ),
                  )}
                </div>
              </div>
              <div className={styles.break} />
            </>
          )}
          {/* 문의 ***********************/}
          <div id={tab[4].id} />
          <div className={styles.qnaContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>문의</div>
              <div
                className={styles.right}
                onClick={() =>
                  gotoNavigate({
                    screenId: APP_NAVIGATE.PRODUCT_QNA_LIST,
                    data: {item_no: no},
                  })
                }>
                <div className={styles.text}>전체보기</div>
                <Svgs.ChevronIcon stroke={COLOR.gray5} rotate={270} />
              </div>
            </div>
            {(qnaList?.result || []).length === 0 ? (
              <div className={styles.emptyContent}>
                상품 문의가 아직 없습니다.
              </div>
            ) : (
              <>
                {(qnaList?.result || []).slice(0, 3).map((_item, _index) => (
                  <ProductQnaItem
                    key={_index}
                    item={_item}
                    setDelete={setActiveQnaNo}
                  />
                ))}
              </>
            )}
            <div
              className={styles.qnaBtn}
              onClick={() =>
                gotoNavigate({
                  screenId: APP_NAVIGATE.WRITE_PRODUCT_QNA,
                  data: {item_no: no},
                })
              }>
              상품 문의 작성하기
              <Svgs.ChevronIcon rotate={270} />
            </div>
          </div>
        </div>
      </div>
      <SignOnCouponSection />
      {footer}
      <MaterialModal
        visibleMaterialModal={visibleMaterialModal}
        close={() => setVisibleMaterialModal(false)}
      />
      {!!productDetail && (
        <ProductBuyModal
          item={productDetail}
          visible={isSelectBuyItem}
          close={() => setIsSelectBuyItem(false)}
          rating={String(productDetail?.avg_score)}
          pre_page_name="product_detail_page"
          nowDeliveryType={nowDeliveryType}
          plusDeliveryFee={plusDeliveryFee}
        />
      )}
      {!!productDetail && (
        <ProductBuySubscribeModal
          item={productDetail}
          visible={!!isSelectSubscribeBuyItem}
          close={() => setIsSelectSubscribeBuyItem(false)}
          pre_page_name="product_detail_page"
          nowDeliveryType={nowDeliveryType}
          plusDeliveryFee={plusDeliveryFee}
        />
      )}
      <CommonModal
        title="문의를 삭제하시겠어요?"
        visible={!!activeQnaNo}
        leftButton={{
          text: "취소",
          onPress: () => setActiveQnaNo(undefined),
        }}
        rightButton={{
          text: "네",
          onPress: deleteQna,
        }}
      />
      <CommonModal
        visible={!!isMilkDeliveryDateAlert}
        title={`${moment(productDetail?.stock_date?.stock_date).format(
          "M월 D일",
        )}에 발송되는 제품이에요`}
        content={
          <FontText color={COLOR.gray4}>
            다른 제품과 함께 담을 경우 배송 도착일이 변경돼요.
          </FontText>
        }
        oneButton={{
          text: "확인",
          onPress: () => setIsMilkDeliveryDateAlert(false),
          backgroundColor: COLOR.gray8,
          textColor: COLOR.gray1,
          fontWeight: 400,
        }}
        close={() => setIsMilkDeliveryDateAlert(false)}
      />
      <ProductDetailSignOnCouponModal
        visible={visibleFirstAccessDetail}
        close={() => {
          setVisibleFirstAccessDetail(false);
          closeFirstAccessDetail();
        }}
        onPress={() => {
          setVisibleFirstAccessDetail(false);
          closeFirstAccessDetail();
          gotoNavigate({screenId: APP_NAVIGATE.LOGIN});
        }}
      />
      {!!imageModal?.isVisible && (
        <ImagePlayModal
          visible={imageModal?.isVisible}
          imageModal={imageModal}
          setImageModal={setImageModal}
          footer={footer}
          partner={productDetail?.partners}
        />
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        theme="light"
        containerId="copyToast"
        toastClassName={styles.toast}
        style={{marginBottom: `${safeArea?.bottom || 0}px`}}
      />
    </>
  );
}

function Footer({
  isLike,
  productLike,
  setSelectBuyItem,
  setSelectSubscribeBuyItem,
  addSubscribeTemp,
  isSoldOut,
  subscribeNo,
  isSubscribeAble = false,
  isSubscribe,
  isSubscribeTooltip = false,
  closeSubscribeTooltip,
  isNoneSubscribeTooltip,
  closeNoneSubscribeTooltip,
  discountPrice = 0,
}: {
  isLike: boolean;
  productLike?: () => void;
  setSelectBuyItem?: () => void;
  setSelectSubscribeBuyItem?: () => void;
  addSubscribeTemp?: () => void;
  isSoldOut: boolean;
  subscribeNo?: number;
  isSubscribeAble?: boolean;
  isSubscribe?: boolean;
  isSubscribeTooltip?: boolean;
  closeSubscribeTooltip?: () => void;
  isNoneSubscribeTooltip?: boolean;
  closeNoneSubscribeTooltip?: () => void;
  discountPrice?: number;
}) {
  const {safeArea} = useRecoilValue(getParameterState);

  return (
    <div
      className={styles.footer}
      style={{paddingBottom: `${safeArea?.bottom || 0}px`}}>
      <div
        onClick={productLike}
        className={[styles.btn, styles.likeBtn].join(" ")}>
        {isLike ? <Svgs.RedBigHeartSvg /> : <Svgs.LineBigHeartSvg />}
      </div>
      {!!subscribeNo && isSubscribeAble && (
        <div
          className={[styles.btn, styles.subscribeTempBtn].join(" ")}
          onClick={addSubscribeTemp}>
          <FontText
            fontSize={16}
            lineHeight={150}
            fontWeight={700}
            color={COLOR.green}>
            함께 받기
          </FontText>

          {!!isSubscribeTooltip && (
            <div
              onClick={handleClickBubble}
              className={[
                styles.subscribeTooltipContainer,
                !isSubscribe && isSubscribeAble ? styles.left : styles.center,
              ].join(" ")}>
              <Tooltip
                horizon={!isSubscribe && isSubscribeAble ? 36 : 58}
                content={
                  <div className={styles.text}>
                    <FontText fontSize={16} color={COLOR.white}>
                      다음 정기구독 날짜에{" "}
                    </FontText>
                    <FontText
                      fontSize={16}
                      color={COLOR.orangeSub}
                      fontWeight={600}>
                      함께받기 가능
                    </FontText>
                  </div>
                }
                width={257}
                close={closeSubscribeTooltip}
              />
            </div>
          )}
        </div>
      )}
      {!isSubscribe && isSubscribeAble && (
        <div
          className={[styles.btn, styles.subscribeTempBtn].join(" ")}
          onClick={setSelectSubscribeBuyItem}>
          <FontText
            fontSize={16}
            lineHeight={150}
            fontWeight={700}
            color={COLOR.green}>
            정기배송
          </FontText>
          {!isSubscribeTooltip &&
            isNoneSubscribeTooltip &&
            discountPrice > 0 && (
              <div
                onClick={handleClickBubble}
                className={[
                  styles.noneSubscribeTooltipContainer,
                  subscribeNo ? styles.right : styles.left,
                ].join(" ")}>
                <Tooltip
                  horizon={subscribeNo ? 150 : undefined}
                  content={
                    <div className={styles.text}>
                      <FontText fontSize={16} color={COLOR.white}>
                        정기배송{" "}
                      </FontText>
                      <FontText
                        fontSize={16}
                        color={COLOR.orangeSub}
                        fontWeight={600}>
                        개당 {discountPrice.toLocaleString()}원
                      </FontText>
                      <FontText fontSize={16} color={COLOR.white}>
                        {" "}
                        할인
                      </FontText>
                    </div>
                  }
                  width={280}
                  close={closeSubscribeTooltip}
                />
              </div>
            )}
        </div>
      )}
      <div
        className={[
          styles.btn,
          styles.buyBtn,
          isSoldOut && styles.disabled,
        ].join(" ")}
        onClick={setSelectBuyItem}>
        <FontText
          fontSize={16}
          lineHeight={150}
          fontWeight={700}
          color={COLOR.white}>
          {!isSoldOut ? "일반구매" : "일시품절"}
        </FontText>
      </div>
    </div>
  );
}

function TabMenu({
  visibleTabs,
  review_cnt,
  moveToScrollSection,
}: {
  visibleTabs: ITabs[];
  notFixed?: boolean;
  review_cnt: number;
  moveToScrollSection?: (id: string) => void;
}) {
  return (
    <div className={[styles.tabs].join(" ")}>
      {visibleTabs.map((_tab, i) => {
        return (
          <div
            key={i}
            className={[styles.item, _tab.active && styles.active].join(" ")}
            onClick={() => {
              moveToScrollSection?.(_tab.id);
            }}>
            <div className={styles.title}>{_tab.title}</div>
            {_tab.title === "후기" && review_cnt > 0 && (
              <div className={styles.reviewCnt}>
                {review_cnt > 9999 ? "9999+" : review_cnt}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

function OptionTabMenu({
  options = [],
  optionTabNo,
  setOptionTabNo,
  moveToScrollSection,
}: {
  options?: IDBItemsOption[];
  optionTabNo?: number;
  setOptionTabNo?: (value: number) => void;
  moveToScrollSection?: () => void;
}) {
  if (options.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.optionTabMenu}>
      {(options || []).map(option => {
        const isActive = optionTabNo === option.no;
        return (
          <div
            key={_.uniqueId()}
            className={[styles.item, isActive && styles.active].join(" ")}
            onClick={() => {
              if (option.no) {
                setOptionTabNo?.(option.no);
                moveToScrollSection?.();
              }
            }}>
            <FontText
              className={styles.name}
              lineHeight={150}
              fontWeight={isActive ? 600 : 500}
              color={isActive ? COLOR.green : COLOR.gray4}>
              {option.option_name}
            </FontText>
          </div>
        );
      })}
    </div>
  );
}
