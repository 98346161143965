import {Svgs} from "@assets";
import {useDownload} from "@hook";
import {COLOR} from "@utils";
import FontText from "components/FontText";
import React from "react";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "./style.module.scss";

interface Props {
  children: JSX.Element;
}

export default function Wrapper({children}: Props) {
  const {isApp} = useRecoilValue(getParameterState);
  const {goToDownload} = useDownload();

  return (
    <div className={styles.wrap}>
      {!isApp && (
        <div className={styles.left}>
          <div className={styles.rightCont}>
            <Svgs.ZoopiCouponTwoSvg />
            <div style={{height: 45}} />
            <FontText
              fontSize={24}
              lineHeight={180}
              fontWeight={500}
              color={COLOR.white}
              align="center">
              지금 신규 가입 시 6천원 쿠폰과
              <br />
              또또우유 100원 구매 혜택을 드려요!
            </FontText>
            <div style={{height: 34}} />
            <div className={styles.rightBtnWrap}>
              <div className={styles.button} onClick={goToDownload}>
                <FontText fontSize={16} fontWeight={700} color={COLOR.white}>
                  Android 다운받기
                </FontText>
              </div>
              <div className={styles.button} onClick={goToDownload}>
                <FontText fontSize={16} fontWeight={700} color={COLOR.white}>
                  iOS 다운받기
                </FontText>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.center}>{children}</div>
      {!isApp && <div className={styles.right}></div>}
    </div>
  );
}
