import {Svgs} from "@assets";
import {FontText} from "@components";
import {IProductDetailCheckpoint, TProductItemDetail} from "@data";
import {COLOR} from "@utils";
import React from "react";
import styles from "../style.module.scss";

export function CheckPointItem({data}: {data: TProductItemDetail[]}) {
  const checkPoint = (data.filter(item => item?.type === "checkpoint")[0] ||
    {}) as IProductDetailCheckpoint;
  const checkPointData = (checkPoint?.data_json?.data || []).filter(
    item => item.title || item.content,
  );

  if (!checkPoint || checkPointData.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.checkPointItem}>
      <div className={styles.container}>
        <div className={styles.svg}>
          <Svgs.GraphicMotifsSvg />
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.svgContainer}>
            <Svgs.ZoopiIcon width={25} />
          </div>
          <div>
            <FontText
              fontSize={18}
              lineHeight={150}
              fontWeight={700}
              color={COLOR.green}>
              {checkPoint?.data_json?.sub_title}
            </FontText>
          </div>
        </div>
        {checkPointData.map((_data, i) => (
          <div key={i} className={styles.item}>
            <div className={styles.itemTitle}>
              <FontText
                fontSize={16}
                lineHeight={150}
                fontWeight={600}
                color={COLOR.gray2}>
                {_data.title}
              </FontText>
            </div>
            <div>
              <FontText lineHeight={150} color={COLOR.gray4}>
                {_data.content}
              </FontText>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
