import {Svgs} from "@assets";
import {FontText, Img} from "@components";
import {IProductDetailInterview, TProductItemDetail} from "@data";
import React from "react";
import styles from "../style.module.scss";

export function Interview2Item({data}: {data: TProductItemDetail[]}) {
  const item = data.filter(
    _data => _data?.type === "interview2",
  )?.[0] as IProductDetailInterview;

  const img_url =
    item?.data_json?.thumbnail_img_url || item?.data_json?.img_url;

  if (!item) {
    return <></>;
  }

  return (
    <div className={styles.interview2Item}>
      <div className={styles.box}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <FontText fontSize={16} lineHeight={150} fontWeight={700}>
              {item?.data_json?.title}
            </FontText>
          </div>
          <Svgs.ChatSvg />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.imgContainer}>
            {img_url ? <Img src={img_url} /> : <Svgs.ZoopiDoctorSvg />}
          </div>
          <div style={{flex: 1}}>
            <div className={styles.content}>
              <FontText>{item?.data_json?.content}</FontText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
