import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 14,
  fill = COLOR.green,
}: {
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={(Number(width) / 14) * 16}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.68636 0.562118C6.78596 0.521133 6.8932 0.5 7.00156 0.5C7.10993 0.5 7.21716 0.521133 7.31676 0.562118L12.432 2.66704C12.7198 2.7855 12.9652 2.98246 13.1375 3.2335C13.3099 3.48454 13.4017 3.77863 13.4016 4.07928V9.34735C13.4015 10.2949 13.1404 11.2256 12.6449 12.0452C12.1494 12.8647 11.437 13.544 10.58 14.0141L7.39836 15.7589C7.27751 15.8251 7.14074 15.86 7.00156 15.86C6.86238 15.86 6.72561 15.8251 6.60476 15.7589L3.42316 14.0141C2.56586 13.5439 1.85332 12.8644 1.3578 12.0445C0.862272 11.2247 0.601377 10.2937 0.601563 9.34581V4.07928C0.601597 3.77876 0.69348 3.48484 0.865827 3.23395C1.03817 2.98306 1.28341 2.78622 1.57116 2.66781L6.68636 0.563654V0.562118ZM9.96716 7.18714C10.1129 7.0423 10.1935 6.84832 10.1917 6.64697C10.1899 6.44562 10.1057 6.25301 9.95742 6.11062C9.80909 5.96824 9.60844 5.88748 9.39868 5.88573C9.18893 5.88398 8.98684 5.96138 8.83596 6.10127L6.20156 8.6301L5.16716 7.63715C5.01628 7.49726 4.8142 7.41986 4.60444 7.42161C4.39468 7.42336 4.19403 7.50412 4.04571 7.6465C3.89738 7.78889 3.81325 7.98149 3.81142 8.18285C3.8096 8.3842 3.89024 8.57818 4.03596 8.72302L5.63596 10.2589C5.78598 10.4029 5.98943 10.4837 6.20156 10.4837C6.41369 10.4837 6.61714 10.4029 6.76716 10.2589L9.96716 7.18714Z"
        fill={fill}
      />
    </svg>
  );
}
