import {Svgs} from "@assets";
import {Skeleton} from "@components";
import React from "react";
import styles from "./style.module.scss";

export function PostItemSkeleton() {
  return (
    <div className={styles.itemContainer}>
      <div className={styles.profileContainer}>
        <div className={styles.profileImgContainer}>
          <Skeleton width={32} ratio={1} borderRadius={100} />
        </div>
        <div className={styles.profileText}>
          <div className={styles.profileName}>
            <Skeleton width={85} height={14} />
          </div>
          <div className={styles.profileTime}>
            <Skeleton width={31} height={12} />
          </div>
        </div>
      </div>
      <div className={styles.imgContainer}>
        <Skeleton borderRadius={8} ratio={1} />
      </div>
      <div className={styles.iconSection}>
        <div className={styles.likeIcon}>
          <Svgs.HeartIcon width={20} />
        </div>
        <div className={styles.shareIcon}>
          <Svgs.ShareIcon width={18} />
        </div>
      </div>
      <div className={styles.titleSection}>
        <div className={styles.title}>
          <Skeleton width={150} height={20} />
        </div>
        <div className={styles.content}>
          <Skeleton width={200} height={20} />
        </div>
      </div>
    </div>
  );
}
