import {FontText, Img} from "@components";
import {IDBNotice} from "@data";
import {api, COLOR, decryptQuery, getParameterObject} from "@utils";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "./style.module.scss";

export default function NoticeDetailPage() {
  const location = useLocation();
  const {safeArea} = useRecoilValue(getParameterState);
  const {k} = getParameterObject({url: location.search});
  const no = decryptQuery({query: k});
  const [noticeDetail, setNoticeDetail] = useState<IDBNotice>();

  const {title, createdAt, content, img} = noticeDetail || {};

  async function getNoticeDetail() {
    try {
      if (!no && isNaN(Number(no))) {
        return;
      }
      const {data, success, message} = await api.v1.notice.noticeDetail({
        no: Number(no),
      });
      if (success) {
        setNoticeDetail(data);
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  useEffect(() => {
    getNoticeDetail();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <FontText lineHeight={150}>{title}</FontText>
        <div className={styles.dateRow}>
          <FontText
            fontSize={12}
            lineHeight={150}
            color={COLOR.gray4}
            className={styles.date}>
            {moment(createdAt).format("YYYY.MM.DD")}
          </FontText>
          {moment(createdAt).format("YYYY.MM.DD") ===
            moment().format("YYYY.MM.DD") && (
            <FontText fontSize={10} lineHeight={150} color={COLOR.red500}>
              NEW
            </FontText>
          )}
        </div>
      </div>
      <div className={styles.contentContainer}>
        {!!img && (
          <div className={styles.img}>
            <Img src={img} />
          </div>
        )}
        <FontText fontSize={12}>{content}</FontText>
      </div>
      <div style={{height: safeArea?.bottom || 0}} />
    </div>
  );
}
