import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 16,
  height = 16,
  fill = COLOR.orangeSub2,
}: {
  width?: number;
  height?: number;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.750977 6L8.75098 0L16.751 6L8.75098 12L0.750977 6Z"
        fill={fill}
      />
    </svg>
  );
}
