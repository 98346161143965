import React from "react";

export default function Svg({
  width = 20,
  fill = "#003824",
}: {
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none">
      <g clipPath="url(#clip0_3156_26481)">
        <path
          d="M10.0002 18.0163C14.4276 18.0163 18.0168 14.4272 18.0168 9.99967C18.0168 5.57219 14.4276 1.98301 10.0002 1.98301C5.57268 1.98301 1.9835 5.57219 1.9835 9.99967C1.9835 14.4272 5.57268 18.0163 10.0002 18.0163ZM19.0668 9.99967C19.0668 15.0071 15.0075 19.0663 10.0002 19.0663C4.99278 19.0663 0.933496 15.0071 0.933496 9.99967C0.933496 4.99229 4.99278 0.933008 10.0002 0.933008C15.0075 0.933008 19.0668 4.99229 19.0668 9.99967Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.2"
        />
        <path
          d="M11.4061 10.2223L11.4061 10.2223L11.4054 10.2226C11.2183 10.3322 11.0111 10.3872 10.7826 10.3872H8.83781C8.8194 10.3872 8.80448 10.3722 8.80448 10.3538V7.99025C8.80448 7.97184 8.8194 7.95691 8.83781 7.95691H10.7826C11.1322 7.95691 11.426 8.0763 11.6673 8.31541L11.6673 8.31542L11.6683 8.31636C11.9162 8.54897 12.0393 8.83153 12.0393 9.16699C12.0393 9.38634 11.9826 9.58905 11.8689 9.7761C11.7549 9.96355 11.6009 10.1121 11.4061 10.2223ZM13.031 10.4431L13.0312 10.4427C13.2659 10.0518 13.3833 9.62625 13.3833 9.16699C13.3833 8.70773 13.2659 8.28216 13.0312 7.89126C12.7967 7.50071 12.4768 7.19225 12.0723 6.96633C11.6742 6.73337 11.237 6.61699 10.7616 6.61699H7.58333C7.5097 6.61699 7.45 6.67669 7.45 6.75033V14.0837C7.45 14.1573 7.5097 14.217 7.58333 14.217H8.67115C8.74478 14.217 8.80448 14.1573 8.80448 14.0837V11.7402C8.80448 11.7218 8.8194 11.7069 8.83781 11.7069H10.7616C11.2369 11.7069 11.6741 11.594 12.0722 11.3677L12.0724 11.3676C12.4766 11.1351 12.7965 10.8268 13.031 10.4431Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.1"
        />
      </g>
      <defs>
        <clipPath id="clip0_3156_26481">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
