import React from "react";
import styles from "./style.module.scss";

interface IProps {
  width?: number;
  height?: number;
  borderRadius?: number;
  ratio?: number;
}

export default function Skeleton({width, height, borderRadius, ratio}: IProps) {
  return (
    <div className={styles.wrapper} style={{width, borderRadius}}>
      <div
        className={styles.skeleton}
        style={{
          height,
          ...(ratio && {aspectRatio: ratio, objectFit: "cover"}),
        }}></div>
    </div>
  );
}
