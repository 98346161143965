import {DMB_TYPE_TITLE, Svgs} from "@assets";
import {FontText, Modal} from "@components";
import {IItemDmbData} from "@data";
import {COLOR} from "@utils";
import React from "react";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "../style.module.scss";

export function ItemDmbModal({
  visible,
  close,
  item_type_no,
  dmb_list,
  sub_title,
  sub_content,
}: {
  visible: boolean;
  close: () => void;
  item_type_no?: number;
  dmb_list?: IItemDmbData[];
  sub_title?: string;
  sub_content?: string;
}) {
  const {safeArea} = useRecoilValue(getParameterState);
  const ACTIVE_TITLE = item_type_no ? DMB_TYPE_TITLE[item_type_no] : undefined;

  return (
    <Modal
      visible={visible}
      close={close}
      className={styles.itemDmbModal}
      position={{bottom: 0}}>
      <div className={styles.wrapper}>
        <div className={styles.titleRow}>
          <div className={styles.title}>
            <FontText
              color={COLOR.black}
              fontSize={20}
              fontWeight={700}
              lineHeight={150}>
              {ACTIVE_TITLE?.title}
            </FontText>
            {!!sub_title && (
              <div className={styles.subTitle}>
                <FontText color={COLOR.gray4} fontSize={16} lineHeight={150}>
                  {sub_title} 당
                </FontText>
              </div>
            )}
          </div>
          <div onClick={close}>
            <Svgs.CloseFullSvg stroke={COLOR.gray1} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.dmbContainer}>
            {(dmb_list || []).map((_item, index) => {
              return (
                <div
                  key={index}
                  className={[
                    styles.dmbItem,
                    styles.isBorder,
                    !ACTIVE_TITLE?.isHalf && styles.full,
                  ].join(" ")}>
                  <FontText
                    lineHeight={150}
                    fontWeight={600}
                    color={COLOR.gray3}>
                    {_item.detail?.title}
                  </FontText>
                  {((!isNaN(Number(_item.content)) &&
                    Number(_item.content) > 0) ||
                    (isNaN(Number(_item.content)) && !!_item.content)) && (
                    <div className={styles.contentRow}>
                      <FontText fontSize={16} lineHeight={150} fontWeight={600}>
                        {_item.content}
                      </FontText>
                      {!!_item.unit && (
                        <div className={styles.percent}>
                          <FontText
                            color={COLOR.gray4}
                            fontSize={16}
                            lineHeight={150}>
                            {_item.unit}
                          </FontText>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {!!sub_content && (
          <div className={styles.description}>
            <FontText color={COLOR.gray4}>{sub_content}</FontText>
          </div>
        )}
        <div className={styles.bottomBtn} onClick={close}>
          <FontText fontWeight={600} fontSize={16} lineHeight={150}>
            닫기
          </FontText>
        </div>
        <div style={{height: safeArea?.bottom || 0}} />
      </div>
    </Modal>
  );
}
