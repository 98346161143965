import {DMB_TYPE_TITLE, Svgs} from "@assets";
import {FontText} from "@components";
import {
  IItemDmbData,
  IItemDmbList,
  IProductDetailComposition,
  TProductItemDetail,
} from "@data";
import {COLOR} from "@utils";
import React, {useEffect, useState} from "react";
import styles from "../style.module.scss";
import {ItemDmbModal} from "./ItemDmbModal";

export function CompositionItem({
  data,
  option_no,
  item_dmb_list = [],
}: {
  data: TProductItemDetail[];
  option_no?: number;
  item_dmb_list?: IItemDmbList[];
}) {
  const [activeItemTypeNoTab, setActiveItemTypeNoTab] = useState<number>();
  const [dmbList, setDmbList] = useState<IItemDmbData[]>();
  const [isDetailModal, setIsDetailModal] = useState(false);

  const list = (data.filter(_data => _data?.type === "composition") ||
    []) as IProductDetailComposition[];
  const item = option_no
    ? list.find(_data => _data?.data_json?.option_no === option_no)
    : list[0];
  const itemList = item?.data_json?.data || [];
  const dmbTitle = (item_dmb_list || []).find(
    item => item.item_type_no === activeItemTypeNoTab,
  );

  const DMB_TAB_TYPE_TITLE = activeItemTypeNoTab
    ? DMB_TYPE_TITLE[activeItemTypeNoTab]
    : undefined;
  const MAX_DMB_COUNT = DMB_TAB_TYPE_TITLE?.isHalf ? 8 : 4;
  const SLICE_DMB_LIST = (dmbList || []).slice(0, MAX_DMB_COUNT);

  useEffect(() => {
    const tempDmbList: IItemDmbData[] = (
      item_dmb_list.find(item => item.item_type_no === activeItemTypeNoTab)
        ?.data || []
    ).sort((a, b) => (a.ordering || 0) - (b.ordering || 0));
    setDmbList(tempDmbList);
  }, [activeItemTypeNoTab]);

  if (!item) {
    return <></>;
  }

  return (
    <>
      <div className={styles.compositionItem}>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              <FontText fontSize={16} lineHeight={150} fontWeight={700}>
                {item?.data_json?.title || "영양 성분 정보"}
              </FontText>
            </div>
            <div style={{flexDirection: "row", alignItems: "center"}}>
              <div className={styles.subTitle}>
                <FontText
                  fontSize={12}
                  lineHeight={150}
                  fontWeight={500}
                  color={COLOR.gray4}>
                  {item?.data_json?.sub_title || "성견 기준"}
                </FontText>
              </div>
              {/* <Svgs.QuestionCircleSvg width={20} /> */}
            </div>
          </div>
          {(item_dmb_list || []).length > 0 && (
            <div className={styles.tabConatiner}>
              <div
                className={[
                  styles.tabItem,
                  !activeItemTypeNoTab && styles.active,
                ].join(" ")}
                onClick={() => setActiveItemTypeNoTab(undefined)}>
                <FontText>등록성분</FontText>
              </div>
              {(item_dmb_list || []).map(dmb => {
                const tabTitle =
                  (!!dmb.item_type_no &&
                    DMB_TYPE_TITLE[dmb.item_type_no]?.short_title) ||
                  "";
                const isActive = dmb.item_type_no === activeItemTypeNoTab;
                return (
                  <div
                    key={dmb.item_type_no}
                    className={[styles.tabItem, isActive && styles.active].join(
                      " ",
                    )}
                    onClick={() => setActiveItemTypeNoTab(dmb.item_type_no)}>
                    <FontText>
                      {tabTitle}
                      {!!dmb.title && `(${dmb.title})`}
                    </FontText>
                  </div>
                );
              })}
            </div>
          )}
          {!activeItemTypeNoTab && (
            <div className={styles.contentContainer}>
              <div className={styles.contentContainer2}>
                {itemList.map((_item, index) => {
                  const rating = _item.rating || "middle";
                  const item_content = _item.content;
                  const item_content_arr = (item_content || "").split(".");
                  const content = isNaN(Number(item_content))
                    ? item_content
                    : `${item_content_arr[0]}.${
                        item_content_arr.length > 1
                          ? `${item_content_arr[1]}`
                          : "0"
                      }`;
                  const isLeft = index % 2 === 0;
                  const isBottomBorder =
                    index <
                    itemList.length - (itemList.length % 2 === 0 ? 2 : 1);

                  return (
                    <div
                      key={index}
                      className={[
                        styles.item,
                        isBottomBorder && styles.isBorder,
                        isLeft && styles.isLeft,
                      ].join(" ")}>
                      <div>
                        <FontText
                          lineHeight={150}
                          fontWeight={600}
                          color={COLOR.gray5}>
                          {_item.title}
                        </FontText>
                        <div className={styles.contentRow}>
                          <div className={styles.content}>
                            <FontText
                              fontSize={16}
                              lineHeight={150}
                              fontWeight={600}>
                              {content}
                            </FontText>
                          </div>
                          {!isNaN(Number(_item.content)) && (
                            <div className={styles.percent}>
                              <FontText
                                color={COLOR.gray4}
                                fontSize={16}
                                lineHeight={150}>
                                %
                              </FontText>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {!!activeItemTypeNoTab && (
            <div className={styles.contentContainer}>
              <div className={styles.contentContainer2}>
                {SLICE_DMB_LIST.map((_item, index) => {
                  const isLeft = index % 2 === 0;
                  const isBottomBorder =
                    index <
                    (DMB_TAB_TYPE_TITLE?.isHalf
                      ? SLICE_DMB_LIST.length -
                        (SLICE_DMB_LIST.length % 2 === 0 ? 2 : 1)
                      : Math.min(
                          (dmbList || []).length - 1,
                          MAX_DMB_COUNT - 1,
                        ));

                  return (
                    <div
                      key={index}
                      className={[
                        styles.item,
                        isBottomBorder && styles.isBorder,
                        !DMB_TAB_TYPE_TITLE?.isHalf && styles.full,
                        isLeft && styles.isLeft,
                      ].join(" ")}>
                      <div>
                        <FontText
                          lineHeight={150}
                          fontWeight={600}
                          color={COLOR.gray3}>
                          {_item.detail?.title}
                        </FontText>
                        {((!isNaN(Number(_item.content)) &&
                          Number(_item.content) > 0) ||
                          (isNaN(Number(_item.content)) &&
                            !!_item.content)) && (
                          <div className={styles.contentRow}>
                            <div className={styles.content}>
                              <FontText
                                fontSize={16}
                                lineHeight={150}
                                fontWeight={600}>
                                {_item.content}
                              </FontText>
                            </div>
                            {!!_item.unit && (
                              <div className={styles.percent}>
                                <FontText
                                  color={COLOR.gray4}
                                  fontSize={16}
                                  lineHeight={150}>
                                  {_item.unit}
                                </FontText>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {(dmbList || []).length > MAX_DMB_COUNT && (
                  <div
                    className={styles.moreBtn}
                    onClick={() => setIsDetailModal(true)}>
                    <FontText color={COLOR.gray4} fontWeight={600}>
                      성분 정보 더 보기
                    </FontText>
                    <Svgs.ChevronIcon rotate={270} />
                  </div>
                )}
              </div>
            </div>
          )}
          {!!dmbTitle?.content && (
            <div className={styles.subContent}>
              <FontText color={COLOR.gray4} fontSize={16} lineHeight={150}>
                {dmbTitle?.content}
              </FontText>
            </div>
          )}
        </div>
      </div>
      <ItemDmbModal
        visible={isDetailModal}
        close={() => setIsDetailModal(false)}
        item_type_no={activeItemTypeNoTab}
        dmb_list={dmbList}
        sub_title={dmbTitle?.title}
        sub_content={dmbTitle?.content}
      />
    </>
  );
}
