import {FontText} from "@components";
import {IProductDetailFeedAmount, TProductItemDetail} from "@data";
import {COLOR} from "@utils";
import React from "react";
import styles from "../style.module.scss";

export function FeedAmountItem({
  data,
  option_no,
}: {
  data: TProductItemDetail[];
  option_no?: number;
}) {
  const list = (data.filter(_data => _data?.type === "feedAmount") ||
    []) as IProductDetailFeedAmount[];
  const item = option_no
    ? list.find(_data => _data?.data_json?.option_no === option_no)
    : list[0];

  if (!item) {
    return <></>;
  }

  return (
    <div className={styles.feedAmountItem}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <FontText fontSize={16} fontWeight={700}>
              {item?.data_json?.title || "급여량"}
            </FontText>
          </div>
          <FontText fontSize={12} fontWeight={500} color={COLOR.gray4}>
            {item?.data_json?.sub_title || "성견 기준"}
          </FontText>
        </div>
        <div className={styles.listContainer}>
          {(item?.data_json?.data || []).map((_item, index) => {
            const radiusLeft = index % 3 === 0;
            const radiusRight =
              index % 3 === 2 ||
              index === (item?.data_json?.data || []).length - 1;

            return (
              <div
                key={index}
                className={[
                  styles.item,
                  radiusLeft && styles.radiusLeft,
                  radiusRight && styles.radiusRight,
                ].join(" ")}>
                <div className={styles.titleContainer}>
                  <div className={styles.title}>
                    <FontText lineHeight={150}>{_item.title}</FontText>
                  </div>
                </div>
                <div className={styles.contentContainer}>
                  <FontText lineHeight={150}>{_item.content}</FontText>
                </div>
              </div>
            );
          })}
        </div>
        {!!item?.data_json?.calorie?.calorie && (
          <div className={styles.calorieContainer}>
            <FontText
              fontSize={16}
              lineHeight={150}
              fontWeight={500}
              color={COLOR.orangeSub2}>
              * 칼로리 :{" "}
              {Number(item.data_json?.calorie?.calorie).toLocaleString()}
              {item.data_json?.calorie?.calorie_unit}
              {!!item.data_json?.calorie?.amount &&
                ` / ${item.data_json?.calorie?.amount}${item.data_json?.calorie?.amount_unit}`}
              {!!item.data_json?.calorie?.weight &&
                `(${Number(
                  item.data_json?.calorie?.weight,
                ).toLocaleString()}${item.data_json?.calorie?.weight_unit})`}
            </FontText>
          </div>
        )}
        {!!item?.data_json?.discription && (
          <div className={styles.discriptionContainer}>
            <FontText lineHeight={150} color={COLOR.gray4}>
              {item?.data_json?.discription}
            </FontText>
          </div>
        )}
      </div>
    </div>
  );
}
