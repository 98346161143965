import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 16,
  stroke = "#5DCDAD",
}: {
  width?: number;
  stroke?: string;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 4L5.99996 11.3333L2.66663 8"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
