import {FontText, Img} from "@components";
import {APP_NAVIGATE, IPost} from "@data";
import {useDownload, useRNPost} from "@hook";
import {COLOR, isDefined} from "@utils";
import React, {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "../style.module.scss";

interface IImage {
  img_url: string;
  title?: string;
  post_no: number;
}

export function PostSection({
  post = [],
  item_name,
}: {
  post: IPost[];
  item_name: string;
}) {
  const {isApp} = useRecoilValue(getParameterState);
  const {openDownloadAppModal} = useDownload();
  const {RNpostMessage} = useRNPost();
  const [imageArr, setImageArr] = useState<IImage[]>([]);

  function goDetail({post_no}: {post_no: number}) {
    if (isApp) {
      RNpostMessage({
        action: "navigate",
        screenId: APP_NAVIGATE.POST_DETAIL,
        id: post_no,
        data: {no: post_no},
      });
    } else {
      openDownloadAppModal();
    }
  }

  useEffect(() => {
    const postArr = post
      .map(item => ({
        img_url: item.photo?.[0]?.thumbnail_img_url || item.photo?.[0]?.img_url,
        title: item.title,
        post_no: item.no,
      }))
      .filter(item => isDefined(item.img_url) && isDefined(item.post_no))
      .slice(0, 3) as IImage[];

    setImageArr(postArr);
  }, []);

  return (
    <div className={styles.postSection}>
      <FontText fontSize={18} lineHeight={150} fontWeight={700}>
        {item_name}로 만든 레시피
      </FontText>
      <div
        className={[
          styles.imgSection,
          [1, 2].includes(imageArr.length) && styles.lengthMax,
        ].join(" ")}>
        <div className={styles.left}>
          {imageArr.slice(0, 1).map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => goDetail({post_no: item.post_no})}
                className={styles.item}>
                <Img src={item.img_url} />
                <div className={styles.imgText}>
                  <FontText
                    fontWeight={700}
                    color={COLOR.white}
                    numberOfLines={1}>
                    {item.title}
                  </FontText>
                </div>
              </div>
            );
          })}
        </div>
        {imageArr.length > 1 && (
          <div
            className={styles.right}
            style={{flex: imageArr.length === 2 ? 2 : 1}}>
            {imageArr.slice(1, 3).map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => goDetail({post_no: item.post_no})}
                  className={styles.item}>
                  <Img src={item.img_url} />
                  <div className={styles.imgText}>
                    <FontText
                      fontWeight={700}
                      color={COLOR.white}
                      numberOfLines={1}>
                      {item.title}
                    </FontText>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
