import {FontText, Img} from "@components";
import {IDBBanner, ScreenId} from "@data";
import {useDownload, useMember} from "@hook";
import {COLOR, encryptQuery} from "@utils";
import moment from "moment";
import React from "react";
import {useNavigate} from "react-router-dom";
import styles from "./style.module.scss";

interface IProps {
  item: IDBBanner;
  isSimple?: boolean;
}

export default function BannerItem({item, isSimple = false}: IProps) {
  const navigation = useNavigate();
  const {openDownloadAppModal} = useDownload();
  const {user} = useMember();

  const img_url = isSimple ? item.thumbnail_img : item.big_img_url;

  function goDetail() {
    if (item.external_url) {
      window.open(item.external_url);
    } else if (item.url) {
      switch (item.url) {
        // case 'login':
        //   user
        //     ? bottomNavigation.navigate(ScreenId.MY_PAGE)
        //     : navigation.push(ScreenId.LOGIN_DEFAULT);
        //   break;
        // case 'petinfo':
        //   !user
        //     ? navigation.push(ScreenId.LOGIN_DEFAULT)
        //     : !!petList && petList.length > 0
        //     ? bottomNavigation.navigate(ScreenId.MY_PAGE)
        //     : navigation.push(ScreenId.MY_PETINFO, {
        //         prevPage: 'home_page',
        //       });
        //   break;
        // case 'attendance':
        //   navigation.push(ScreenId.EVENT_ATTENDANCE);
        //   break;
        case "noticeDetail":
          if (item.target_no) {
            navigation(
              `${ScreenId.NOTICE_DETAIL}?k=${encryptQuery({
                query: `${item.target_no}`,
              })}`,
            );
          }
          break;
        // case 'event':
        default:
          //   if (item.no) {
          //     navigation.push(ScreenId.BANNER_DETAIL, {
          //       no: item.no,
          //     });
          //   }
          openDownloadAppModal();
          break;
      }
    }
  }

  return (
    <div
      className={[styles.bannerItem, isSimple && styles.simple].join(" ")}
      onClick={goDetail}>
      <Img src={img_url} />
      <div className={styles.textContainer}>
        {!!item.title && (
          <FontText
            fontSize={isSimple ? 14 : 22}
            lineHeight={150}
            color={isSimple ? COLOR.gray1 : COLOR.black}>
            {item.title}{" "}
          </FontText>
        )}
        {!!item.bold_title && (
          <FontText
            fontSize={isSimple ? 14 : 22}
            lineHeight={150}
            color={COLOR.gray1}
            fontWeight={700}>
            {item.bold_title}
          </FontText>
        )}
        {!isSimple && !!item.sub_title && (
          <div className={styles.subTitle}>
            <FontText fontSize={16} color={COLOR.gray1}>
              {item.sub_title}
            </FontText>
          </div>
        )}
        {!isSimple && (
          <div className={styles.dateContainer}>
            {item.always_open_yn === "Y" ? (
              <FontText color={COLOR.gray3}>{item.always_open_title}</FontText>
            ) : (
              <>
                {(moment(item.openDate).isValid() ||
                  moment(item.closeDate).isValid()) && (
                  <FontText color={COLOR.gray3}>
                    {moment(item.openDate).isValid()
                      ? moment(item.openDate).format("MM.DD")
                      : ""}{" "}
                    -{" "}
                    {moment(item.closeDate).isValid()
                      ? moment(item.closeDate).format("MM.DD")
                      : ""}
                  </FontText>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
