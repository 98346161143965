import {Svgs} from "@assets";
import {COLOR} from "@utils";
import React from "react";
import styles from "./style.module.scss";

type TDirect = "bottom" | "top" | "left" | "right";
interface IProps {
  content: JSX.Element;
  isCloseBtn?: boolean;
  close?: () => void;
  direct?: TDirect;
  horizon?: number;
  vertical?: number;
  backgroundColor?: COLOR;
  closeBtnColor?: COLOR;
  width?: number;
}

const directStyle: {
  [key in TDirect]: string;
} = {
  bottom: styles.bottom,
  top: styles.top,
  left: styles.left,
  right: styles.right,
};

export default function Tooltip({
  content,
  isCloseBtn = true,
  close,
  direct = "bottom",
  horizon = 58,
  vertical = 15,
  backgroundColor = COLOR.black,
  closeBtnColor = COLOR.white,
  width,
}: IProps) {
  return (
    <div className={styles.tootip}>
      <div
        className={styles.box}
        style={{
          backgroundColor,
          width,
          maxWidth: `calc(var(--DEVICE-WIDTH) - 24px - ${horizon}px)`,
        }}>
        <div
          className={[styles.arrowView, directStyle[direct]].join(" ")}
          style={{
            backgroundColor,
            ...(["bottom", "top"].includes(direct) && {left: horizon}),
            ...(["left", "right"].includes(direct) && {top: vertical}),
          }}>
          <Svgs.RectangleSvg
            width={16}
            height={["left", "right"].includes(direct) ? 12 : 16}
            fill={backgroundColor}
          />
        </div>
        {content}
        {isCloseBtn && (
          <div onClick={close} className={styles.close}>
            <Svgs.CloseFullSvg width={16} stroke={closeBtnColor} />
          </div>
        )}
      </div>
    </div>
  );
}
