import React from "react";

export default function Svg({
  width = 20,
  stroke = "#003824",
}: {
  width?: number;
  stroke?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none">
      <line
        x1="10.75"
        y1="9.16797"
        x2="10.75"
        y2="15.8346"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M8.5 10.9167C5.62833 10.9167 3.29517 8.61106 3.25065 5.75H4.83333C7.705 5.75 10.0382 8.0556 10.0827 10.9167H8.5Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M12.333 9.25065C15.2047 9.25065 17.5378 6.94505 17.5824 4.08398H15.9997C13.128 4.08398 10.7948 6.38959 10.7503 9.25065H12.333Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M3.33301 17.5007C5.22451 16.4734 7.88547 15.834 10.833 15.834C13.7805 15.834 16.4415 16.4734 18.333 17.5007"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
