import React from "react";

export default function Svg({
  width = 24,
  stroke = "#BDBDBD",
  fill,
}: {
  width?: number;
  stroke?: string;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.737 2.76172H8.084C6.025 2.76172 4.25 4.43072 4.25 6.49072V17.2037C4.25 19.3797 5.909 21.1147 8.084 21.1147H16.073C18.133 21.1147 19.802 19.2647 19.802 17.2037V8.03772L14.737 2.76172Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4727 2.75V5.659C14.4727 7.079 15.6217 8.231 17.0407 8.234C18.3577 8.237 19.7047 8.238 19.7957 8.232"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.5L11.7273 15L15 10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
