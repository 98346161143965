import React from "react";

export default function Svg({
  width = 47,
  fill = "#E0E0E0",
}: {
  width?: number;
  stroke?: string;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={(width / 47) * 44}
      viewBox="0 0 39 37"
      fill="none">
      <path
        d="M17.6109 1.43478C18.2335 -0.356344 20.7665 -0.356346 21.3891 1.43477L24.6858 10.9189C24.9604 11.709 25.6978 12.2447 26.5342 12.2618L36.5728 12.4664C38.4687 12.505 39.2514 14.9141 37.7403 16.0597L29.7392 22.1258C29.0726 22.6311 28.7909 23.498 29.0331 24.2987L31.9407 33.9092C32.4898 35.7242 30.4405 37.2131 28.884 36.13L20.6424 30.3949C19.9557 29.9171 19.0443 29.9171 18.3576 30.3949L10.116 36.13C8.55949 37.2131 6.51019 35.7242 7.0593 33.9092L9.96685 24.2987C10.2091 23.498 9.92743 22.6311 9.26083 22.1258L1.25965 16.0597C-0.251414 14.9141 0.531344 12.505 2.42719 12.4664L12.4658 12.2618C13.3022 12.2447 14.0396 11.709 14.3142 10.9189L17.6109 1.43478Z"
        fill={fill}
      />
    </svg>
  );
}
