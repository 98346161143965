export enum ScreenId {
  HOME = "/",
  POST = "/post",
  POST_DETAIL = "/postdetail",
  PRODUCT_DETAIL = "/productdetail",
  NOTICE_DETAIL = "/noticedetail",
}

export enum APP_NAVIGATE {
  PRODUCT = "product",
  PRODUCT_DETAIL = "productdetail", // same product
  REVIEW_LIST = "reviewList",
  REVIEW_DETAIL = "reviewDetail",
  ORDER_DETAIL = "orderDetail",
  COUPON = "coupon",
  POINT = "point",
  ITEM_QNA = "itemQnA",
  MEMBER_QNA = "memberQnA",
  BANNER = "banner",
  SECTION_DETAIL = "sectionDetail",
  NOTICE_DETAIL = "noticeDetail",
  ORDER_LIST = "orderList",
  ORDER_LIST_SUBSCRIBE = "orderListSubscribe",
  LIKE_LIST = "likeList",
  RECENT_LIST = "recentList",
  ALARM = "alarm",
  MY_REVIEW = "myReview",
  CART = "cart",
  PARTNER = "partner",
  ATTENDANCE = "attendance",
  FREQUENCY = "frequency",
  POST = "post",
  POST_DETAIL = "postDetail",
  LOGIN = "login",
  PHOTO_REVIEW_LIST = "photoReviewList",
  CREATE_DECLARATION_REVIEW = "createDeclarationReview",
  WRITE_REVIEW = "writeReview",
  WRITE_PRODUCT_QNA = "writeProductQnA",
  PRODUCT_QNA_LIST = "productQnaList",
  CATEGORY_LIST = "categoryList",
  MY_PAGE = "mypage",
}
