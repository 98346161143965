import {Svgs} from "@assets";
import {IProductQna} from "@data";
import {useMember} from "@hook";
import {formatNickname} from "@utils";
import moment from "moment";
import React, {useState} from "react";
import styles from "./style.module.scss";

interface IProps {
  item: IProductQna;
  setDelete?: (no: number) => void;
}

export default function ProductQnaItem({item, setDelete}: IProps) {
  const {user} = useMember();
  const [active, setActive] = useState(false);

  const isSecret = item.hide_yn === "Y" && item.member_no !== user?.no;

  return (
    <div className={styles.container}>
      <div
        onClick={() => {
          if (item.answer && !isSecret) {
            setActive(!active);
          }
        }}>
        <div className={styles.titleContainer}>
          <div className={styles.row}>
            {item.member_no === user?.no && (
              <div className={styles.myQna}>내 문의</div>
            )}
            <div className={styles.title}>
              <span>{isSecret ? "비밀글 입니다." : item.title}</span>
              {item.hide_yn === "Y" && <Svgs.LockSvg />}
            </div>
          </div>
          {item.member_no === user?.no && (
            <div
              className={styles.deleteBtn}
              onClick={() => {
                if (item.no) {
                  setDelete?.(item.no);
                }
              }}>
              삭제
            </div>
          )}
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.isAnswer}>
            {item.answer ? "답변완료" : "답변미완료"}
          </div>
          <div className={styles.bar} />
          <div className={styles.nickname}>
            {formatNickname(item.member?.nickname || item.member?.name)}
          </div>
          <div className={styles.bar} />
          <div className={styles.date}>
            {moment(item.createdAt).format("YYYY.MM.DD")}
          </div>
        </div>
      </div>
      <div
        className={[styles.answerContainer, active && styles.open].join(" ")}>
        <div>
          <div className={styles.titleRow}>
            <div className={styles.badge}>주토피아’s 답변</div>
            <div className={styles.date}>
              {moment(item.answerDt).format("YYYY.MM.DD")}
            </div>
          </div>
          <div className={styles.answer}>{item.answer}</div>
        </div>
      </div>
    </div>
  );
}
