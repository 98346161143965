import {Routes, Route, useNavigationType, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import * as pages from "@pages";
import {HeaderProps, ScreenId} from "@data";
import {useSetRecoilState} from "recoil";
import {headerState} from "recoils/atoms";
import {Svgs} from "@assets";
import {COLOR} from "@utils";
import {FontText} from "@components";
import {useDownload} from "@hook";

interface IRoute {
  path: ScreenId;
  element: JSX.Element;
  header: HeaderProps;
}

export default function WebRouteApp() {
  const action = useNavigationType();
  const location = useLocation();
  const {openDownloadAppModal} = useDownload();
  const setHeader = useSetRecoilState(headerState);
  const pathname = location.pathname;

  const homeHeaderRightElement = [
    {
      icon: (
        <div
          style={{
            display: "flex",
            width: 44,
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Svgs.SearchSvg />
        </div>
      ),
      onPress: openDownloadAppModal,
    },
    {
      icon: (
        <div
          style={{
            display: "flex",
            width: 44,
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 4,
          }}>
          <Svgs.CartIcon stroke={COLOR.white} />
        </div>
      ),
      onPress: openDownloadAppModal,
    },
  ];

  const initRouteList: IRoute[] = [
    {
      path: ScreenId.HOME,
      element: <pages.HomePage />,
      header: {
        backgroundColor: COLOR.green,
        leftElements: [
          {
            icon: (
              <div style={{display: "flex", marginLeft: 16}}>
                <Svgs.ZoopiLogoSvg />
              </div>
            ),
          },
        ],
        rightElements: homeHeaderRightElement,
      },
    },
    {
      path: ScreenId.POST,
      element: <pages.PostPage />,
      header: {
        backgroundColor: COLOR.green,
        leftElements: [
          {
            icon: (
              <div style={{display: "flex", marginLeft: 16}}>
                <FontText fontSize={18} color={COLOR.white} fontWeight={700}>
                  한상차림
                </FontText>
              </div>
            ),
          },
        ],
        rightElements: homeHeaderRightElement,
      },
    },
    {
      path: ScreenId.POST_DETAIL,
      element: <pages.PostDetailPage />,
      header: {title: ""},
    },
    {
      path: ScreenId.PRODUCT_DETAIL,
      element: <pages.ProductDetailPage />,
      header: {title: ""},
    },
    {
      path: ScreenId.NOTICE_DETAIL,
      element: <pages.NoticeDetailPage />,
      header: {title: "공지사항"},
    },
  ];

  useEffect(() => {
    if (action !== "POP") window.scrollTo(0, 0);

    setHeader({
      ...(initRouteList.find(item => item.path === pathname)?.header || {}),
    });
  }, [action, pathname]);

  return (
    <Routes>
      <Route
        path="/"
        element={(initRouteList || [])[0]?.element || <pages.ErrorPage />}
      />
      {initRouteList.map((_route, i) => (
        <Route key={i} path={_route.path} element={_route.element} />
      ))}
      <Route path="*" element={<pages.ErrorPage />} />
    </Routes>
  );
}
