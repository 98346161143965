import {useState, useEffect} from "react";
import _, {throttle} from "lodash";

interface IProps {
  refElement: React.RefObject<HTMLElement>;
  fetchData: () => Promise<any>;
  threshold?: number;
  throttle?: number;
}

export default function useInfiniteScroll({
  refElement,
  fetchData,
  threshold = 0.1,
  throttle,
}: IProps) {
  const [isLoading, setIsLoading] = useState(false);

  async function refresh() {
    fetchData?.().then(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !isLoading) {
          setIsLoading(true);
          refresh();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold, // 요소가 뷰포트의 10% 이상 교차하면 이벤트가 발생
      },
    );

    if (refElement.current) {
      observer.observe(refElement.current);
    }

    return () => {
      if (refElement.current) {
        observer.unobserve(refElement.current);
      }
    };
  }, [isLoading, fetchData]);

  return {refElement, isLoading};
}
