import {Svgs} from "@assets";
import {Modal} from "@components";
import {useDownload, useReactGA} from "@hook";
import {COLOR} from "@utils";
import FontText from "components/FontText";
import React, {useEffect} from "react";
import styles from "./style.module.scss";

export default function DownloadModal() {
  const {isDownloadAppModal, closeDownloadAppModal, goToDownload} =
    useDownload();
  const {GAEventTrigger} = useReactGA();

  useEffect(() => {
    if (isDownloadAppModal) {
      GAEventTrigger({
        action: "click_step2",
        category: "click_step2",
        label: "팝업창 진입",
        value: 1,
      });
    }
  }, [isDownloadAppModal]);

  return (
    <Modal
      visible={!!isDownloadAppModal}
      backOpacity={70}
      close={closeDownloadAppModal}
      position={{bottom: 0}}>
      <div className={styles.modal}>
        <div className={styles.closeBtn} onClick={closeDownloadAppModal}>
          <Svgs.CloseFullSvg width={24} stroke={COLOR.black} />
        </div>
        <div className={styles.container}>
          <Svgs.ZoopiCouponTwoSvg />
          <div className={styles.textContainer}>
            <FontText
              fontSize={20}
              lineHeight={150}
              fontWeight={700}
              align="center">
              앱 다운 시 6천원 쿠폰과
              <br />
              또또우유 100원 구매 혜택을 드려요!
            </FontText>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.bottomBtn} onClick={goToDownload}>
            <FontText
              color={COLOR.white}
              fontSize={16}
              lineHeight={150}
              fontWeight={700}>
              쿠폰 받으러 가기
            </FontText>
          </div>
        </div>
      </div>
    </Modal>
  );
}
