import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 160,
  fill = COLOR.gray6,
}: {
  width?: number;
  fill?: COLOR;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={(Number(width) / 160) * 32}
      viewBox="0 0 160 32"
      fill="none">
      <path
        d="M15.1076 3.76535C15.4775 3.03357 16.5225 3.03356 16.8924 3.76535L20.0908 10.0921C20.2382 10.3836 20.5186 10.5848 20.842 10.631L27.956 11.6462C28.7833 11.7643 29.1094 12.7849 28.5039 13.3608L23.3885 18.2256C23.1459 18.4564 23.0348 18.7934 23.0928 19.1233L24.3039 26.0184C24.4469 26.8322 23.5972 27.4584 22.8622 27.081L16.4568 23.7919C16.1701 23.6446 15.8299 23.6446 15.5432 23.7919L9.13779 27.081C8.40278 27.4584 7.55314 26.8322 7.69608 26.0184L8.90723 19.1233C8.96518 18.7934 8.85414 18.4564 8.61145 18.2256L3.49615 13.3608C2.89057 12.7849 3.21667 11.7643 4.044 11.6462L11.158 10.631C11.4814 10.5848 11.7618 10.3836 11.9092 10.0921L15.1076 3.76535Z"
        fill={fill}
      />
      <path
        d="M47.1076 3.76535C47.4775 3.03357 48.5225 3.03356 48.8924 3.76535L52.0908 10.0921C52.2382 10.3836 52.5186 10.5848 52.842 10.631L59.956 11.6462C60.7833 11.7643 61.1094 12.7849 60.5039 13.3608L55.3885 18.2256C55.1459 18.4564 55.0348 18.7934 55.0928 19.1233L56.3039 26.0184C56.4469 26.8322 55.5972 27.4584 54.8622 27.081L48.4568 23.7919C48.1701 23.6446 47.8299 23.6446 47.5432 23.7919L41.1378 27.081C40.4028 27.4584 39.5531 26.8322 39.6961 26.0184L40.9072 19.1233C40.9652 18.7934 40.8541 18.4564 40.6115 18.2256L35.4961 13.3608C34.8906 12.7849 35.2167 11.7643 36.044 11.6462L43.158 10.631C43.4814 10.5848 43.7618 10.3836 43.9092 10.0921L47.1076 3.76535Z"
        fill={fill}
      />
      <path
        d="M79.1076 3.76535C79.4775 3.03357 80.5225 3.03356 80.8924 3.76535L84.0908 10.0921C84.2382 10.3836 84.5186 10.5848 84.842 10.631L91.956 11.6462C92.7833 11.7643 93.1094 12.7849 92.5039 13.3608L87.3885 18.2256C87.1459 18.4564 87.0348 18.7934 87.0928 19.1233L88.3039 26.0184C88.4469 26.8322 87.5972 27.4584 86.8622 27.081L80.4568 23.7919C80.1701 23.6446 79.8299 23.6446 79.5432 23.7919L73.1378 27.081C72.4028 27.4584 71.5531 26.8322 71.6961 26.0184L72.9072 19.1233C72.9652 18.7934 72.8541 18.4564 72.6115 18.2256L67.4961 13.3608C66.8906 12.7849 67.2167 11.7643 68.044 11.6462L75.158 10.631C75.4814 10.5848 75.7618 10.3836 75.9092 10.0921L79.1076 3.76535Z"
        fill={fill}
      />
      <path
        d="M111.108 3.76535C111.477 3.03357 112.523 3.03356 112.892 3.76535L116.091 10.0921C116.238 10.3836 116.519 10.5848 116.842 10.631L123.956 11.6462C124.783 11.7643 125.109 12.7849 124.504 13.3608L119.389 18.2256C119.146 18.4564 119.035 18.7934 119.093 19.1233L120.304 26.0184C120.447 26.8322 119.597 27.4584 118.862 27.081L112.457 23.7919C112.17 23.6446 111.83 23.6446 111.543 23.7919L105.138 27.081C104.403 27.4584 103.553 26.8322 103.696 26.0184L104.907 19.1233C104.965 18.7934 104.854 18.4564 104.611 18.2256L99.4961 13.3608C98.8906 12.7849 99.2167 11.7643 100.044 11.6462L107.158 10.631C107.481 10.5848 107.762 10.3836 107.909 10.0921L111.108 3.76535Z"
        fill={fill}
      />
      <path
        d="M143.108 3.76535C143.477 3.03357 144.523 3.03356 144.892 3.76535L148.091 10.0921C148.238 10.3836 148.519 10.5848 148.842 10.631L155.956 11.6462C156.783 11.7643 157.109 12.7849 156.504 13.3608L151.389 18.2256C151.146 18.4564 151.035 18.7934 151.093 19.1233L152.304 26.0184C152.447 26.8322 151.597 27.4584 150.862 27.081L144.457 23.7919C144.17 23.6446 143.83 23.6446 143.543 23.7919L137.138 27.081C136.403 27.4584 135.553 26.8322 135.696 26.0184L136.907 19.1233C136.965 18.7934 136.854 18.4564 136.611 18.2256L131.496 13.3608C130.891 12.7849 131.217 11.7643 132.044 11.6462L139.158 10.631C139.481 10.5848 139.762 10.3836 139.909 10.0921L143.108 3.76535Z"
        fill={fill}
      />
    </svg>
  );
}
