export enum cookieKeys {
  isLoginKey = "@isLogin",
  accessTokenKey = "@accessToken",
  pushTokenKey = "@pushToken",
  selectAddressStorageKey = "selectAddressStorageKey",
  eventBlockDateKey = "eventBlockDateKey",
  searchHistoryKey = "searchHistoryKey",
  checkpointTooltipKey = "checkpointTooltipKey",
  materialTooltipKey = "materialTooltipKey",
  firstAccessKey = "firstAccessKey",
  saveCardTypeKey = "@saveCardTypeKey",
  storeReviewKey = "@storeReviewKey",
  subscribeTooltipKey = "subscribeTooltipKey",
  noneSubscribeTooltipKey = "noneSubscribeTooltipKey",
  lastOrderNoKey = "lastOrderNoKey",
  eventPopupHideDateKey = "eventPopupHideDateKey",
  bottomReviewTooltipKey = "bottomReviewTooltipKey",
  isFrequencyCoachMarkHomeKey = "isFrequencyCoachMarkHomeKey",
  isFrequencyCoachMarkKey = "isFrequencyCoachMarkKey",
  isproductGuaranteeHideKey = "isproductGuaranteeHideKey",
  firstAccessDetailKey = "firstAccessDetailKey",
}
