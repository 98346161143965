import {ZOOTOPIA_LINK_URL} from "@assets";
import moment from "moment";
import CryptoJS, {AES, enc} from "crypto-js";
import {envUtil} from "./envUtil";

export function isDefined<T>(argument: T | undefined | null): argument is T {
  return argument !== undefined && argument !== null;
}

export function formatTime(date: moment.Moment | Date | string | undefined) {
  const momentDate = moment(date);
  if (!date && !momentDate.isValid()) {
    return "";
  }
  if (moment().diff(momentDate, "minute") < 3) {
    return "방금 전";
  } else if (moment().diff(momentDate, "minute") < 60) {
    return `${moment().diff(momentDate, "minute")}분 전`;
  } else if (moment().diff(momentDate, "hour") < 24) {
    return `${moment().diff(momentDate, "hour")}시간 전`;
  } else if (moment().diff(momentDate, "month") < 1) {
    return `${moment().diff(momentDate, "day")}일 전`;
  }
  return momentDate.format("YYYY.MM.DD");
}

export function getParameterObject({url}: {url: string}) {
  const query = url.indexOf("?") !== -1 ? url.split("?")[1] : url;
  const params = query.split("&") || [];
  const obj: {[key: string]: string} = {};

  for (let i = 0; i < params.length; i++) {
    const temp = params[i]?.split("=") || [];
    const key = temp[0];
    const value = temp.splice(1, temp.length).join("=");
    obj[key] = value;
  }
  return obj;
}

export function getParameter({url, key}: {url: string; key: string}) {
  const obj = getParameterObject({url});
  return obj[key];
}

export function handleClickBubble(e: React.MouseEvent<HTMLElement>) {
  e.stopPropagation();
}

export function handleClickCapture(e: React.MouseEvent<HTMLElement>) {
  e.preventDefault();
}

export function handleClickBubbleAndCapture(e: React.MouseEvent<HTMLElement>) {
  handleClickBubble(e);
  handleClickCapture(e);
}

export function encryptQuery({query}: {query: string}) {
  const encryptQuery = encrypt(query, envUtil.ENCRYPT_KEY);
  return encryptQuery;
}

export function decryptQuery({query}: {query: string}) {
  const newQuery = query.replace(/[?]/gi, "");
  const decryptQuery = decrypt(newQuery, envUtil.ENCRYPT_KEY);
  return decryptQuery;
}

export function copyURL(params: string) {
  const url = `${ZOOTOPIA_LINK_URL}${params}`;
  return url;
}

export function encrypt(data: string, key: string): string {
  const dataWA = enc.Utf8.parse(data);
  const keyWA = enc.Utf8.parse(key);
  const ivWA = enc.Utf8.parse(key.substring(0, 16));

  const cipher = AES.encrypt(dataWA, keyWA, {
    iv: ivWA,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  const result = cipher.ciphertext.toString(enc.Base64url);
  return result;
}

export function decrypt(encData: string, key: string): string {
  const keyWA = enc.Utf8.parse(key);
  const ivWA = enc.Utf8.parse(key.substring(0, 16));

  const cipher = AES.decrypt(
    enc.Base64url.parse(encData.replace(/=/gi, "")).toString(enc.Base64),
    keyWA,
    {iv: ivWA, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC},
  );
  const result = cipher.toString(enc.Utf8);
  return result;
}
