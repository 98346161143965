import React from "react";

export default function Svg({
  width = 16,
  stroke = "#828282",
  strokeWidth = 1.5,
  rotate = 0,
}: {
  width?: number;
  strokeWidth?: number;
  stroke?: string;
  rotate?: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      style={{
        transform: `rotate(${rotate}deg)`,
        transition: `all 0.3s ease`,
      }}>
      <path
        d="M13 6L8 11L3 6"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
