import {Svgs} from "@assets";
import {COLOR} from "@utils";
import {FontText} from "@components";
import {HeaderProps} from "data/common";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "./style.module.scss";
import {useRNPost} from "@hook";

const HEADER_HEIGHT = 44;

export default function Header({
  title,
  titleElement,
  titleAlign = "center",
  backgroundColor = COLOR.white,
  titleColor = COLOR.black,
  iconColor = COLOR.black,
  leftElements = [],
  leftDiv = false,
  rightElements = [],
  rightDiv = false,
  height = HEADER_HEIGHT,
}: HeaderProps) {
  const {key} = useLocation();
  const navigate = useNavigate();
  const {isHeader, isApp, safeArea} = useRecoilValue(getParameterState);
  const {RNpostMessage} = useRNPost();

  const leftElementList =
    leftElements.length === 0
      ? [
          {
            icon: (
              <div className={styles.backIcon}>
                <Svgs.BackSvg />
              </div>
            ),
            onPress: goBack,
          },
        ]
      : leftElements;

  function goBack() {
    if (isApp) {
      RNpostMessage({action: "back"});
    } else {
      if (key && history.state.idx > 0) {
        history.go(-1);
      } else {
        navigate("/");
      }
    }
  }

  if (!isHeader) return <></>;

  return (
    <>
      <div style={{backgroundColor, height: safeArea?.top || 0}}></div>
      <div className={styles.header} style={{height, backgroundColor}}>
        <div className={styles.left}>
          {leftElementList.length > 0 && (
            <div className={styles.leftContent} style={{height}}>
              <div className={styles.leftContainer}>
                {leftElementList.map((element, index) => (
                  <div key={index} className={styles.item}>
                    {index > 0 && leftDiv && (
                      <div className={styles.division} />
                    )}
                    <div onClick={element.onPress} className={styles.icon}>
                      {element.icon}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className={styles.center}>
          {titleElement ? (
            <>{titleElement}</>
          ) : title ? (
            <FontText
              numberOfLines={1}
              align={titleAlign}
              fontSize={16}
              lineHeight={150}
              color={titleColor}
              fontWeight={500}>
              {title}
            </FontText>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.right}>
          {rightElements.length > 0 && (
            <div className={styles.rightContent} style={{height}}>
              <div className={styles.rightContainer}>
                {rightElements.map((element, index) => (
                  <div key={index} className={styles.item}>
                    {index > 0 && rightDiv && (
                      <div className={styles.division} />
                    )}
                    <div onClick={element.onPress} className={styles.icon}>
                      {element.icon}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
