import {FontText} from "@components";
import React from "react";
import {COLOR} from "utils";
import styles from "./style.module.scss";

const dotText = "\u2022";

export default function DotText({
  text,
  numbering,
  circleNumber = false,
  size,
  lineHeight = 150,
  color = COLOR.gray3,
  weight = 400,
}: {
  text: string | JSX.Element;
  numbering?: string;
  circleNumber?: boolean;
  size: number;
  lineHeight?: number;
  weight?: number;
  color?: COLOR;
}) {
  const circleSize = 15;

  return (
    <div className={styles.contentView}>
      {circleNumber ? (
        <div
          className={styles.circle}
          style={{
            width: circleSize,
            height: circleSize,
            borderRadius: circleSize,
          }}>
          <span>{numbering || dotText}</span>
        </div>
      ) : (
        <div style={{justifyContent: "center"}}>
          <div
            className={styles.contentDot}
            style={{
              fontSize: size,
              lineHeight: `${lineHeight}%`,
            }}>
            {numbering || dotText}
          </div>
        </div>
      )}
      {typeof text === "string" ? (
        <div className={styles.content}>
          <FontText
            fontSize={size}
            lineHeight={lineHeight}
            fontWeight={weight}
            color={color}>
            {text}
          </FontText>
        </div>
      ) : (
        <>{text}</>
      )}
    </div>
  );
}
