import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 32,
  stroke = COLOR.gray5,
}: {
  width?: number;
  stroke?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none">
      <path
        d="M27.2769 6.12177C26.6253 5.44911 25.8517 4.9155 25.0002 4.55144C24.1487 4.18738 23.236 4 22.3143 4C21.3926 4 20.48 4.18738 19.6285 4.55144C18.777 4.9155 18.0034 5.44911 17.3518 6.12177L15.9995 7.51714L14.6472 6.12177C13.3311 4.76368 11.546 4.0007 9.68465 4.0007C7.82332 4.0007 6.03823 4.76368 4.72207 6.12177C3.40591 7.47987 2.6665 9.32185 2.6665 11.2425C2.6665 13.1631 3.40591 15.0051 4.72207 16.3632L6.07434 17.7586L15.9995 28L25.9247 17.7586L27.2769 16.3632C27.9288 15.6909 28.4459 14.8926 28.7988 14.0139C29.1516 13.1353 29.3332 12.1936 29.3332 11.2425C29.3332 10.2914 29.1516 9.34967 28.7988 8.47104C28.4459 7.59241 27.9288 6.79412 27.2769 6.12177V6.12177Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
