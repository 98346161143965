import {COLOR} from "@utils";
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

interface IProps {
  color?: string;
  size?: number;
}

export default function Loader({color = COLOR.green, size = 100}: IProps) {
  return <ClipLoader color={color} loading size={size} />;
}
