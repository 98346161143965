import React from "react";

export default function Svg({
  width = 18,
  stroke = "#4F4F4F",
}: {
  width?: number;
  stroke?: string;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 8.75758C2 8.75758 6.26633 4.02952 9 1M9 1C11.7337 4.02952 13.2663 5.72805 16 8.75758M9 1V17"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}
