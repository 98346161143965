import React from "react";
import {Header, Nav} from "@components";
import styles from "./style.module.scss";
import {getParameterState, headerState} from "recoils/atoms";
import {useRecoilValue} from "recoil";
import {ScreenId} from "@data";

interface IProps {
  children: JSX.Element;
}

export default function Layout({children}: IProps) {
  const header = useRecoilValue(headerState);
  const {isBottom} = useRecoilValue(getParameterState);
  const pathname = location.pathname;
  const isPathBottom = [ScreenId.HOME, ScreenId.POST].includes(
    pathname as ScreenId,
  );

  return (
    <div className={styles.container}>
      <Header {...header} />
      <div className={styles.content}>{children}</div>
      <Nav isVisible={isBottom && isPathBottom} />
    </div>
  );
}
