import React from "react";

export default function Svg({
  width = 20,
  stroke = "#F24147",
}: {
  width?: number;
  stroke?: string;
}) {
  return (
    <svg
      width={width}
      height={(Number(width) / 20) * 21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="9.99984"
        cy="10.5013"
        r="8.33333"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M10 5.91797V10.5013L12.5 13.0013"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
