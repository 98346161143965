import React from "react";

export default function Svg({
  width = 24,
  stroke = "#BDBDBD",
  fill,
}: {
  width?: number;
  stroke?: string;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M7 11.1225V3.75C7 3.55109 7.07902 3.36032 7.21967 3.21967C7.36032 3.07902 7.55109 3 7.75 3C7.94891 3 8.13968 3.07902 8.28033 3.21967C8.42098 3.36032 8.5 3.55109 8.5 3.75V11.1225C8.93887 10.9673 9.31881 10.6799 9.58746 10.2997C9.85611 9.9196 10.0002 9.46549 10 9V3.75C10 3.55109 10.079 3.36032 10.2197 3.21967C10.3603 3.07902 10.5511 3 10.75 3C10.9489 3 11.1397 3.07902 11.2803 3.21967C11.421 3.36032 11.5 3.55109 11.5 3.75V9C11.5002 9.86449 11.2017 10.7025 10.655 11.3722C10.1083 12.0419 9.34702 12.5021 8.5 12.675V21.25C8.5 21.4489 8.42098 21.6397 8.28033 21.7803C8.13968 21.921 7.94891 22 7.75 22C7.55109 22 7.36032 21.921 7.21967 21.7803C7.07902 21.6397 7 21.4489 7 21.25V12.675C6.15298 12.5021 5.3917 12.0419 4.84502 11.3722C4.29834 10.7025 3.99982 9.86449 4 9V3.75C4 3.55109 4.07902 3.36032 4.21967 3.21967C4.36032 3.07902 4.55109 3 4.75 3C4.94891 3 5.13968 3.07902 5.28033 3.21967C5.42098 3.36032 5.5 3.55109 5.5 3.75V9C5.49976 9.46549 5.64389 9.9196 5.91254 10.2997C6.18119 10.6799 6.56113 10.9673 7 11.1225Z"
        fill={stroke}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 15.75V21.25C18.5 21.4489 18.579 21.6397 18.7197 21.7803C18.8603 21.921 19.0511 22 19.25 22C19.4489 22 19.6397 21.921 19.7803 21.7803C19.921 21.6397 20 21.4489 20 21.25V3H18.5C16.5005 5.097 15.2495 7.098 14.75 9C14.2505 10.902 14 13.152 14 15.75H18.5ZM18.5 5.25781V14.2503H15.53C15.52 12.0054 16.1 7.06406 18.5 5.25781Z"
        fill={stroke}
      />
    </svg>
  );
}
