import * as Datas from "@data";
import {axiosPostUtil} from "./axiosUtil";

const zootopiaAPI = {
  v1: {
    product: {
      productList: async ({
        partner_no,
        except_no,
        searchText,
        page = 1,
        dawn_delivery_flag = true,
      }: {
        partner_no?: number;
        except_no?: number;
        searchText?: string;
        page?: number;
        dawn_delivery_flag?: boolean;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IHomeProduct[];
            page: Datas.IPage;
          }>
        >("/api/v1/product/productList", {
          partner_no,
          except_no,
          searchText,
          page,
          dawn_delivery_flag,
        }).then(value => value.data);
      },

      popularProductList: async (params: {
        page?: number;
        limit?: number;
        item_type_no?: number;
        item_category_no?: number;
        days?: number;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IHomeProduct[];
            page: Datas.IPage;
          }>
        >("/api/v1/product/popularProductList", params).then(
          value => value.data,
        );
      },

      getGiftItemList: async ({
        item_list,
        orderPrice,
        deliveryPrice,
      }: {
        item_list: {item_no: number; count: number}[];
        orderPrice: number;
        deliveryPrice: number;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IGiftProduct[]>>(
          "/api/v1/product/getGiftItemList",
          {
            item_list,
            orderPrice,
            deliveryPrice,
          },
        ).then(value => value.data);
      },

      getAppOpenEventTab: async () => {
        return await axiosPostUtil<
          Datas.ApiResponse<Datas.IAppEventTabResponse>
        >("/api/v1/product/getAppOpenEventTab").then(value => value.data);
      },

      sectionDetail: async ({section_no}: {section_no: number}) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.ISectionDetail>>(
          "/api/v1/product/sectionDetail",
          {
            section_no,
          },
        ).then(value => value.data);
      },

      recommandProductList: async ({
        no,
        page = 1,
        limit,
      }: {
        no: number;
        page?: number;
        limit?: number;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IHomeProduct[];
            page: Datas.IPage;
          }>
        >("/api/v1/product/recommandProductList", {
          item_no: no,
          page,
          limit,
        }).then(value => value.data);
      },

      productDetail: async ({item_no}: {item_no: string}) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IProductDetail>>(
          "/api/v1/product/productDetail",
          {
            no: item_no,
          },
        ).then(value => value.data);
      },

      productionQnaList: async ({
        item_no,
        page,
      }: {
        item_no: number;
        page: number;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IProductQna[];
            page: Datas.IPage;
          }>
        >("/api/v1/product/productionQnaList", {
          item_no,
          page,
        }).then(value => value.data);
      },

      productionQnaDelete: async ({no}: {no: number}) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/product/productionQnaDelete",
          {
            no,
          },
        ).then(value => value.data);
      },

      createProductQna: async ({
        item_no,
        title,
        content,
        hide_yn,
      }: {
        item_no: number;
        title: string;
        content: string;
        hide_yn: Datas.TypeYN;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/product/createProductQna",
          {
            item_no,
            title,
            content,
            hide_yn,
          },
        ).then(value => value.data);
      },

      memberOrderedProductList: async ({page}: {page?: number}) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IHomeProduct[];
            page: Datas.IPage;
          }>
        >("/api/v1/product/memberOrderedProductList", {page}).then(
          value => value.data,
        );
      },

      searchList: async ({
        partner_no,
        searchText,
        page = 1,
        dawn_delivery_flag = true,
      }: {
        partner_no?: number;
        searchText?: string;
        page?: number;
        dawn_delivery_flag?: boolean;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IHomeProduct[];
            page: Datas.IPage;
          }>
        >("/api/v1/product/searchList", {
          partner_no,
          searchText,
          page,
          dawn_delivery_flag,
        }).then(value => value.data);
      },

      getCategoryProductList: async ({
        page = 1,
        limit = 20,
        item_type_no,
        pet_kind_no,
        category_no,
        sort,
        orderBy,
      }: {
        page?: number;
        limit?: number;
        item_type_no?: number;
        pet_kind_no?: number;
        category_no?: number;
        sort?: Datas.TProductCategorySortValue;
        orderBy?: Datas.TOrderBy;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IHomeProduct[];
            page: Datas.IPage;
          }>
        >("/api/v1/product/getCategoryProductList", {
          page,
          limit,
          item_type_no,
          pet_kind_no,
          category_no,
          sort,
          orderBy,
        }).then(value => value.data);
      },
    },

    member: {
      getMember: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IMemberData>>(
          "/api/v1/member/getMember",
        ).then(value => value?.data);
      },
    },

    post: {
      getList: async (params: {limit?: number; page?: number}) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.ResultPagingResponse<Datas.IPostListResponse[]>;
          }>
        >("/api/v1/post/getList", params).then(value => value?.data);
      },

      getDetail: async (params: {no: number}) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{result: Datas.IPostListResponse}>
        >("/api/v1/post/getDetail", params).then(value => value?.data);
      },

      setLike: async (params: {post_no: number; isLike: boolean}) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/post/setLike",
          params,
        ).then(value => value?.data);
      },
    },

    event: {
      receiveReward: async (params: {
        frequency_no: number;
        step_no: number;
        delivery_address_no?: number;
        memo?: string;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/event/receiveReward",
          params,
        ).then(value => value.data);
      },

      applyEventItem: async ({
        event_item_application_no,
        event_item_application_item_no,
      }: {
        event_item_application_no: number;
        event_item_application_item_no: number;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/event/applyEventItem",
          {
            event_item_application_no,
            event_item_application_item_no,
          },
        ).then(value => value.data);
      },

      applyAttendance: async ({
        event_attendance_no,
      }: {
        event_attendance_no: number;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/event/applyAttendance",
          {
            event_attendance_no,
          },
        ).then(value => value.data);
      },

      getEventList: async () => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            attendance?: Datas.IEventAttendance[];
            item_application?: Datas.IEventItemApplication[];
          }>
        >("/api/v1/event/getEventList").then(value => value.data);
      },
    },

    order: {
      addSubscribeItems: async ({
        subscribe_no,
        item_list,
      }: {
        subscribe_no: number;
        item_list: {item_no: number; release_cycle: number; count: number}[];
      }) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBEventPopup[]>>(
          "/api/v1/order/addSubscribeItems",
          {subscribe_no, item_list},
        ).then(value => value.data);
      },

      createSubscribe: async ({
        item_list,
        card_list,
        delivery_address_no,
        start_date,
        auto_coupon_yn,
        auto_point_yn,
      }: {
        item_list: {item_no: number; release_cycle: number; count: number}[];
        card_list: {card_no: number; main_yn: Datas.TypeYN}[];
        delivery_address_no: number;
        start_date: string;
        auto_coupon_yn: Datas.TypeYN;
        auto_point_yn: Datas.TypeYN;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse<{subscribe_no?: number}>>(
          "/api/v1/order/createSubscribe",
          {
            item_list,
            card_list,
            delivery_address_no,
            start_date,
            auto_coupon_yn,
            auto_point_yn,
          },
        ).then(value => value.data);
      },

      getSubscribeDetail: async ({subscribe_no}: {subscribe_no: number}) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{subscribe: Datas.ISubscribe}>
        >("/api/v1/order/getSubscribeDetail", {subscribe_no}).then(
          value => value.data,
        );
      },

      payment: async ({
        itemList,
        coupon,
        point,
        card,
        itemTotalPrice,
        couponPrice,
        deliveryPrice,
        orderPrice,
        deliveryAddress,
        memo,
        del_cart = "N",
        part_cancel_yn = "N",
        delivery_type,
        payment_type,
        payment_data,
      }: Datas.PaymentListRequest) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{result: {order_no?: number; error?: string}}>
        >("/api/v1/order/payment", {
          itemList,
          coupon,
          point,
          card,
          itemTotalPrice,
          couponPrice,
          deliveryPrice,
          orderPrice,
          deliveryAddress,
          memo,
          del_cart,
          part_cancel_yn,
          delivery_type,
          payment_type,
          payment_data,
        }).then(value => value.data);
      },

      getList: async ({
        page = 1,
        subscribe_yn,
        cancel_yn,
        review_yn,
      }: {
        page?: number;
        subscribe_yn?: Datas.TypeYN;
        cancel_yn?: Datas.TypeYN;
        review_yn?: Datas.TypeYN;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IOrderRequest[];
            page: Datas.IPage;
          }>
        >("/api/v1/order/getList", {
          page,
          subscribe_yn,
          cancel_yn,
          review_yn,
        }).then(value => value.data);
      },

      getDetail: async ({order_no}: {order_no: number}) => {
        return await axiosPostUtil<
          Datas.ApiResponse<Datas.IOrderDetailRequest>
        >("/api/v1/order/getDetail", {
          order_no,
        }).then(value => value.data);
      },

      getOrderItemList: async () => {
        return await axiosPostUtil<
          Datas.ApiResponse<Datas.IMemberOutMenuList[]>
        >("/api/v1/order/getOrderItemList").then(value => value.data);
      },

      cancelPayment: async ({
        no,
        cancelReasonNo,
        cancelReasonText,
      }: {
        no: number;
        cancelReasonNo?: number;
        cancelReasonText?: string;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/order/cancelPayment",
          {
            no,
            cancelReasonNo,
            cancelReasonText,
          },
        ).then(value => value.data);
      },

      getSubscribePreviewList: async ({
        delivery_address_no,
      }: {
        delivery_address_no: number;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: {
              subscribe?: Datas.ISubscribe;
              previewList?: Datas.ISubscribePreview[];
            };
          }>
        >("/api/v1/order/getSubscribePreviewList", {
          delivery_address_no,
        }).then(value => value.data);
      },

      getSubscribePreviewDetail: async ({
        subscribe_no,
        date,
      }: {
        subscribe_no: number;
        date?: Date;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: {
              subscribe?: Datas.ISubscribe;
              preview?: Datas.ISubscribePreview;
              is_temp_add?: boolean;
            };
          }>
        >("/api/v1/order/getSubscribePreviewDetail", {
          subscribe_no,
          date,
        }).then(value => value.data);
      },

      getSubscribeFailList: async ({
        page,
        subscribe_no,
      }: {
        page: number;
        subscribe_no: number;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.ISubscribeFail[];
            page: Datas.IPage;
          }>
        >("/api/v1/order/getSubscribeFailList", {
          page,
          subscribe_no,
        }).then(value => value.data);
      },

      getSubscribeCancelList: async ({page}: {page: number}) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.ISubscribe[];
            page: Datas.IPage;
          }>
        >("/api/v1/order/getSubscribeCancelList", {
          page,
        }).then(value => value.data);
      },

      getSubscribeTempCountChange: async ({
        item_no,
        count,
        subscribe_no,
      }: {
        item_no: number;
        count: number;
        subscribe_no: number;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/order/getSubscribeTempCountChange",
          {
            item_no,
            count,
            subscribe_no,
          },
        ).then(value => value.data);
      },

      getAddSubscribeTemp: async ({
        item_no,
        count,
        subscribe_no,
      }: {
        item_no: number;
        count: number;
        subscribe_no: number;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: {
              delivery_date?: Datas.IDBDeliveryDate;
              near_delivery_date?: Datas.IDBDeliveryDate;
              stock_date?: Datas.IDBStockDate;
            };
          }>
        >("/api/v1/order/getAddSubscribeTemp", {
          item_no,
          count,
          subscribe_no,
        }).then(value => value.data);
      },

      getAddSubscribeTempList: async ({
        subscribe_no,
        list,
      }: {
        subscribe_no: number;
        list: {
          item_no: number;
          count: number;
        }[];
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: {
              delivery_date?: Datas.IDBDeliveryDate;
              near_delivery_date?: Datas.IDBDeliveryDate;
              stock_date?: Datas.IDBStockDate;
            };
          }>
        >("/api/v1/order/getAddSubscribeTempList", {
          list,
          subscribe_no,
        }).then(value => value.data);
      },

      getSubscribeFailDetail: async ({
        subscribe_no,
        subscribe_fail_no,
      }: {
        subscribe_no: number;
        subscribe_fail_no: number;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            subscribe: Datas.ISubscribe;
            failDetail: Datas.ISubscribeFail;
          }>
        >("/api/v1/order/getSubscribeFailDetail", {
          subscribe_no,
          subscribe_fail_no,
        }).then(value => value.data);
      },
    },

    cart: {
      addCartItemsEachOne: async ({
        list,
      }: {
        list: {item_no: number; count: number; subscribe_yn: Datas.TypeYN}[];
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/cart/addCartItemsEachOne",
          {list},
        ).then(value => value.data);
      },

      createCart: async ({
        item_no,
        count,
        subscribe_yn = "N",
        release_cycle = 0,
      }: {
        item_no: number;
        count: number;
        subscribe_yn?: Datas.TypeYN;
        release_cycle?: number;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse<{result: Datas.IDBCart}>>(
          "/api/v1/cart/createCart",
          {
            item_no,
            count,
            subscribe_yn,
            release_cycle,
          },
        ).then(value => value.data);
      },

      getCartList: async ({
        subscribe_yn = "N",
      }: {
        subscribe_yn: Datas.TypeYN;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IItemCart[]>>(
          "/api/v1/cart/getCartList",
          {
            subscribe_yn,
          },
        ).then(value => value.data);
      },

      deleteCart: async ({
        list,
      }: {
        list: {
          item_no: number;
          subscribe_yn: Datas.TypeYN;
        }[];
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/cart/deleteCart",
          {list},
        ).then(value => value.data);
      },

      changeCartCount: async ({
        item_no,
        count,
        subscribe_yn = "N",
      }: {
        item_no: number;
        count: number;
        subscribe_yn: Datas.TypeYN;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/cart/changeCartCount",
          {
            item_no,
            count,
            subscribe_yn,
          },
        ).then(value => value.data);
      },

      reCreateCart: async ({order_no}: {order_no: number}) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/cart/reCreateCart",
          {
            order_no,
          },
        ).then(value => value.data);
      },
    },

    alarm: {
      getAlarmList: async ({page = 1}: {page?: number | undefined}) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IAlarm[]>>(
          "/api/v1/alarm/getAlarmList",
          {
            page,
          },
        ).then(value => value.data);
      },

      setReadAlarm: async ({
        alarm_no,
        push_click = false,
        marketing_no,
      }: {
        alarm_no?: number;
        push_click?: boolean;
        marketing_no?: number;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            coupon?: Datas.IHomeCoupon;
            push_data?: Datas.IDBMarketingLog;
          }>
        >("/api/v1/alarm/setReadAlarm", {
          no: alarm_no,
          push_click_yn: push_click ? "Y" : "N",
          marketing_no,
        }).then(value => value.data);
      },

      clickMarketingPush: async ({no}: {no: number}) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/alarm/clickMarketingPush",
          {
            no,
          },
        ).then(value => value.data);
      },
    },

    review: {
      productionReviewList: async ({
        no,
        page = 1,
        best_yn,
        photo_yn,
        order = "createdAt",
        orderBy = "DESC",
      }: {
        no: number;
        page: number;
        best_yn?: "N" | "Y";
        photo_yn?: "N" | "Y";
        order?: "createdAt" | "payment_cnt";
        orderBy?: "ASC" | "DESC";
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            options: Datas.IProductOptions[];
            result: Datas.IProductReview[];
            page: Datas.IPage;
          }>
        >("/api/v1/review/productionReviewList", {
          no,
          page,
          best_yn,
          photo_yn,
          order,
          orderBy,
        }).then(value => value.data);
      },

      productionReviewPhotoList: async ({
        no,
        page = 1,
        limit = 10,
      }: {
        no: number;
        page: number;
        limit?: number;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IReviewPhoto[];
            page: Datas.IPage;
          }>
        >("/api/v1/review/productionReviewPhotoList", {no, page, limit}).then(
          value => value.data,
        );
      },

      productionReviewDetail: async ({no}: {no: number}) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IProductReviewDetail;
            total_cnt: number;
            now: number;
            prev_num: number;
            next_num: number;
          }>
        >("/api/v1/review/productionReviewDetail", {no}).then(
          value => value.data,
        );
      },

      createReview: async ({
        item_no,
        order_no,
        score,
        content,
        photos,
        selectOptions,
      }: {
        item_no: number;
        order_no: number;
        score: number;
        content: string;
        photos?: Datas.IUploadImage[];
        selectOptions?: Datas.ISelectReviewOptions[];
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/review/createReview",
          {
            item_no,
            order_no,
            score,
            content,
            photos,
            selectOptions,
          },
        ).then(value => value.data);
      },

      updateReview: async ({
        no,
        item_no,
        order_no,
        score,
        content,
        photos,
        selectOptions,
      }: {
        no: number;
        item_no: number;
        order_no: number;
        score: number;
        content: string;
        photos?: Datas.IUploadImage[];
        selectOptions?: Datas.ISelectReviewOptions[];
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/review/updateReview",
          {
            no,
            item_no,
            order_no,
            score,
            content,
            photos,
            selectOptions,
          },
        ).then(value => value.data);
      },

      reviewOptionList: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IReviewOptions[]>>(
          "/api/v1/review/reviewOptionList",
        ).then(value => value.data);
      },

      memberReviewList: async ({
        page = 1,
        startDate,
      }: {
        page?: number;
        startDate?: string;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            result: Datas.IMyReview[];
            page: Datas.IPage;
          }>
        >("/api/v1/review/memberReviewList", {
          page,
          startDate,
        }).then(value => value.data);
      },
    },

    pet: {
      getKindAndType: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IPetKindAndType[]>>(
          "/api/v1/pet/getKindAndType",
        ).then(value => value.data);
      },

      getPetEatingHabits: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBEatingHabits[]>>(
          "/api/v1/pet/getPetEatingHabits",
        ).then(value => value.data);
      },

      getPetDiseaseList: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDiseaseAllList[]>>(
          "/api/v1/pet/getPetDiseaseList",
        ).then(value => value.data);
      },

      getPetAllergyList: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBAllergyList[]>>(
          "/api/v1/pet/getPetAllergyList",
        ).then(value => value.data);
      },

      addPetType: async ({
        kind_no,
        type_name,
      }: {
        kind_no: number;
        type_name: string;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBPetType>>(
          "/api/v1/pet/addPetType",
          {
            kind_no,
            type_name,
          },
        ).then(value => value.data);
      },

      savePetInfo: async ({
        petInfo,
        member_name,
        gift_apply_yn,
      }: {
        petInfo: Datas.IPetInfo;
        member_name: string;
        gift_apply_yn?: Datas.TypeYN;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBPetType>>(
          "/api/v1/pet/savePetInfo",
          {
            ...petInfo,
            disease_list: (petInfo.diseaseList || [])
              .map(item => item.disease_no)
              .filter(item => !!item),
            allergy_list: (petInfo.allergyList || [])
              .map(item => item.allergy_no)
              .filter(item => !!item),
            eating_habits_no: petInfo.eating_habits_no,
            member_name,
            gift_apply_yn,
          },
        ).then(value => value.data);
      },

      getPetInfo: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IPetInfo[]>>(
          "/api/v1/pet/getPetInfo",
        ).then(value => value.data);
      },

      addPetDisease: async ({text_list}: {text_list: string[]}) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBDiseaseList[]>>(
          "/api/v1/pet/addPetDisease",
          {
            text_list,
          },
        ).then(value => value.data);
      },

      addPetAllergy: async ({text_list}: {text_list: string[]}) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBAllergyList[]>>(
          "/api/v1/pet/addPetAllergy",
          {
            text_list,
          },
        ).then(value => value.data);
      },
    },

    payment: {
      registCard: async (cardData: Datas.ICardRequest) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/payment/registCard",
          cardData,
        ).then(value => value.data);
      },

      removeCard: async ({
        no,
        builling_bid,
      }: {
        no?: number;
        builling_bid?: string;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/payment/removeCard",
          {
            no,
            builling_bid,
          },
        ).then(value => value.data);
      },
    },

    dawnshipping: {
      getDeliveryType: async (info: Datas.DeliveryAddressResponse) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{result: Datas.TDeliveryType}>
        >("/api/v1/dawnshipping/getDeliveryType", info).then(
          value => value.data,
        );
      },
    },

    banner: {
      bannerList: async (params: {show_category_yn?: Datas.TypeYN} = {}) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBBanner[]>>(
          "/api/v1/banner/bannerList",
          params,
        ).then(value => value.data);
      },

      bannerDetail: async (no: number) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IHomeBanner>>(
          "/api/v1/banner/bannerDetail",
          {no},
        ).then(value => value.data);
      },
    },

    utils: {
      getCommonInfo: async () => {
        return await axiosPostUtil<
          Datas.ApiResponse<{
            etc?: Datas.IDBEtc;
            bgm_list?: Datas.IDBBgmList[];
            item_type_list?: Datas.IItemType[];
            pet_kind_list?: Datas.IDBPetKind[];
            delivery_date?: Datas.IDBDeliveryDate[];
            home_tab_list?: Datas.IDBHomeTabList[];
            event_popup?: Datas.IDBEventPopup[];
            grade_list?: Datas.IGrade[];
            block_delivery_date_list?: Datas.IBlockDeliveryDate;
            frequency?: Datas.IFrequency;
            badge_list?: Datas.IDBBadgeList[];
          }>
        >("/api/v1/utils/getCommonInfo").then(value => value.data);
      },

      sendSms: async (phoneNumber: string) => {
        return await axiosPostUtil<Datas.ApiResponse<{code: string}>>(
          "/api/v1/utils/sendSms",
          {
            phoneNumber,
          },
        ).then(value => value.data);
      },
    },

    ocr: {
      // paymentCard: async (imgUri: string) => {
      //   try {
      //     const form = new FormData();
      //     form.append("file", {
      //       name: `ocrDocument${moment().format("YYYYMMDDHHMMSS")}`,
      //       type: "image/png",
      //       uri: imgUri,
      //     });
      //     return await axiosApi
      //       .post<Datas.ApiResponse<Datas.IOCRResponse>>(
      //         "/api/v1/ocr/paymentCard",
      //         form,
      //         {
      //           headers: {
      //             "content-type": "multipart/form-data",
      //           },
      //           transformRequest: data => {
      //             return data;
      //           },
      //         },
      //       )
      //       .then(value => value.data);
      //   } catch (e) {
      //     console.error(e);
      //     throw e;
      //   }
      // },
    },

    faq: {
      faqList: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IFaqList[]>>(
          "/api/v1/faq/faqList",
          {},
        ).then(value => value.data);
      },

      faqGroupList: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBFaqGroup[]>>(
          "/api/v1/faq/faqGroupList",
          {},
        ).then(value => value.data);
      },

      createMemberQna: async (
        qnaData:
          | {
              group?:
                | {
                    no?: number | undefined;
                    title?: string | undefined;
                  }
                | undefined;
              phone_number?: string | undefined;
              content?: string | undefined;
            }
          | undefined,
      ) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/faq/createMemberQna",
          qnaData,
        ).then(value => value.data);
      },

      memberQnaList: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IMemberQnaList[]>>(
          "/api/v1/faq/memberQnaList",
          {},
        ).then(value => value.data);
      },
    },

    partner: {
      getPartnerList: async ({
        searchText,
        page = 1,
      }: {
        searchText?: string;
        page?: number;
      }) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBPartner[]>>(
          "/api/v1/partner/getPartnerList",
          {
            searchText,
            page,
          },
        ).then(value => value.data);
      },

      getPartner: async ({no}: {no: number}) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBPartner>>(
          "/api/v1/partner/getPartner",
          {
            no,
          },
        ).then(value => value.data);
      },
    },

    notice: {
      noticeList: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBNotice[]>>(
          "/api/v1/notice/noticeList",
        ).then(value => value.data);
      },

      noticeDetail: async ({no}: {no: number}) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IDBNotice>>(
          "/api/v1/notice/noticeDetail",
          {
            no,
          },
        ).then(value => value.data);
      },
    },

    likeHistory: {
      toggleLikeHistory: async ({
        del,
        type,
        target_no,
      }: {
        del: Datas.TypeYN;
        type: Datas.TLikeHistoryType;
        target_no: number;
      }) => {
        return await axiosPostUtil<
          Datas.ApiResponse<{result?: Datas.IDBLikeHistory}>
        >("/api/v1/likeHistory/toggleLikeHistory", {
          del,
          type,
          target_no,
        }).then(value => value.data);
      },
    },

    image: {
      uploadImage: async ({
        path,
        filename,
        filepath,
      }: {
        path: string;
        filename: string;
        filepath: string;
      }) => {
        // const result = await RNFS.uploadFiles({
        //   toUrl: `${BASE_URL}/api/v1/image/uploadImage`,
        //   files: [
        //     {
        //       filename,
        //       filepath,
        //       filetype: "image/png",
        //       name: "files",
        //     },
        //   ],
        //   method: "POST",
        //   headers: {
        //     Accept: "application/json",
        //     path,
        //   },
        // }).promise;
        // return JSON.parse(result.body) as {
        //   success: boolean;
        //   code: number;
        //   data: {
        //     img: string;
        //   };
        // };
      },
    },

    declaration: {
      createDeclaration: async ({
        type,
        target_no,
        contents,
      }: {
        type: Datas.TDeclarationType;
        target_no: number;
        contents: string[];
      }) => {
        return await axiosPostUtil<Datas.ApiResponse>(
          "/api/v1/declaration/createDeclaration",
          {
            type,
            target_no,
            contents,
          },
        ).then(value => value.data);
      },
    },
  },
  v2: {
    product: {
      getRecentViewItemList: async () => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IHomeProduct[]>>(
          "/api/v2/product/getRecentViewItemList",
        ).then(value => value.data);
      },

      sectionList: async ({home_tab_no}: {home_tab_no?: number}) => {
        return await axiosPostUtil<Datas.ApiResponse<Datas.IHomeSection[]>>(
          "/api/v2/product/sectionList",
          {
            home_tab_no,
          },
        ).then(value => value.data);
      },
    },
  },
};

export default zootopiaAPI;
