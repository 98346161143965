import {
  DAWN_BLOCK_END_TIME,
  DAWN_BLOCK_START_TIME,
  DAWN_DELIVEY_HOUR,
  DAWN_LIMIT_HOUR,
  DAY_BLOCK_END_TIME,
  DAY_BLOCK_START_TIME,
  DAY_LIMIT_HOUR,
  ORDER_BLOCK_END_TIME,
  ORDER_BLOCK_START_TIME,
  ORDER_LIMIT_HOUR,
} from "@assets";
import {
  IBlockDeliveryDate,
  IDBDeliveryAddress,
  IDBProduct,
  TDeliveryType,
} from "@data";
import moment from "moment";
import {dateFormatMoment, dateWeekdayFormat, fullDateFormat} from "./dateUtils";

/** 배송 며칠걸리는지 */
export function calcDeliveryDate({
  orderDate,
  delivery_type,
  common_delivery__order_limit_datetime,
  plus_delivery__order_limit_datetime,
  next_stock_date,
  delivery_block_date,
}: {
  orderDate?: moment.Moment;
  delivery_type: TDeliveryType;
  common_delivery__order_limit_datetime?: Date;
  plus_delivery__order_limit_datetime?: Date;
  next_stock_date?: Date;
  delivery_block_date: IBlockDeliveryDate;
}) {
  const blockDeliveryDateList: string[] = [];
  let deliveryDate = moment(orderDate);
  const commonDeliveryOrderLimitDatetime = common_delivery__order_limit_datetime
    ? moment(common_delivery__order_limit_datetime)
    : undefined;
  const plusDeliveryOrderLimitDatetime = plus_delivery__order_limit_datetime
    ? moment(plus_delivery__order_limit_datetime)
    : undefined;
  const nextStockDate = next_stock_date ? moment(next_stock_date) : undefined;

  if (delivery_type === "일반") {
    if (
      !!commonDeliveryOrderLimitDatetime &&
      moment().diff(commonDeliveryOrderLimitDatetime) > 0 &&
      nextStockDate
    ) {
      deliveryDate = nextStockDate;
    }
  } else if (delivery_type === "도서산간") {
    if (
      !!plusDeliveryOrderLimitDatetime &&
      moment().diff(plusDeliveryOrderLimitDatetime) > 0 &&
      nextStockDate
    ) {
      deliveryDate = nextStockDate;
    }
  }

  if (delivery_type === "새벽") {
    /** 배송불가기간 */
    (delivery_block_date?.dawn || []).map(block_date =>
      blockDeliveryDateList.push(block_date),
    );

    if (deliveryDate.hour() < DAWN_LIMIT_HOUR) {
      deliveryDate.add(1, "days").set("hour", DAWN_DELIVEY_HOUR);
    } else {
      deliveryDate.add(2, "days").set("hour", DAWN_DELIVEY_HOUR);
    }
  } else if (delivery_type === "당일") {
    // FIXME
    if (deliveryDate.hour() < DAY_LIMIT_HOUR) {
      deliveryDate.add(1, "days").set("hour", 23);
    } else {
      deliveryDate.add(2, "days").set("hour", 23);
    }
  } else if (delivery_type === "일반") {
    /** 배송불가기간 */
    (delivery_block_date?.common || []).map(block_date =>
      blockDeliveryDateList.push(block_date),
    );

    if (deliveryDate.hour() < ORDER_LIMIT_HOUR) {
      deliveryDate.add(1, "days").set("hour", 23);
    } else {
      deliveryDate.add(2, "days").set("hour", 23);
    }
  } else if (delivery_type === "도서산간") {
    /** 배송불가기간 */
    (delivery_block_date?.plus || []).map(block_date =>
      blockDeliveryDateList.push(block_date),
    );

    if (deliveryDate.hour() < ORDER_LIMIT_HOUR) {
      deliveryDate.add(2, "days").set("hour", 23);
    } else {
      deliveryDate.add(3, "days").set("hour", 23);
    }
  }

  // 배송불가일 체크
  blockDeliveryDateList.sort().forEach(_date => {
    if (deliveryDate.format("YYYY-MM-DD") === _date) {
      deliveryDate.add(1, "days");
    }
  });

  // 일요일 배송 X
  if (deliveryDate.day() % 7 === 0) {
    deliveryDate.add(1, "days");
  }
  return deliveryDate.set("minute", 0).set("second", 0);
}

/** 배송며칠 걸리는지 상품상세페이지에서 사용 */
export function calcDeliveryDateText({
  delivery_type,
  stockDate,
  common_delivery__order_limit_datetime,
  plus_delivery__order_limit_datetime,
  next_stock_date,
  delivery_block_date,
}: {
  delivery_type: TDeliveryType;
  stockDate?: moment.Moment;
  common_delivery__order_limit_datetime?: Date;
  plus_delivery__order_limit_datetime?: Date;
  next_stock_date?: Date;
  delivery_block_date: IBlockDeliveryDate;
}) {
  const orderDate = calcDeliveryDate({
    delivery_type,
    orderDate: stockDate,
    common_delivery__order_limit_datetime,
    plus_delivery__order_limit_datetime,
    next_stock_date,
    delivery_block_date,
  });
  const diff = orderDate.startOf("days").diff(moment().startOf("days"), "days");
  const diffDay = diff === 1 ? "내일" : `${diff}일`;
  if (delivery_type === "새벽") {
    if (diff === 1) {
      return `${diffDay} 아침 ${DAWN_DELIVEY_HOUR}시 전`;
    } else {
      return `${dateFormatMoment(orderDate)} 아침 ${DAWN_DELIVEY_HOUR}시 전`;
    }
  } else if (delivery_type === "당일") {
    // FIXME
    if (diff === 1) {
      return `${diffDay} 이내`;
    } else {
      return `${dateFormatMoment(orderDate)} 이내`;
    }
  } else if (delivery_type === "일반") {
    if (diff === 1) {
      return `${diffDay} 이내`;
    } else {
      return `${dateFormatMoment(orderDate)} 이내`;
    }
  } else if (delivery_type === "도서산간") {
    if (diff >= 3) {
      return `${diffDay} 이내`;
    } else {
      return `${dateFormatMoment(orderDate)} 이내`;
    }
  }
  return `${diffDay} 이내`;
}

/** 배송며칠 걸리는지 장바구니/주문페이지에서 사용 */
export function calcDeliveryDateText2({
  delivery_type,
  stockDate,
  common_delivery__order_limit_datetime,
  plus_delivery__order_limit_datetime,
  next_stock_date,
  delivery_block_date,
}: {
  delivery_type: TDeliveryType;
  stockDate?: moment.Moment;
  common_delivery__order_limit_datetime?: Date;
  plus_delivery__order_limit_datetime?: Date;
  next_stock_date?: Date;
  delivery_block_date: IBlockDeliveryDate;
}) {
  const orderDate = calcDeliveryDate({
    delivery_type,
    orderDate: stockDate,
    common_delivery__order_limit_datetime,
    plus_delivery__order_limit_datetime,
    next_stock_date,
    delivery_block_date,
  });
  if (delivery_type === "새벽") {
    return `${fullDateFormat(orderDate.toDate())} 전`;
  } else if (delivery_type === "당일") {
    // FIXME
    return `${dateWeekdayFormat(orderDate.toDate())}`;
  } else if (delivery_type === "일반") {
    return `${dateWeekdayFormat(orderDate.toDate())}`;
  } else if (delivery_type === "도서산간") {
    return `${dateWeekdayFormat(orderDate.toDate())}`;
  }
  return `${dateWeekdayFormat(orderDate.toDate())}`;
}

export function calcDeliveryDateTextFormatting2({
  delivery_type,
  delivery_date,
}: {
  delivery_type: TDeliveryType;
  delivery_date: moment.Moment;
}) {
  const deliveryDate = delivery_date;
  if (delivery_type === "새벽") {
    return `${fullDateFormat(deliveryDate.toDate())} 전`;
  } else if (delivery_type === "당일") {
    // FIXME
    return `${dateWeekdayFormat(deliveryDate.toDate())}`;
  } else if (delivery_type === "일반") {
    return `${dateWeekdayFormat(deliveryDate.toDate())}`;
  } else if (delivery_type === "도서산간") {
    return `${dateWeekdayFormat(deliveryDate.toDate())}`;
  }
  return `${dateWeekdayFormat(deliveryDate.toDate())}`;
}

export function getCalcOrderPrice({
  delivery_type,
  plus_price = 0,
  item_total_price,
  couponPrice = 0,
  pointPrice = 0,
  isIncludeFrozen = false,
}: {
  delivery_type: TDeliveryType;
  plus_price?: number;
  item_total_price: number;
  couponPrice?: number;
  pointPrice?: number;
  isIncludeFresh?: boolean;
  isIncludeFrozen?: boolean;
}) {
  // const PRICE_STEP = [50000, 40000, 20000];
  const FREE_DELIVERY_PRICE = 40000;
  const response = {
    delivery_price: 0,
    total_price: 0,
    order_price: 0,
    additional_delivery_fee: 0,
    need_for_delivery_fee: 0,
    free_delivery_price: FREE_DELIVERY_PRICE,
  };

  const pay_price = item_total_price - pointPrice - couponPrice;

  /*
  let deliveryPrice = (() => {
    if (pay_price >= PRICE_STEP[0]) {
      return 0;
    } else if (pay_price < PRICE_STEP[0] && pay_price >= PRICE_STEP[1]) {
      return 1500;
    } else if (pay_price < PRICE_STEP[1] && pay_price >= PRICE_STEP[2]) {
      return 4500;
    }
    return 5500;
  })();
  */
  let deliveryPrice = (() => {
    switch (delivery_type) {
      case "새벽":
        if (pay_price >= FREE_DELIVERY_PRICE) {
          return 0;
        }
        return 4000;
      case "일반":
        if (pay_price >= FREE_DELIVERY_PRICE) {
          return 0;
        }
        return 3500;
      case "도서산간":
        return 3500;
    }
    return 4000;
  })();

  const ADDITIONAL_DELIVERY_FEE = (() => {
    // 하계배송비 구하기
    const FRESH_DELIVERY_FEE = 500;
    let return_additional_delivery_fee = 0;

    /*
    if (!isIncludeFrozen) {
      // 냉장만 구매할 때
      if (pay_price < 80000) {
        return_additional_delivery_fee += FRESH_DELIVERY_FEE;
      }
    } else {
      if (pay_price < 40000) {
        return_additional_delivery_fee += 1500;
      } else if (pay_price < 80000) {
        return_additional_delivery_fee += 1000;
      }
    }
    */
    if (pay_price < 40000) {
      return_additional_delivery_fee += 1500;
    } else if (pay_price < 80000) {
      return_additional_delivery_fee += 1000;
    }
    return return_additional_delivery_fee;
  })();

  if (isIncludeFrozen) {
    deliveryPrice += ADDITIONAL_DELIVERY_FEE;
    response.additional_delivery_fee = ADDITIONAL_DELIVERY_FEE;
  }

  deliveryPrice += plus_price;

  const totalPrice = item_total_price + deliveryPrice;
  const orderPrice = totalPrice - pointPrice - couponPrice;

  response.delivery_price = deliveryPrice;
  response.total_price = totalPrice;
  response.order_price = orderPrice;
  response.need_for_delivery_fee = FREE_DELIVERY_PRICE - item_total_price;

  return response;
}

export function isBlockTime({delivery_type}: {delivery_type: TDeliveryType}) {
  const now = moment().format("HHmm");
  if (delivery_type === "새벽") {
    if (now >= DAWN_BLOCK_START_TIME && now <= DAWN_BLOCK_END_TIME) {
      return true;
    }
  } else if (delivery_type === "당일") {
    if (now >= DAY_BLOCK_START_TIME && now <= DAY_BLOCK_END_TIME) {
      return true;
    }
  } else if (delivery_type === "일반") {
    if (now >= ORDER_BLOCK_START_TIME && now <= ORDER_BLOCK_END_TIME) {
      return true;
    }
  } else if (delivery_type === "도서산간") {
    if (now >= ORDER_BLOCK_START_TIME && now <= ORDER_BLOCK_END_TIME) {
      return true;
    }
  }
  return false;
}

export function convertDeliveryType({
  deliveryAddress,
}: {
  deliveryAddress?: IDBDeliveryAddress;
}): TDeliveryType {
  if (deliveryAddress?.dawn_delivery_yn === "Y") {
    return "새벽";
  }
  if (deliveryAddress?.day_delivery_yn === "Y") {
    return "당일";
  }
  if (deliveryAddress?.plus_delivery_yn === "Y") {
    return "도서산간";
  }
  return "일반";
}

export function formatNickname(nickname?: string) {
  const nick = nickname || "";
  return nick.slice(0, 2) + "*".repeat((nick.length || 2) - 2);
}

export function getProductPrice({
  product,
  isSubscribe = false,
}: {
  product?: IDBProduct;
  isSubscribe?: boolean;
}): number {
  if (!!product?.promotion_price && !isNaN(Number(product?.promotion_price))) {
    return product?.promotion_price;
  }
  if (isSubscribe) {
    if (
      !!product?.subscribe_price &&
      !isNaN(Number(product?.subscribe_price))
    ) {
      return product?.subscribe_price;
    }
  }
  return product?.price || 0;
}
