import React from "react";
import styles from "./style.module.scss";
import {useRecoilState, useRecoilValue} from "recoil";
import {useNavigate} from "react-router-dom";
import {getParameterState, navigationState} from "recoils/atoms";
import {TNavigation} from "data/common";
import {Svgs} from "@assets";
import {COLOR} from "@utils";
import {APP_NAVIGATE, ScreenId} from "@data";
import {FontText} from "@components";
import {useDownload, useRNPost} from "@hook";

interface INavigation {
  nav: TNavigation;
  img: JSX.Element;
  activeImg: JSX.Element;
  text: string;
  navUrl?: ScreenId;
  appNavUrl?: APP_NAVIGATE;
}

export default function Nav({isVisible = true}: {isVisible: boolean}) {
  const [activeNav, setActiveNav] = useRecoilState(navigationState);
  const navigate = useNavigate();
  const {isApp, safeArea} = useRecoilValue(getParameterState);
  const {openDownloadAppModal} = useDownload();
  const {RNpostMessage} = useRNPost();

  const navData: INavigation[] = [
    {
      nav: "home",
      img: <Svgs.HomeIcon width={19} />,
      activeImg: (
        <Svgs.HomeIcon width={19} fill={COLOR.green} stroke={COLOR.green} />
      ),
      text: "홈",
      navUrl: ScreenId.HOME,
    },
    {
      nav: "moment",
      img: <Svgs.MomentIcon width={19} />,
      activeImg: <Svgs.MomentIcon width={19} stroke={COLOR.green} />,
      text: "한상차림",
      navUrl: ScreenId.POST,
    },
    {
      nav: "category",
      img: <Svgs.MenuIconSvg width={19} fill={COLOR.gray5} />,
      activeImg: <Svgs.MenuIconSvg width={19} fill={COLOR.green} />,
      text: "카테고리",
      appNavUrl: APP_NAVIGATE.CATEGORY_LIST,
    },
    {
      nav: "order",
      img: <Svgs.OrderIcon width={19} />,
      activeImg: <Svgs.OrderIcon width={19} stroke={COLOR.green} />,
      text: "주문내역",
      appNavUrl: APP_NAVIGATE.ORDER_LIST,
    },
    {
      nav: "mypage",
      img: <Svgs.MypageIcon width={19} />,
      activeImg: <Svgs.MypageIcon width={19} stroke={COLOR.green} />,
      text: "마이페이지",
      appNavUrl: APP_NAVIGATE.MY_PAGE,
    },
  ];

  return (
    <div className={[styles.container, !isVisible && styles.hide].join(" ")}>
      <div className={styles.wrapper}>
        <div className={styles.nav}>
          {navData.map(({nav, img, activeImg, text, navUrl, appNavUrl}) => {
            const isActive = nav === activeNav;

            function onClick() {
              if (navUrl) {
                setActiveNav(nav);
                navigate(navUrl);
              } else if (isApp && appNavUrl) {
                RNpostMessage({action: "navigate", screenId: appNavUrl});
              } else {
                openDownloadAppModal();
              }
            }

            return (
              <div className={styles.navItem} key={text} onClick={onClick}>
                <div className={styles.imgContainer}>
                  {isActive ? activeImg : img}
                </div>
                <FontText
                  fontSize={10}
                  color={isActive ? COLOR.green : COLOR.gray4}>
                  {text}
                </FontText>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={styles.safeAreaBottom}
        style={{height: safeArea?.bottom || 0}}
      />
    </div>
  );
}
