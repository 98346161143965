import {Svgs} from "@assets";
import {DotText, FontText, Img} from "@components";
import {IProductDetailRecipe, TProductItemDetail} from "@data";
import {useHackleTrack} from "@hook";
import {COLOR} from "@utils";
import React, {useEffect, useState} from "react";
import styles from "../style.module.scss";
import Slider from "react-slick";

export function RecipeItem({
  item_no,
  data = [],
}: {
  item_no: number;
  data: TProductItemDetail[];
}) {
  const {hackleTrack} = useHackleTrack();
  const [isSwipeSwipe, setIsSwipeSwipe] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const recipe = (data.filter(item => item?.type === "recipe")[0] ||
    {}) as IProductDetailRecipe;
  const recipeLength = (recipe?.data_json?.data || []).length;

  useEffect(() => {
    if (currentPage > 1 && !isSwipeSwipe) {
      setIsSwipeSwipe(true);

      hackleTrack({
        key: "swipe__recipe",
        properties: {
          product_no: String(item_no),
        },
      });
    }
    if (currentPage === recipeLength - 1) {
      hackleTrack({
        key: "swipe__recipe_last_page",
        properties: {
          product_no: String(item_no),
        },
      });
    }
  }, [currentPage]);

  if (recipeLength === 0) {
    return <></>;
  }

  return (
    <div className={styles.recipeItem}>
      <div className={styles.recipeTitleContainer}>
        <div className={styles.svg}>
          <Svgs.RecipeSvg width={24} fill={COLOR.green} />
        </div>
        <FontText fontSize={20} lineHeight={150} fontWeight={700}>
          이렇게 만들었어요
        </FontText>
      </div>
      <div className={styles.recipeContentContainer}>
        <div className={styles.imgContainer}>
          <Slider
            swipeToSlide={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            dots={true}
            dotsClass={styles.sliderDots}
            focusOnSelect={false}
            appendDots={(dots: any) => (
              <div>
                <ul>{dots}</ul>
              </div>
            )}>
            {(recipe?.data_json?.data || []).map((_data, i) => {
              const img_url = _data.thumbnail_img_url || _data.img_url;
              return (
                <div key={i} className={styles.recipeSlideItem}>
                  {!!img_url && <Img src={img_url} />}
                  <div className={styles.content}>
                    {!!_data.content && (
                      <DotText
                        text={_data.content}
                        size={18}
                        color={COLOR.gray3}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
