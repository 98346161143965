import {FontText, Img} from "@components";
import {
  IProductDetailImgDetail,
  IProductDetailInterview,
  TProductItemDetail,
} from "@data";
import {COLOR} from "@utils";
import React from "react";
import styles from "../style.module.scss";

export function ImgDetailItem({
  data,
  recipeItem,
}: {
  data: TProductItemDetail[];
  recipeItem?: JSX.Element;
}) {
  const imgDetail = (data.filter(_data => _data.type === "imgDetail")[0] ||
    {}) as IProductDetailImgDetail;
  const interview = (data.filter(_data => _data.type === "interview")[0] ||
    {}) as IProductDetailInterview;

  return (
    <div className={styles.imgDetailItem}>
      {imgDetail?.data_json?.data?.map((_data, i) => {
        const img_url = _data.thumbnail_img_url || _data.img_url;
        return (
          <div className={styles.item} key={i}>
            {!!img_url && <Img src={img_url} />}
            <div className={styles.contentContainer}>
              <div className={styles.title}>
                <FontText
                  fontSize={20}
                  lineHeight={150}
                  fontWeight={700}
                  align="center">
                  {_data.title}
                </FontText>
              </div>
              <div>
                <FontText
                  fontSize={16}
                  lineHeight={150}
                  color={COLOR.gray3}
                  align="center">
                  {_data.content}
                </FontText>
              </div>
            </div>
            {i === 0 && !!recipeItem && (
              <div className={styles.recipeContainer}>{recipeItem}</div>
            )}
          </div>
        );
      })}
      {!!interview && !!interview.data_json && (
        <div className={styles.interviewItem}>
          {!!interview.data_json?.img_url && (
            <Img src={interview.data_json?.img_url} />
          )}
          <div className={styles.item}>
            <div className={styles.title}>
              <FontText
                fontSize={20}
                lineHeight={150}
                fontWeight={700}
                align="center">
                {interview.data_json?.title}
              </FontText>
            </div>
            <FontText
              fontSize={16}
              lineHeight={150}
              color={COLOR.gray3}
              align="center">
              {interview.data_json?.content}
            </FontText>
          </div>
        </div>
      )}
    </div>
  );
}
