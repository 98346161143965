import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 20,
  fill = COLOR.gray1,
}: {
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none">
      <mask id="path-1-inside-1_3967_23310" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2018 2.5C16.0486 2.5 19.167 5.61843 19.167 9.46522C19.167 13.312 16.0486 16.4304 12.2018 16.4304H7.79887C6.82433 16.4304 5.89653 16.2303 5.05434 15.8689L2.815 17.1787C2.04616 17.6284 1.11496 16.9146 1.34345 16.0507L2.04625 13.3934C1.28116 12.2752 0.833658 10.9224 0.833658 9.46522C0.833658 5.61843 3.95209 2.5 7.79887 2.5H12.2018Z"
        />
      </mask>
      <path
        d="M5.05434 15.8689L4.297 14.5741L4.95034 14.1919L5.6459 14.4904L5.05434 15.8689ZM2.815 17.1787L2.05766 15.8839V15.8839L2.815 17.1787ZM1.34345 16.0507L2.79359 16.4342L2.79359 16.4342L1.34345 16.0507ZM2.04625 13.3934L3.28423 12.5464L3.67195 13.1131L3.49639 13.777L2.04625 13.3934ZM17.667 9.46522C17.667 6.44686 15.2201 4 12.2018 4V1C16.877 1 20.667 4.79001 20.667 9.46522H17.667ZM12.2018 14.9304C15.2201 14.9304 17.667 12.4836 17.667 9.46522H20.667C20.667 14.1404 16.877 17.9304 12.2018 17.9304V14.9304ZM7.79887 14.9304H12.2018V17.9304H7.79887V14.9304ZM5.6459 14.4904C6.30463 14.7731 7.03144 14.9304 7.79887 14.9304V17.9304C6.61722 17.9304 5.48843 17.6874 4.46279 17.2473L5.6459 14.4904ZM2.05766 15.8839L4.297 14.5741L5.81169 17.1636L3.57235 18.4735L2.05766 15.8839ZM2.79359 16.4342C2.90193 16.0246 2.46175 15.6476 2.05766 15.8839L3.57235 18.4735C1.63056 19.6093 -0.672003 17.8046 -0.106684 15.6672L2.79359 16.4342ZM3.49639 13.777L2.79359 16.4342L-0.106686 15.6672L0.596107 13.0099L3.49639 13.777ZM2.33366 9.46522C2.33366 10.6107 2.6844 11.6697 3.28423 12.5464L0.808264 14.2404C-0.122072 12.8806 -0.666342 11.2342 -0.666342 9.46522H2.33366ZM7.79887 4C4.78052 4 2.33366 6.44686 2.33366 9.46522H-0.666342C-0.666342 4.79001 3.12366 1 7.79887 1V4ZM12.2018 4H7.79887V1H12.2018V4Z"
        fill={fill}
        mask="url(#path-1-inside-1_3967_23310)"
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(-1 0 0 1 15 8.58398)"
        fill={fill}
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(-1 0 0 1 11 8.58398)"
        fill={fill}
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(-1 0 0 1 7 8.58398)"
        fill={fill}
      />
    </svg>
  );
}
