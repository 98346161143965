import React from "react";

export default function Svg({
  width = 16,
  fill = "#BDBDBD",
}: {
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={(Number(width) / 16) * 15}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.403 6.32935C10.2117 7.16202 9.63833 7.52868 9.21899 7.79735C8.78499 8.07468 8.63499 8.19068 8.63499 8.58335C8.63499 8.74248 8.57178 8.89509 8.45926 9.00761C8.34673 9.12013 8.19412 9.18335 8.03499 9.18335C7.87586 9.18335 7.72325 9.12013 7.61073 9.00761C7.49821 8.89509 7.43499 8.74248 7.43499 8.58335C7.43499 7.51335 8.09232 7.09335 8.57232 6.78602C8.95366 6.54268 9.15566 6.39935 9.23366 6.06068C9.30099 5.76668 9.24766 5.50668 9.07366 5.28802C8.84566 5.00202 8.43833 4.81668 8.03499 4.81668C7.38299 4.81668 6.85233 5.34802 6.85233 6.00002C6.85233 6.15915 6.78911 6.31176 6.67659 6.42428C6.56407 6.5368 6.41146 6.60002 6.25233 6.60002C6.0932 6.60002 5.94058 6.5368 5.82806 6.42428C5.71554 6.31176 5.65233 6.15915 5.65233 6.00002C5.65303 5.36831 5.90429 4.76268 6.35097 4.316C6.79766 3.86931 7.40329 3.61805 8.03499 3.61735C8.80099 3.61735 9.55832 3.97068 10.0123 4.54068C10.4137 5.04468 10.5523 5.68002 10.403 6.32935M8.04566 11.4887C7.83349 11.4887 7.63 11.4044 7.47997 11.2544C7.32994 11.1043 7.24566 10.9009 7.24566 10.6887C7.24566 10.4765 7.32994 10.273 7.47997 10.123C7.63 9.97297 7.83349 9.88868 8.04566 9.88868C8.25783 9.88868 8.46131 9.97297 8.61134 10.123C8.76137 10.273 8.84566 10.4765 8.84566 10.6887C8.84566 10.9009 8.76137 11.1043 8.61134 11.2544C8.46131 11.4044 8.25783 11.4887 8.04566 11.4887ZM8.00033 0.166016C3.94966 0.166016 0.666992 3.44935 0.666992 7.49935C0.666992 11.55 3.94966 14.8327 8.00033 14.8327C12.0503 14.8327 15.3337 11.55 15.3337 7.49935C15.3337 3.44935 12.0503 0.166016 8.00033 0.166016Z"
        fill={fill}
      />
    </svg>
  );
}
