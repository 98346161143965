import {Svgs} from "@assets";
import {FontText} from "@components";
import {IProductDetailNotice, TProductItemDetail} from "@data";
import {COLOR} from "@utils";
import React from "react";
import styles from "../style.module.scss";

export function NoticeItem({data}: {data?: TProductItemDetail[]}) {
  const list = ((data || []).filter(_data => _data?.type === "notice") ||
    []) as IProductDetailNotice[];
  const item = list?.[0];

  if (!item) {
    return <></>;
  }

  return (
    <div className={styles.noticeItem}>
      <div className={styles.titleContainer}>
        <div className={styles.svg}>
          <Svgs.NoticeSvg />
        </div>
        <FontText fontSize={16} lineHeight={150} fontWeight={600}>
          {item.data_json?.title || "상품 공지"}
        </FontText>
      </div>
      <div className={styles.contentContainer}>
        {(item.data_json?.data || []).map((_text, index) => (
          <div key={index} className={styles.item}>
            <FontText lineHeight={150} color={COLOR.gray3}>
              {_text}
            </FontText>
          </div>
        ))}
      </div>
    </div>
  );
}
