import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 16,
  fill = COLOR.red400,
}: {
  width?: number;
  stroke?: string;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8Z"
        fill={fill}
      />
      <path d="M7.33333 4.33333H8.66667V9.66667H7.33333V4.33333Z" fill={fill} />
      <path d="M7.33333 10.3333H8.66667V11.6667H7.33333V10.3333Z" fill={fill} />
    </svg>
  );
}
