export const ZOOTOPIA_WEB_URL = "https://web.zootopia.shop";
export const ZOOTOPIA_LINK_URL = "https://link.zootopia.shop";
export const ZOOTOPIA_APP_LINK = "https://deeplink.zootopia.shop";
export const ZOOTOPIA_DOWNLOAD_LINK = "https://download.zootopia.shop";
export const DEFAULT_PROFILE_IMG_URL =
  "https://zootopia-event.s3.ap-northeast-2.amazonaws.com/profile/zoopiProfile.jpg";

export const DAWN_LIMIT_HOUR = 18; // 새벽배송 주문마감시간
export const DAY_LIMIT_HOUR = 18; // 당일배송 주문마감시간
export const ORDER_LIMIT_HOUR = 17; // 일반배송 주문마감시간
export const DAWN_DELIVEY_HOUR = 7; // 새벽배송 도착시간
export const DAWN_BLOCK_START_TIME = "1750"; // 새벽 주문불가시작시간
export const DAWN_BLOCK_END_TIME = "1800"; // 새벽 주문불가종료시간
export const DAY_BLOCK_START_TIME = "1750"; // 당일 주문불가시작시간
export const DAY_BLOCK_END_TIME = "1800"; // 당일 주문불가종료시간
export const ORDER_BLOCK_START_TIME = "1650"; // 주문불가시작시간
export const ORDER_BLOCK_END_TIME = "1700"; // 주문불가종료시간

export const REVIEW_LIMIT_DAY = 7;
export const REVIEW_EDIT_LIMIT_DAY = 7;

export const MILK_ITEM_NO_LIST = [3, 84];
export const ZOOPI_ONLY_BADGE_NO = 11;

export const DMB_TYPE_TITLE: {
  [key: number]: {title: string; short_title: string; isHalf: boolean};
} = {
  1: {title: "DM(Dry Matter)", short_title: "DM 성분", isHalf: true},
  2: {title: "DM(Dry Matter)", short_title: "DM 성분", isHalf: true},
  3: {title: "DM(Dry Matter)", short_title: "DM 성분", isHalf: true},
  4: {title: "DM(Dry Matter)", short_title: "DM 성분", isHalf: true},
  5: {title: "성분 분석 정보", short_title: "성분 구성", isHalf: false},
};
