import {ScreenId} from "@data";
import _ from "lodash";
import react, {useEffect} from "react";
import {atom, useRecoilState} from "recoil";

interface IPageScroll {
  [key: string]: number;
}
export interface IPageScrollItem {
  screenId: ScreenId;
  divId?: string;
  scroll?: number;
}
const pageScrollState = atom<IPageScroll>({
  key: "pageScrollKey",
  default: {},
});

export default function usePageScroll() {
  const [pageScroll, setPageScroll] = useRecoilState(pageScrollState);

  function getPageScroll({
    screenId,
    divId,
  }: {
    screenId: ScreenId;
    divId?: string;
  }) {
    const key = getKey({screenId, divId});
    return pageScroll[key] || 0;
  }

  function settingPageScroll({screenId, divId, scroll = 0}: IPageScrollItem) {
    const key = getKey({screenId, divId});
    setPageScroll({...pageScroll, [key]: scroll});
  }

  function settingPageScrollList(list: IPageScrollItem[]) {
    const newObj: IPageScroll = {};
    list.forEach(item => {
      const newKey = getKey({screenId: item.screenId, divId: item.divId});
      newObj[newKey] = item.scroll || 0;
    });
    setPageScroll({...pageScroll, ...newObj});
  }

  function scrollToPrevious({
    ref,
    screenId,
    divId,
    isHorizontal = false,
  }: {
    ref: React.RefObject<HTMLDivElement>;
    screenId: ScreenId;
    divId?: string;
    isHorizontal?: boolean;
  }) {
    const key = getKey({screenId, divId});
    const previousPage = pageScroll[key] || 0;
    if (previousPage > 0 && ref.current) {
      ref.current.style.scrollBehavior = "initial";
      ref.current?.scrollTo(
        isHorizontal ? {left: previousPage} : {top: previousPage},
      );
      ref.current.style.scrollBehavior = "smooth";
    }
  }

  function getKey({screenId, divId}: {screenId: ScreenId; divId?: string}) {
    const key = divId ? `${screenId}${divId}` : screenId;
    return key;
  }

  return {
    getPageScroll,
    setPageScroll: _.throttle(settingPageScroll, 100),
    setPageScrollList: _.throttle(settingPageScrollList, 100),
    scrollToPrevious,
  };
}
