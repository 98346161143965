import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 20,
  fill = COLOR.green,
}: {
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41667 3C2.08579 3 1 4.08579 1 5.41667V13.5833C1 14.5498 1.7835 15.3333 2.75 15.3333H3.18436C3.51469 16.5317 4.61598 17.4167 5.91659 17.4167C7.21719 17.4167 8.31848 16.5317 8.64881 15.3333H11.9344C12.2647 16.5317 13.366 17.4167 14.6666 17.4167C15.9672 17.4167 17.0685 16.5317 17.3988 15.3333H18.25C19.2165 15.3333 20 14.5498 20 13.5833V9.91667C20 9.53802 19.8772 9.16958 19.65 8.86667L17.65 6.2C17.3195 5.75934 16.8008 5.5 16.25 5.5H15V4.75C15 3.7835 14.2165 3 13.25 3H3.41667ZM17.3988 13.8333H18.25C18.3881 13.8333 18.5 13.7214 18.5 13.5833V10.3333H14.25H13.5V9.58333V7V6.25V4.75C13.5 4.61193 13.3881 4.5 13.25 4.5H3.41667C2.91421 4.5 2.5 4.91421 2.5 5.41667V13.5833C2.5 13.7214 2.61193 13.8333 2.75 13.8333H3.18436C3.51469 12.635 4.61598 11.75 5.91659 11.75C7.21719 11.75 8.31848 12.635 8.64881 13.8333H11.9344C12.2647 12.635 13.366 11.75 14.6666 11.75C15.9672 11.75 17.0685 12.635 17.3988 13.8333ZM17.75 8.83333L16.45 7.1C16.4028 7.03705 16.3287 7 16.25 7H15V8.83333H17.75ZM5.91659 13.25C5.18497 13.25 4.58325 13.8517 4.58325 14.5833C4.58325 15.315 5.18497 15.9167 5.91659 15.9167C6.64821 15.9167 7.24992 15.315 7.24992 14.5833C7.24992 13.8517 6.64821 13.25 5.91659 13.25ZM13.3333 14.5833C13.3333 13.8517 13.935 13.25 14.6666 13.25C15.3982 13.25 15.9999 13.8517 15.9999 14.5833C15.9999 15.315 15.3982 15.9167 14.6666 15.9167C13.935 15.9167 13.3333 15.315 13.3333 14.5833Z"
        fill={fill}
      />
    </svg>
  );
}
