import {Svgs} from "@assets";
import {
  APP_NAVIGATE,
  IDefaultProduct,
  IToast,
  TDeliveryType,
  TPrevPage,
} from "@data";
import {useDownload, useMember, useRNPost} from "@hook";
import React, {useState} from "react";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import {toast, ToastContainer} from "react-toastify";
import styles from "./style.module.scss";
import {COLOR, getCalcOrderPrice, getProductPrice} from "@utils";
import {FontText, Modal} from "@components";

interface IProps {
  visible: boolean;
  item: IDefaultProduct;
  close?: () => void;
  rating?: string;
  pre_page_name: TPrevPage;
  nowDeliveryType: TDeliveryType;
  plusDeliveryFee?: number;
}

export function ProductBuySubscribeModal({
  visible,
  item,
  close,
  nowDeliveryType,
  plusDeliveryFee,
}: IProps) {
  const {isApp, safeArea} = useRecoilValue(getParameterState);
  const {openDownloadAppModal} = useDownload();
  const {RNpostMessage} = useRNPost();
  const {user} = useMember();
  const [count, setCount] = useState(1);
  const [releaseCycle, setReleaseCycle] = useState(2);

  const isIncludeFrozen = item?.item_fresh === "N";
  const isIncludeFresh = item?.item_fresh === "Y";

  const {
    delivery_price: nowDeliveryFee,
    free_delivery_price: FREE_DELIVERY_PRICE,
  } = getCalcOrderPrice({
    item_total_price: 0,
    delivery_type: nowDeliveryType,
    plus_price: plusDeliveryFee,
    isIncludeFrozen,
    isIncludeFresh,
  });

  const productSubscribePrice = getProductPrice({
    product: item,
    isSubscribe: true,
  });
  const productPrice = getProductPrice({product: item});
  const unit_price = productSubscribePrice;

  const discountPrice = productPrice - (unit_price || 0);

  function checkUserInfo({callback}: {callback?: () => void}) {
    if (user) {
      callback?.();
    } else {
      gotoNavigate({screenId: APP_NAVIGATE.LOGIN});
    }
  }

  function gotoNavigate({
    screenId,
    data,
  }: {
    screenId: APP_NAVIGATE;
    data?: {[key: string]: string | number | undefined};
  }) {
    if (isApp) {
      RNpostMessage({action: "navigate", screenId, data});
    } else {
      openDownloadAppModal();
    }
  }

  function pressPlus() {
    const limitCount = Math.min(
      item.item_order_limit || 0,
      (item.stock || 0) > 0
        ? item.stock || 0
        : item.stock_date?.stock_count || 0,
    );
    if (count >= limitCount) {
      return setToast({
        svg: <Svgs.WarningSvg />,
        text: `${limitCount}개 까지만 추가할 수 있어요!`,
        bottomButton: true,
      });
    }
    setCount(count + 1);
  }

  async function createCartSubscribe() {
    if (isApp) {
      RNpostMessage({
        action: "insert_cart_subscribe",
        data: {count},
        product: item,
      });
      close?.();
    } else {
      openDownloadAppModal();
    }
  }

  function setToast(data: IToast) {
    try {
      if (isApp) {
        const {svg, ...toastParam} = data;
        const toastData = {
          data: {
            ...(toastParam || {}),
            svgIcon: data.svg === <Svgs.CheckSvg /> ? "check" : "warning",
          },
        };
        RNpostMessage({action: "toast", ...toastData});
      } else {
        toast(data.text, {toastId: "toast"});
      }
    } catch (error) {
      console.error("setToast error", error);
    }
  }

  return (
    <>
      <Modal
        visible={visible}
        close={close}
        className={styles.productBuySubscribeModal}
        position={{bottom: 0}}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <div className={styles.topContainer}>
              <FontText
                fontSize={16}
                lineHeight={150}
                fontWeight={600}
                color={COLOR.black}>
                {item.item_name}
              </FontText>
              <div className={styles.priceRow}>
                <div className={styles.price}>
                  <FontText
                    fontSize={18}
                    lineHeight={150}
                    fontWeight={700}
                    color={COLOR.green}>
                    {productPrice.toLocaleString()}
                  </FontText>
                  <FontText
                    fontSize={18}
                    lineHeight={150}
                    fontWeight={700}
                    color={COLOR.black}>
                    원
                  </FontText>
                </div>
                <div className={styles.btnContainer}>
                  <div
                    className={styles.countChangeBtn}
                    onClick={() => count > 1 && setCount(count - 1)}>
                    <Svgs.MinusIconSvg />
                  </div>
                  <div
                    className={[styles.countChangeBtn, styles.count].join(" ")}>
                    <FontText>{count}</FontText>
                  </div>
                  <div className={styles.countChangeBtn} onClick={pressPlus}>
                    <Svgs.PlusIconSvg />
                  </div>
                </div>
              </div>
              <div>
                {(item.stock || 0) <= 5 && (item.stock || 0) > 2 && (
                  <div className={styles.stockAlert}>
                    <Svgs.WarningSvg fill={COLOR.stockYellow} width={12} />
                    <FontText
                      fontSize={12}
                      lineHeight={150}
                      fontWeight={500}
                      color={COLOR.stockYellow}>
                      {item.stock}개 남았어요!
                    </FontText>
                  </div>
                )}
                {(item.stock || 0) <= 2 && (item.stock || 0) > 0 && (
                  <div className={styles.stockAlert}>
                    <Svgs.WarningSvg fill={COLOR.red500} width={12} />
                    <FontText
                      fontSize={12}
                      lineHeight={150}
                      fontWeight={500}
                      color={COLOR.red500}>
                      품절 임박! 얼른 구매해주세요.
                    </FontText>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.line} />
            <div className={styles.releaseCycleContainer}>
              <div className={styles.title}>
                <FontText lineHeight={150} fontWeight={600}>
                  배송주기
                </FontText>
              </div>
              <div className={styles.content}>
                <div className={styles.selectList}>
                  {[1, 2].map(index => {
                    const isActive = index === releaseCycle;
                    return (
                      <div
                        key={index}
                        className={[
                          styles.item,
                          isActive && styles.active,
                        ].join(" ")}
                        onClick={() => {
                          if (index === 2) {
                            // FIXME
                            setReleaseCycle(index);
                          }
                        }}>
                        <FontText
                          lineHeight={150}
                          fontWeight={isActive ? 700 : 500}
                          color={isActive ? COLOR.green : COLOR.gray4}>
                          {index}주에 한 번
                        </FontText>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.box}>
                  <Svgs.WarningSvg width={20} fill={COLOR.redDD1616} />
                  <div className={styles.text}>
                    <FontText
                      lineHeight={150}
                      fontWeight={700}
                      color={COLOR.redDD1616}
                      className={styles.textTitle}>
                      2주에 한 번 정기 배송되는 상품입니다.
                    </FontText>
                    <FontText lineHeight={150} color={COLOR.gray3}>
                      다른 제품과 정기 구매 시 출고일에 맞춰 2주에 한 번 정기
                      배송됩니다.{"\n"}상품을 신선하게 준비해 보내 드리겠습니다.
                    </FontText>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.rowItem}>
              <div className={styles.title}>
                <FontText lineHeight={150} fontWeight={600}>
                  배송유형
                </FontText>
              </div>
              <div className={styles.content}>
                <Svgs.DeliveryIcon width={20} />
                <FontText fontWeight={700} color={COLOR.green}>
                  {nowDeliveryType === "새벽"
                    ? "주피배송"
                    : nowDeliveryType === "도서산간"
                    ? "도서산간지역 배송"
                    : "일반배송"}
                </FontText>
              </div>
            </div>
            <div className={styles.deliveryFeeContainer}>
              <div className={styles.title}>
                <FontText lineHeight={150} fontWeight={600}>
                  배송비
                </FontText>
              </div>
              <div className={styles.content}>
                <div className={styles.deliveryFee}>
                  <FontText lineHeight={150} fontWeight={600}>
                    {nowDeliveryFee.toLocaleString()}원 회차별 결제
                  </FontText>
                </div>
                {nowDeliveryType !== "도서산간" && (
                  <>
                    <FontText
                      fontSize={12}
                      lineHeight={150}
                      fontWeight={500}
                      color={COLOR.grayC5C5C5}>
                      {FREE_DELIVERY_PRICE.toLocaleString()}원 이상 구매 시 무료
                    </FontText>
                    <FontText
                      fontSize={12}
                      lineHeight={150}
                      fontWeight={500}
                      color={COLOR.grayC5C5C5}>
                      {nowDeliveryType === "새벽" &&
                        "*제주 외 도서산간지역 배송 시 추가비용 발생"}
                      {nowDeliveryType === "일반" &&
                        `*제주 외 도서산간지역 추가 ${(1000).toLocaleString()}원 이상 추가`}
                    </FontText>
                  </>
                )}
              </div>
            </div>
            <div className={styles.bottomContainer}>
              <div
                className={styles.btn}
                onClick={() => checkUserInfo({callback: createCartSubscribe})}>
                <FontText fontSize={16} color={COLOR.white} fontWeight={700}>
                  정기배송 장바구니
                </FontText>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        theme="light"
        containerId="copyToast"
        toastClassName={styles.toast}
        style={{marginBottom: `${safeArea?.bottom || 0}px`}}
      />
    </>
  );
}
