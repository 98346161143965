import {Svgs} from "@assets";
import {FontText, Img} from "@components";
import {IProductDetailCheckpoint, TProductItemDetail} from "@data";
import {COLOR} from "@utils";
import React from "react";
import styles from "../style.module.scss";

export function CheckPointNewItem({data}: {data: TProductItemDetail[]}) {
  const checkPoint = (data.filter(item => item?.type === "checkpointNew")[0] ||
    {}) as IProductDetailCheckpoint;

  const checkPointData = (checkPoint?.data_json?.data || []).filter(
    item => item.title || item.content,
  );
  const checkPointLength = (checkPointData || []).length;

  if (!checkPoint && checkPointData.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.checkPointNew}>
      <div className={styles.titleContainer}>
        <div className={styles.svg}>
          <Svgs.ZoopiIcon width={25} />
        </div>
        <div>
          <FontText
            fontSize={16}
            lineHeight={150}
            color={COLOR.green}>{`주피's pick`}</FontText>
        </div>
      </div>
      {!!checkPoint?.data_json?.sub_title && (
        <div>
          <FontText
            fontSize={20}
            lineHeight={150}
            fontWeight={700}
            color={COLOR.green}
            align="center">
            {checkPoint?.data_json?.sub_title}
          </FontText>
        </div>
      )}
      <div className={styles.contentContainer}>
        {(checkPointData || []).map((_data, i) => {
          const img_url = _data.thumbnail_img_url || _data.img_url;
          return (
            <div key={i} className={styles.item}>
              <div>{!!img_url && <Img src={img_url} />}</div>
              <div className={styles.content}>
                <FontText lineHeight={150}>{_data.content}</FontText>
                {!!_data.bold_content && (
                  <FontText lineHeight={150} fontWeight={700}>
                    {_data.bold_content}
                  </FontText>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
