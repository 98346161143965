import {
  IAppEventTabResponse,
  IBlockDeliveryDate,
  IDBBgmList,
  IDBDeliveryDate,
  IDBEtc,
  IDBEventPopup,
  IDBGrade,
  IDBHomeTabList,
  IDBPetKind,
  IFrequency,
  IItemType,
  cookieKeys,
  IDBBadgeList,
} from "@data";
import {api, deleteCookie, getCookie, setCookie, isDefined} from "@utils";
import {atom, useRecoilState, useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import useRNPost from "./useRNPost";

const etcState = atom<IDBEtc | undefined>({
  key: "etc",
  default: undefined,
});

const bgmListState = atom<IDBBgmList[]>({
  key: "bgmList",
  default: [],
});

const itemTypeListState = atom<IItemType[]>({
  key: "itemTypeList",
  default: [],
});

const homeItemTypeListState = atom<IItemType[]>({
  key: "homeItemTypeList",
  default: [],
});

const petKindListState = atom<IDBPetKind[]>({
  key: "petKindList",
  default: [],
});

const checkpointTooltipState = atom<string>({
  key: "checkpointTooltip",
  default: "",
});

const materialTooltipState = atom<string>({
  key: "materialTooltip",
  default: "",
});

const subscribeTooltipState = atom<string>({
  key: "subscribeTooltip",
  default: "",
});

const noneSubscribeTooltipState = atom<string>({
  key: "noneSubscribeTooltip",
  default: "",
});

const homeTabListState = atom<IDBHomeTabList[]>({
  key: "homeTabListStateKey",
  default: [],
});

const appEventTabState = atom<IAppEventTabResponse | undefined>({
  key: "appEventTabStateKey",
  default: undefined,
});

const subscribeDeliveryDateListState = atom<IDBDeliveryDate[] | undefined>({
  key: "subscribeDeliveryDateListStateKey",
  default: undefined,
});

const eventPopupState = atom<IDBEventPopup[] | undefined>({
  key: "eventPopupStateKey",
  default: undefined,
});

const bottomReviewTooltipState = atom<string>({
  key: "bottomReviewTooltip",
  default: "",
});

const bottomNoneReviewTooltipState = atom<string>({
  key: "bottomNoneReviewTooltip",
  default: "",
});

const gradeListState = atom<IDBGrade[]>({
  key: "gradeList",
  default: [],
});

const blockDateState = atom<IBlockDeliveryDate>({
  key: "blockDate",
  default: {},
});

const frequencyState = atom<IFrequency>({
  key: "frequencyDefault",
  default: {},
});

const frequencyCoachMarkHomeState = atom<string>({
  key: "frequencyCoachMarkHome",
  default: "",
});

const frequencyCoachMarkState = atom<string>({
  key: "frequencyCoachMark",
  default: "",
});

const frequencyCompleteState = atom<boolean>({
  key: "frequencyComplete",
  default: false,
});

const frequencyCompleteBannerState = atom<boolean>({
  key: "frequencyCompleteBanner",
  default: false,
});
const isproductGuaranteeHideState = atom<boolean>({
  key: "isproductGuaranteeHide",
  default: false,
});
const firstAccessDetailState = atom<string>({
  key: "firstAccessDetailState",
  default: "",
});
const badgeListState = atom<IDBBadgeList[]>({
  key: "badgeListtKey",
  default: [],
});

const CHECK_TOOLTIP_VALUE = "1";

export default function useCommon() {
  const {isApp} = useRecoilValue(getParameterState);
  const {RNpostMessage} = useRNPost();
  const [etc, setEtc] = useRecoilState(etcState);
  const [bgmList, setBgmList] = useRecoilState(bgmListState);
  const [itemTypeList, setItemTypeList] = useRecoilState(itemTypeListState);
  const [homeItemTypeList, setHomeItemTypeList] = useRecoilState(
    homeItemTypeListState,
  );
  const [petKindList, setPetKindList] = useRecoilState(petKindListState);
  const [checkpointTooltip, setCheckpointTooltip] = useRecoilState(
    checkpointTooltipState,
  );
  const [homeTabList, setHomeTabList] = useRecoilState(homeTabListState);
  const [materialTooltip, setMaterialTooltip] =
    useRecoilState(materialTooltipState);
  const [subscribeTooltip, setSubscribeTooltip] = useRecoilState(
    subscribeTooltipState,
  );
  const [noneSubscribeTooltip, setNoneSubscribeTooltip] = useRecoilState(
    noneSubscribeTooltipState,
  );
  const [appEventTabData, setAppEventTabData] =
    useRecoilState(appEventTabState);
  const [subscribeDeliveryDateList, setSubscribeDeliveryDateList] =
    useRecoilState(subscribeDeliveryDateListState);
  const [bottomReviewTooltip, setBottomReviewTooltip] = useRecoilState(
    bottomReviewTooltipState,
  );
  const [bottomNoneReviewTooltip, setBottomNoneReviewTooltip] = useRecoilState(
    bottomNoneReviewTooltipState,
  );
  const [eventPopupList, setEventPopupList] = useRecoilState(eventPopupState);
  const [gradeList, setGradeList] = useRecoilState(gradeListState);
  const [deliveryBlockDate, setBlockDate] = useRecoilState(blockDateState);
  const [frequency, setFrequency] = useRecoilState(frequencyState);
  const [frequencyCoachMarkHome, setFrequencyCoachMarkHome] = useRecoilState(
    frequencyCoachMarkHomeState,
  );
  const [frequencyCoachMark, setFrequencyCoachMark] = useRecoilState(
    frequencyCoachMarkState,
  );
  const [frequencyComplete, setFrequencyComplete] = useRecoilState(
    frequencyCompleteState,
  );
  const [frequencyCompleteBanner, setFrequencyCompleteBanner] = useRecoilState(
    frequencyCompleteBannerState,
  );
  const [isproductGuaranteeHide, setIsproductGuaranteeHide] = useRecoilState(
    isproductGuaranteeHideState,
  );
  const [firstAccessDetail, setFirstAccessDetail] = useRecoilState(
    firstAccessDetailState,
  );
  const [badgeList, setBadgeList] = useRecoilState(badgeListState);

  async function getCommon() {
    try {
      const {data, success} = await api.v1.utils.getCommonInfo();
      if (success) {
        setEtc(data.etc);
        setBgmList(data.bgm_list || []);
        setItemTypeList(data.item_type_list || []);
        setPetKindList(data.pet_kind_list || []);
        setHomeTabList(data.home_tab_list || []);
        setSubscribeDeliveryDateList(data.delivery_date || []);
        setEventPopupList(data.event_popup || []);
        setGradeList(data.grade_list || []);
        setBlockDate(data.block_delivery_date_list || {});
        setFrequency(data.frequency || {});
        setBadgeList(data.badge_list || []);
        setHomeItemTypeList(
          (data.item_type_list || [])
            .flatMap(item => item.categorys)
            .filter(isDefined)
            .filter(item => !!item?.home_ordering)
            .sort((a, b) => (a?.home_ordering || 0) - (b?.home_ordering || 0)),
        );
      }
    } catch (error) {
      console.log("getCommon error", error);
    }
  }

  async function getAppEventTabData() {
    try {
      const {data, success} = await api.v1.product.getAppOpenEventTab();
      if (success) {
        setAppEventTabData(data);
      }
    } catch (error) {
      console.log("getAppEventTabData error", error);
    }
  }

  async function getTooltipState() {
    try {
      const _checkpointTooltip =
        getCookie({name: cookieKeys.checkpointTooltipKey}) || "";
      const _materialTooltip =
        getCookie({name: cookieKeys.materialTooltipKey}) || "";
      const _subscribeTooltip =
        getCookie({name: cookieKeys.subscribeTooltipKey}) || "";
      const _noneSubscribeTooltip =
        getCookie({name: cookieKeys.noneSubscribeTooltipKey}) || "";
      const _bottomReviewTooltip =
        getCookie({name: cookieKeys.bottomReviewTooltipKey}) || "";
      const _frequencyCoachMarkHome =
        getCookie({name: cookieKeys.isFrequencyCoachMarkHomeKey}) || "";
      const _frequencyCoachMark =
        getCookie({name: cookieKeys.isFrequencyCoachMarkKey}) || "";
      const _isproductGuaranteeHide =
        getCookie({name: cookieKeys.isproductGuaranteeHideKey}) || "";
      const _isFirstAccessDetail =
        getCookie({name: cookieKeys.firstAccessDetailKey}) || "";
      setCheckpointTooltip(_checkpointTooltip);
      setMaterialTooltip(_materialTooltip);
      setSubscribeTooltip(_subscribeTooltip);
      setNoneSubscribeTooltip(_noneSubscribeTooltip);
      setBottomReviewTooltip(_bottomReviewTooltip);
      setFrequencyCoachMarkHome(_frequencyCoachMarkHome);
      setFrequencyCoachMark(_frequencyCoachMark);
      setIsproductGuaranteeHide(
        _isproductGuaranteeHide === CHECK_TOOLTIP_VALUE,
      );
      setFirstAccessDetail(_isFirstAccessDetail);
    } catch (error) {
      console.log("getTooltipState error", error);
    }
  }

  async function closeCheckpointTooltip() {
    try {
      if (isApp) {
        RNpostMessage({action: "closeCheckpointTooltip"});
      }
      setCookie({
        name: cookieKeys.checkpointTooltipKey,
        value: CHECK_TOOLTIP_VALUE,
      });
      setCheckpointTooltip(CHECK_TOOLTIP_VALUE);
    } catch (error) {
      console.log("closeCheckpointTooltip error", error);
    }
  }

  async function closeMaterialTooltip() {
    try {
      if (isApp) {
        RNpostMessage({action: "closeMaterialTooltip"});
      }
      setCookie({
        name: cookieKeys.materialTooltipKey,
        value: CHECK_TOOLTIP_VALUE,
      });
      setMaterialTooltip(CHECK_TOOLTIP_VALUE);
    } catch (error) {
      console.log("closeMaterialTooltip error", error);
    }
  }

  async function closeSubscribeTooltip() {
    try {
      if (isApp) {
        RNpostMessage({action: "closeSubscribeTooltip"});
      }
      setCookie({
        name: cookieKeys.subscribeTooltipKey,
        value: CHECK_TOOLTIP_VALUE,
      });
      setSubscribeTooltip(CHECK_TOOLTIP_VALUE);
    } catch (error) {
      console.log("closeSubscribeTooltip error", error);
    }
  }

  async function closeNoneSubscribeTooltip() {
    try {
      if (isApp) {
        RNpostMessage({action: "closeNoneSubscribeTooltip"});
      }
      setCookie({
        name: cookieKeys.noneSubscribeTooltipKey,
        value: CHECK_TOOLTIP_VALUE,
      });
      setNoneSubscribeTooltip(CHECK_TOOLTIP_VALUE);
    } catch (error) {
      console.log("closeNoneSubscribeTooltip error", error);
    }
  }

  async function closeBottomReviewTooltip() {
    try {
      if (isApp) {
        RNpostMessage({action: "closeBottomReviewTooltip"});
      }
      setCookie({
        name: cookieKeys.bottomReviewTooltipKey,
        value: CHECK_TOOLTIP_VALUE,
      });
      setBottomReviewTooltip(CHECK_TOOLTIP_VALUE);
    } catch (error) {
      console.log("closeBottomReviewTooltip error", error);
    }
  }

  function closeBottomNoneReviewTooltip() {
    try {
      if (isApp) {
        RNpostMessage({action: "closeBottomNoneReviewTooltip"});
      }
      setBottomNoneReviewTooltip(CHECK_TOOLTIP_VALUE);
    } catch (error) {
      console.log("closeBottomNoneReviewTooltip error", error);
    }
  }

  function closeFrequencyCoachMarkHome() {
    try {
      if (isApp) {
        RNpostMessage({action: "closeFrequencyCoachMarkHome"});
      }
      setCookie({
        name: cookieKeys.isFrequencyCoachMarkHomeKey,
        value: CHECK_TOOLTIP_VALUE,
      });
      setFrequencyCoachMarkHome(CHECK_TOOLTIP_VALUE);
    } catch (error) {
      console.log("closeFrequencyCoachMarkHome error", error);
    }
  }

  function closeFrequencyCoachMark() {
    try {
      if (isApp) {
        RNpostMessage({action: "closeFrequencyCoachMark"});
      }
      setCookie({
        name: cookieKeys.isFrequencyCoachMarkKey,
        value: CHECK_TOOLTIP_VALUE,
      });
      setFrequencyCoachMark(CHECK_TOOLTIP_VALUE);
    } catch (error) {
      console.log("closeFrequencyCoachMark error", error);
    }
  }

  function toggleIsproductGuaranteeHide({
    hide,
    isAppMessage = true,
  }: {
    hide: boolean;
    isAppMessage?: boolean;
  }) {
    try {
      if (isApp && isAppMessage) {
        RNpostMessage({
          action: !hide
            ? "openIsproductGuaranteeHide"
            : "closeIsproductGuaranteeHide",
        });
      }
      if (!hide) {
        deleteCookie({name: cookieKeys.isproductGuaranteeHideKey});
        setIsproductGuaranteeHide(false);
      } else {
        setCookie({
          name: cookieKeys.isproductGuaranteeHideKey,
          value: CHECK_TOOLTIP_VALUE,
        });
        setIsproductGuaranteeHide(true);
      }
    } catch (error) {
      console.log("toggleIsproductGuaranteeHide error", error);
    }
  }

  function closeFirstAccessDetail() {
    try {
      if (isApp) {
        RNpostMessage({action: "closeFirstAccessDetail"});
      }
      setCookie({
        name: cookieKeys.firstAccessDetailKey,
        value: CHECK_TOOLTIP_VALUE,
      });
      setFirstAccessDetail(CHECK_TOOLTIP_VALUE);
    } catch (error) {
      console.log("closeFirstAccessDetail error", error);
    }
  }

  async function getGiftItemList({
    item_list,
    orderPrice,
    deliveryPrice,
  }: {
    item_list: {item_no: number; count: number}[];
    orderPrice: number;
    deliveryPrice: number;
  }) {
    try {
      const {success, data, message} = await api.v1.product.getGiftItemList({
        item_list,
        orderPrice,
        deliveryPrice,
      });
      if (success) {
        return data;
      } else {
        throw Error(message);
      }
    } catch (error) {
      console.log("getGiftItemList error", error);
      return null;
    }
  }

  return {
    etc,
    bgmList,
    itemTypeList,
    homeItemTypeList,
    petKindList,
    homeTabList,
    subscribeDeliveryDateList,
    eventPopupList,
    badgeList,

    getCommon,
    gradeList,
    getTooltipState,
    isCheckpointTooltip: checkpointTooltip !== CHECK_TOOLTIP_VALUE,
    closeCheckpointTooltip,
    isMaterialTooltip: materialTooltip !== CHECK_TOOLTIP_VALUE,
    closeMaterialTooltip,
    isSubscribeTooltip: subscribeTooltip !== CHECK_TOOLTIP_VALUE,
    closeSubscribeTooltip,
    isNoneSubscribeTooltip: noneSubscribeTooltip !== CHECK_TOOLTIP_VALUE,
    closeNoneSubscribeTooltip,
    isBottomReviewTooltip: bottomReviewTooltip !== CHECK_TOOLTIP_VALUE,
    closeBottomReviewTooltip,
    isBottomNoneReviewTooltip: bottomNoneReviewTooltip !== CHECK_TOOLTIP_VALUE,
    closeBottomNoneReviewTooltip,
    appEventTabData,
    getAppEventTabData,
    getGiftItemList,
    deliveryBlockDate,
    frequency,
    isFrequencyCoachMarkHome: frequencyCoachMarkHome !== CHECK_TOOLTIP_VALUE,
    closeFrequencyCoachMarkHome,
    isFrequencyCoachMark: frequencyCoachMark !== CHECK_TOOLTIP_VALUE,
    closeFrequencyCoachMark,
    frequencyComplete,
    setFrequencyComplete,
    frequencyCompleteBanner,
    setFrequencyCompleteBanner,
    isproductGuaranteeHide,
    toggleIsproductGuaranteeHide,
    isFirstAccessDetail: firstAccessDetail !== CHECK_TOOLTIP_VALUE,
    closeFirstAccessDetail,
  };
}
