import {TFontAlign} from "@data";
import {COLOR, isDefined} from "@utils";
import React, {ReactNode} from "react";
import styles from "./style.module.scss";

interface IProps {
  children?: ReactNode;
  fontSize?: number;
  lineHeight?: number;
  fontWeight?: number;
  color?: COLOR;
  className?: string | string[];
  align?: TFontAlign;
  numberOfLines?: number;
}

export default function FontText({
  children,
  fontSize = 14,
  lineHeight = 130,
  fontWeight = 400,
  color = COLOR.black,
  className,
  align = "left",
  numberOfLines,
}: IProps) {
  const classList = [
    styles.img,
    ...(Array.isArray(className) ? className : [className]),
  ].filter(isDefined);

  return (
    <div
      className={[
        styles.container,
        !isNaN(Number(numberOfLines)) && styles.numberOfLines,
        classList,
      ].join(" ")}
      style={{
        fontSize: `calc(${fontSize} * var(--REM))`,
        lineHeight: `${lineHeight}%`,
        fontWeight,
        color,
        textAlign: align,
      }}>
      <span
        style={{
          ...(!isNaN(Number(numberOfLines))
            ? {WebkitLineClamp: Number(numberOfLines)}
            : {}),
        }}>
        {children}
      </span>
    </div>
  );
}
