import {Svgs} from "@assets";
import {FontText, Modal} from "@components";
import {COLOR} from "@utils";
import React from "react";
import styles from "./style.module.scss";

interface IProps {
  close: () => void;
  visible: boolean;
  onPress: () => void;
}

export function ProductDetailSignOnCouponModal({
  close,
  visible,
  onPress,
}: IProps) {
  return (
    <Modal
      visible={visible}
      close={close}
      className={styles.productDetailSignonCouponModal}>
      <div className={styles.wrap}>
        <div className={styles.modal}>
          <div className={styles.contents}>
            <FontText
              fontSize={20}
              fontWeight={700}
              lineHeight={150}
              align="center">
              {
                "지금 가입하면 6천원 쿠폰팩과\n또또우유 100원 구매 혜택을 드려요!"
              }
            </FontText>
            <FontText
              className={styles.subTitle}
              color={COLOR.gray3}
              lineHeight={150}
              fontSize={16}
              align="center">
              가입 후 바로 사용할 수 있어요
            </FontText>
            <Svgs.ZoopiCouponTwoSvg />
            <div className={styles.btn} onClick={onPress}>
              <FontText fontSize={16} color={COLOR.white} fontWeight={700}>
                가입하기
              </FontText>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
