import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 12,
  fill = COLOR.gray4,
}: {
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={(Number(width) / 12) * 2}
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.5H0V0.5H12V1.5Z"
        fill={fill}
      />
    </svg>
  );
}
