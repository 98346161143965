import {ICommonModalProps} from "@data";
import {COLOR} from "@utils";
import {FontText, Modal} from "@components";
import React from "react";
import styles from "./style.module.scss";

export default function CommonModal({
  visible,
  title,
  subTitle,
  content,
  leftButton,
  rightButton,
  oneButton,
  align = "center",
  backOpacity = 40,
  close,
}: ICommonModalProps) {
  return (
    <Modal
      visible={!!visible}
      isCenter={true}
      backOpacity={backOpacity}
      close={close}>
      <div className={styles.wrap}>
        <div className={styles.modal}>
          {title && (
            <FontText
              fontWeight={700}
              fontSize={18}
              lineHeight={150}
              color={COLOR.gray1}
              align={align}>
              {title}
            </FontText>
          )}
          {subTitle && (
            <div className={styles.subTitle}>
              <FontText lineHeight={150} color={COLOR.gray4} align={"center"}>
                {subTitle}
              </FontText>
            </div>
          )}
          {!!content && <div className={styles.content}>{content}</div>}
          <div className={styles.btnContainer}>
            {leftButton && (
              <div
                className={[styles.btn, styles.leftBtn].join(" ")}
                onClick={leftButton.onPress}>
                <FontText fontSize={16} fontWeight={700}>
                  {leftButton?.text}
                </FontText>
              </div>
            )}
            {rightButton && (
              <div
                className={[styles.btn, styles.rightBtn].join(" ")}
                onClick={rightButton?.onPress}>
                <FontText fontSize={16} fontWeight={700} color={COLOR.white}>
                  {rightButton?.text}
                </FontText>
              </div>
            )}
            {!!oneButton && (
              <div
                className={[styles.btn, styles.oneBtn].join(" ")}
                style={{
                  backgroundColor: oneButton.backgroundColor ?? COLOR.green,
                }}
                onClick={oneButton?.onPress}>
                <FontText
                  fontSize={16}
                  fontWeight={oneButton?.fontWeight ?? 700}
                  color={oneButton.textColor ?? COLOR.white}>
                  {oneButton?.text}
                </FontText>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
