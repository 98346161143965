import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 20,
  fill = COLOR.red,
}: {
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3739 4.03914C13.7645 3.64861 13.7645 3.01545 13.3739 2.62492C12.9834 2.2344 12.3502 2.2344 11.9597 2.62492L8.00016 6.58448L4.0406 2.62492C3.65008 2.2344 3.01691 2.2344 2.62639 2.62492C2.23587 3.01545 2.23587 3.64861 2.62639 4.03914L6.58595 7.9987L2.62639 11.9583C2.23587 12.3488 2.23587 12.9819 2.62639 13.3725C3.01691 13.763 3.65008 13.763 4.0406 13.3725L8.00016 9.41291L11.9597 13.3725C12.3502 13.763 12.9834 13.763 13.3739 13.3725C13.7645 12.9819 13.7645 12.3488 13.3739 11.9583L9.41438 7.9987L13.3739 4.03914Z"
        fill={fill}
      />
    </svg>
  );
}
