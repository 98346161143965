import {Svgs} from "@assets";
import {Img, ProductItem} from "@components";
import {APP_NAVIGATE, IPostListResponse, ScreenId} from "@data";
import {
  api,
  COLOR,
  copyURL,
  decryptQuery,
  encryptQuery,
  getParameterObject,
  getProductPrice,
  isDefined,
} from "@utils";
import _ from "lodash";
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import Slider from "react-slick";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "./style.module.scss";
import {toast, ToastContainer} from "react-toastify";
import {useDownload, useHackleTrack, useMember, useRNPost} from "@hook";

const TAB_LIST = [
  {name: "한상 차리기", id: "postDetailSection00"},
  {name: "재료", id: "postDetailSection01"},
];

export default function PostDetailPage() {
  const location = useLocation();
  const {hackleTrack} = useHackleTrack();
  const {RNpostMessage} = useRNPost();
  const {openDownloadAppModal} = useDownload();
  const {isApp} = useRecoilValue(getParameterState);
  const {user} = useMember();
  const {k} = getParameterObject({url: location.search});
  const no = decryptQuery({query: k});
  const [detail, setDetail] = useState<IPostListResponse>();
  const [navIndex, setNavIndex] = useState(0);
  const [isLike, setIsLike] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const notSoldOutList = (detail?.recipe_item || []).filter(_item => {
    const isSoldOut =
      (_item?.product?.stock || 0) <= 0 &&
      (_item?.product?.stock_date?.stock_count || 0) <= 0;
    return !isSoldOut;
  });
  const totalPrice = notSoldOutList.reduce((acc, cur) => {
    const productPrice = getProductPrice({product: cur});
    return acc + productPrice;
  }, 0);

  async function getDetail() {
    try {
      if (!no && isNaN(Number(no))) {
        return;
      }
      const {data, success, message} = await api.v1.post.getDetail({
        no: Number(no),
      });
      if (success) {
        setDetail(data.result);
        setIsLike(!!data.result?.my_like_history);
      } else {
        alert(message);
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  function goToInstargram() {
    if (!detail?.instagram_url) {
      return;
    }
    window.location.href = detail?.instagram_url;
  }

  async function setLike() {
    try {
      if (!detail?.no) {
        return;
      }
      if (!user) {
        if (isApp) {
          RNpostMessage({action: "navigate", screenId: APP_NAVIGATE.LOGIN});
        } else {
          openDownloadAppModal();
        }
        return;
      }
      const {success} = await api.v1.post.setLike({
        post_no: detail.no,
        isLike: !isLike,
      });
      if (success) {
        setIsLike(!isLike);
      }
    } catch (error) {
      console.log("setLike error", error);
    }
  }

  function copyShareLink() {
    if (!detail?.no) {
      return;
    }
    hackleTrack({
      key: "click__post_share",
      properties: {
        post_no: String(detail.no),
        isApp: isApp ? "TRUE" : "FALSE",
      },
    });
    if (isApp) {
      RNpostMessage({
        action: "share",
        data: copyURL(
          `${ScreenId.POST_DETAIL}?k=${encryptQuery({
            query: `${detail.no}`,
          })}`,
        ),
      });
    } else {
      window.navigator.clipboard.writeText(
        copyURL(
          `${ScreenId.POST_DETAIL}?k=${encryptQuery({query: `${detail.no}`})}`,
        ),
      );
      toast("복사되었습니다.", {toastId: "copyToast"});
    }
  }

  function allAddCart() {
    try {
      if (isApp) {
        RNpostMessage({
          action: "insert_all_cart",
          data: notSoldOutList.map(item => item.item_no).filter(isDefined),
        });
      } else {
        openDownloadAppModal();
      }
      if (detail?.no) {
        hackleTrack({
          key: "click__post_all_cart",
          properties: {
            isApp: isApp ? "TRUE" : "FALSE",
            post_no: String(detail.no),
            isSubscribe: "FALSE",
          },
        });
      }
    } catch (error) {
      console.log("allAddCart error", error);
    }
  }

  function moveToScrollSection(id: string) {
    const offsetTop = document.getElementById(id)?.offsetTop || 0;
    scrollRef.current?.scroll({top: offsetTop});
  }

  const handleScroll = () => {
    const offsetTop = scrollRef.current?.scrollTop || 0;
    const tabTop = TAB_LIST.map(tab => {
      const top = document.getElementById(tab.id)?.offsetTop || 0;
      return top;
    });
    let accTop = 0;
    tabTop.forEach((tab, index) => {
      accTop += tab;
      if (offsetTop > accTop - 150) {
        // 근사치 도달 시로 계산하기 위해 150 빼줌
        setNavIndex(index);
      }
    });
  };

  const throttleHandleScroll = _.throttle(handleScroll, 100);

  useEffect(() => {
    getDetail();
  }, [no]);

  useEffect(() => {
    if (no) {
      hackleTrack({
        key: "view__post_detail",
        properties: {
          post_no: String(no),
          isApp: isApp ? "TRUE" : "FALSE",
        },
      });
    }

    scrollRef.current?.addEventListener("scroll", throttleHandleScroll);
    return () => {
      scrollRef.current?.removeEventListener("scroll", throttleHandleScroll);
    };
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTab}>
            {TAB_LIST.map(({name, id}, index) => (
              <div
                key={_.uniqueId()}
                className={[
                  styles.item,
                  navIndex === index && styles.active,
                ].join(" ")}
                onClick={() => moveToScrollSection(id)}>
                {name}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.contentContainer} ref={scrollRef}>
          <div id={TAB_LIST[0].id}>
            <div className={styles.contentHeader}>
              <Svgs.ZoopiIcon />
              <span>table setting</span>
            </div>
            <div className={styles.titleContainer}>
              <div className={styles.title}>{detail?.title}</div>
              <div className={styles.subTitle}>{detail?.content}</div>
            </div>
            <div className={styles.contents}>
              <div key={_.uniqueId()} className={styles.detailItem}>
                <Img
                  src={
                    (detail?.detail?.[0]?.photos || [])?.[0]
                      ?.thumbnail_img_url ||
                    (detail?.detail?.[0]?.photos || [])?.[0]?.img_url
                  }
                />
                <div className={styles.title}>{detail?.detail?.[0]?.title}</div>
                <div className={styles.content}>
                  {detail?.detail?.[0]?.content}
                </div>
              </div>
              {(detail?.detail || [])
                .slice(1, (detail?.detail || []).length)
                .map(_item => {
                  const imageList = (_item.photos || [])
                    .map(_photo => _photo.thumbnail_img_url || _photo.img_url)
                    .filter(isDefined);
                  return (
                    <div key={_.uniqueId()} className={styles.detailItem}>
                      <div className={styles.title}>{_item.title}</div>
                      {imageList.length > 0 && (
                        <div className={styles.imgContainer}>
                          <Slider
                            swipeToSlide={true}
                            speed={500}
                            slidesToShow={1}
                            slidesToScroll={1}
                            dots={true}
                            className={"test"}
                            dotsClass={styles.sliderDots}
                            focusOnSelect={false}
                            appendDots={(dots: any) => (
                              <div>
                                <ul>{dots}</ul>
                              </div>
                            )}>
                            {imageList.map(img_url => (
                              <div key={_.uniqueId()}>
                                <Img src={img_url} />
                              </div>
                            ))}
                          </Slider>
                        </div>
                      )}
                      <div className={styles.content}>{_item.content}</div>
                    </div>
                  );
                })}
            </div>
          </div>
          {!!detail?.instagram_url && (
            <div className={styles.instagramContainer}>
              <div className={styles.btn} onClick={goToInstargram}>
                <div className={styles.svgContainer}>
                  <Svgs.InstagramIcon />
                </div>
                <span>인스타그램 구경가기</span>
              </div>
            </div>
          )}
          <div id={TAB_LIST[1].id}>
            <div className={styles.contents}>
              <div key={_.uniqueId()} className={styles.productSection}>
                <div className={styles.title}>한상차림 재료</div>
                <div className={styles.productContents}>
                  {(detail?.recipe_item || []).map((_item, index: number) => (
                    <div
                      key={_.uniqueId()}
                      className={[
                        styles.productItem,
                        index % 2 === 0 ? styles.even : styles.odd,
                      ].join(" ")}>
                      <ProductItem item={_item.product} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {totalPrice > 0 && (
          <div className={styles.footerContainer}>
            <div className={styles.icon} onClick={setLike}>
              {isLike ? (
                <Svgs.HeartIcon
                  width={20}
                  fill={COLOR.red}
                  stroke={COLOR.red}
                />
              ) : (
                <Svgs.HeartIcon width={20} stroke={COLOR.gray3} />
              )}
            </div>
            <div className={styles.divider} />
            <div className={styles.icon} onClick={copyShareLink}>
              <Svgs.ShareIcon stroke={COLOR.gray3} />
            </div>
            <div className={styles.footerBtn} onClick={allAddCart}>
              {totalPrice.toLocaleString()}원으로 한 번에 담기 {">"}
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        theme="light"
        containerId="copyToast"
      />
    </>
  );
}
