import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 10,
  fill = COLOR.gray4,
}: {
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 4.25V0H4.25V4.25H0V5.75H4.25V10H5.75V5.75H10V4.25H5.75Z"
        fill={fill}
      />
    </svg>
  );
}
