import {FontText, Img, Modal} from "@components";
import {IDBPartner} from "@data";
import {useCommon} from "@hook";
import {COLOR} from "@utils";
import React, {useEffect, useRef, useState} from "react";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "./style.module.scss";
import Slider from "react-slick";
import {Svgs} from "@assets";
import ReactAudioPlayer from "react-audio-player";

export interface IProductImageModal {
  isVisible: boolean;
  title?: "제조방법" | "원산지";
  image?: {
    title?: string;
    img_url?: string;
  }[];
  sub_title?: string;
}

interface IProps {
  visible?: boolean;
  imageModal?: IProductImageModal;
  setImageModal?: (value: IProductImageModal) => void;
  footer?: JSX.Element;
  partner?: IDBPartner;
}

const delay = 2500;

export function ImagePlayModal({
  visible,
  imageModal,
  setImageModal,
  footer,
  partner,
}: IProps) {
  const {bgmList} = useCommon();
  const {safeArea, isHeader} = useRecoilValue(getParameterState);
  const [page, setPage] = useState<number>();
  const carouselRef = useRef<Slider>(null);
  const [autoSlide, setAutoSlide] = useState(false);

  const imgList = imageModal?.image || [];
  const maxPage = imgList.length - 1;

  const musicPath =
    imageModal && imageModal.title === "제조방법"
      ? bgmList?.find(item => item.no === 3)?.bgm_url
      : bgmList?.find(item => item.no === 1)?.bgm_url;

  const closeHandler = () => {
    setImageModal?.({isVisible: false});
    setPage(undefined);
    setAutoSlide(false);
  };

  useEffect(() => {
    if (!isNaN(Number(page))) {
      if (page === 0) {
        setAutoSlide(true);
      }
      if (page === maxPage) {
        setTimeout(closeHandler, delay);
      }
    }
  }, [page]);

  useEffect(() => {
    if (visible) {
      setPage(0);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      close={closeHandler}
      isFullModal={true}
      toWay="none"
      className={styles.imagePlayModal}>
      <div className={styles.wrap}>
        <div
          className={styles.imgSection}
          style={{marginTop: safeArea?.top || 0}}>
          <div>
            {imgList.length > 0 && !!visible && (
              <Slider
                ref={carouselRef}
                swipeToSlide={true}
                slidesToShow={1}
                slidesToScroll={1}
                dots={false}
                focusOnSelect={false}
                infinite={false}
                autoplay={autoSlide}
                autoplaySpeed={delay}
                afterChange={currentSlide => {
                  setPage(currentSlide);
                }}>
                {imgList.map((item, index) => (
                  <div key={index} className={styles.item}>
                    <div className={styles.imgContainer}>
                      <Img src={item.img_url} />
                    </div>
                    <div className={styles.touchArea}>
                      <div
                        onClick={() => {
                          if (index > 0) {
                            carouselRef.current?.slickGoTo(index - 1);
                          }
                        }}
                      />
                      <div
                        onClick={() => {
                          if (index < maxPage) {
                            carouselRef.current?.slickGoTo(index + 1);
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
        <div
          className={styles.topContent}
          style={{marginTop: safeArea?.top || 0}}>
          {isHeader && (
            <div className={styles.header}>
              <div onClick={closeHandler} className={styles.backIcon}>
                <Svgs.BackSvg stroke={COLOR.white} />
              </div>
              <div className={styles.title}>
                <FontText
                  numberOfLines={1}
                  align="center"
                  fontSize={16}
                  lineHeight={150}
                  fontWeight={500}
                  color={COLOR.white}>
                  {imageModal?.title}
                </FontText>
              </div>
              <div className={styles.right} />
            </div>
          )}
          <div className={styles.playLine}>
            {(imageModal?.image || []).map((item, index) => {
              const isActive = (page || 0) > index;
              const isLoading = (page || 0) === index;
              return (
                <div key={index} className={styles.item}>
                  <div
                    className={[
                      styles.activeLine,
                      isActive && autoSlide && styles.active,
                      isLoading && autoSlide && styles.loading,
                    ].join(" ")}>
                    <div className={styles.animateLine} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.bottom}>
            <FontText fontSize={16} color={COLOR.white} fontWeight={500}>
              {imageModal?.sub_title}
            </FontText>
            <div className={styles.partnerRow}>
              {partner?.logo_url ? (
                <Img src={partner?.logo_url} className={styles.partnerImg} />
              ) : (
                <div className={styles.emptyImg}>
                  <Svgs.HouseSvg />
                </div>
              )}
              <FontText color={COLOR.white}>{partner?.partner_name}</FontText>
            </div>
          </div>
          <div className={styles.bottomFooter}>{footer}</div>
          <div className={styles.radioHidden}>
            <ReactAudioPlayer
              src={musicPath}
              autoPlay={!!imageModal?.isVisible}
              controls
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
