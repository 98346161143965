import {Svgs} from "@assets";
import {FontText, Img, ProductItem} from "@components";
import {IHomeProduct, IHomeSection, IProduct, ScreenId} from "@data";
import {useDownload, usePageScroll, useRNPost} from "@hook";
import {COLOR, encryptQuery} from "@utils";
import _ from "lodash";
import React, {
  useRef,
  useState,
  MouseEvent,
  useLayoutEffect,
  UIEvent,
} from "react";
import {useNavigate} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "./style.module.scss";

export function SectionDetailViewType1({list}: {list: IHomeProduct[]}) {
  return (
    <div className={styles.viewType1}>
      {(list || []).map((_product, _index) => (
        <div key={_index} className={styles.item}>
          <div>
            <ProductItem item={_product} key={_index} />
          </div>
        </div>
      ))}
    </div>
  );
}

export function SectionDetailViewType2({
  list,
  divId,
  setScroll,
}: {
  list: IHomeProduct[];
  divId?: string;
  setScroll?: (scrollX: number) => void;
}) {
  const {getPageScroll, scrollToPrevious} = usePageScroll();
  const scrollRef = useRef<HTMLDivElement>(null);
  const isDrag = useRef(false);
  const [startX, setStartX] = useState(0);

  const onDragStart = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    isDrag.current = true;
    setStartX(e.pageX + (scrollRef.current?.scrollLeft || 0));
  };

  const onDragEnd = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    isDrag.current = false;
  };

  const onDragMove = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (isDrag.current) {
      try {
        const scrollLeft = startX - e.pageX;
        scrollRef.current!.scrollLeft = scrollLeft;
        setScroll?.(scrollLeft);
      } catch (error) {
        console.error("onDragMove error", error);
      }
    }
  };

  const onScroll = (e: UIEvent) => {
    try {
      setScroll?.(scrollRef.current!.scrollLeft);
    } catch (error) {
      console.error("onScroll error", error);
    }
  };

  const onThrottleDragMove = _.throttle(onDragMove, 100);

  useLayoutEffect(() => {
    const prevScroll = getPageScroll({screenId: ScreenId.HOME, divId});
    if (prevScroll >= 0) {
      scrollToPrevious({
        ref: scrollRef,
        screenId: ScreenId.HOME,
        divId,
        isHorizontal: true,
      });
    }
  }, []);

  return (
    <div
      ref={scrollRef}
      onMouseDown={onDragStart}
      onMouseMove={isDrag.current ? onThrottleDragMove : undefined}
      onMouseUp={onDragEnd}
      onMouseLeave={onDragEnd}
      onScroll={onScroll}
      className={styles.viewType2}>
      {(list || []).map((_product, _index) => (
        <div key={_index} className={styles.item}>
          <div>
            <ProductItem item={_product} key={_index} />
          </div>
        </div>
      ))}
    </div>
  );
}

export function SectionDetailViewType3({
  section_data,
  section_no,
  setItemDetail,
}: {
  section_data?: IHomeSection;
  section_no: number;
  setItemDetail?: (itemList: IHomeProduct[]) => void;
}) {
  const {isApp} = useRecoilValue(getParameterState);
  const {RNpostMessage} = useRNPost();
  const {openDownloadAppModal} = useDownload();
  const navigation = useNavigate();

  const img_url = section_data?.thumb_img_url || section_data?.img_url;
  const list = section_data?.detail || [];

  function goDetail() {
    try {
      if (!!section_data?.post_no && !isNaN(section_data?.post_no)) {
        navigation(
          `${ScreenId.POST_DETAIL}?k=${encryptQuery({
            query: `${section_data?.post_no}`,
          })}`,
        );
      } else {
        openDownloadAppModal();
      }
    } catch (error) {
      console.error("SectionDetailViewType3 goDetail error", error);
    }
  }

  async function openCombinationModal({
    product_ist,
  }: {
    product_ist: IProduct[];
  }) {
    if (isApp) {
      RNpostMessage({
        action: "combination_modal",
        data: product_ist,
      });
    } else {
      openDownloadAppModal();
    }
  }

  const throttleOpenCombinationModal = _.throttle(openCombinationModal, 1000);

  return (
    <div className={styles.viewType3}>
      <div className={styles.container}>
        {!!img_url && (
          <div onClick={goDetail} className={styles.mainImg}>
            <Img src={img_url} />
          </div>
        )}
        <div className={styles.content}>
          <div>
            {!!section_data?.home_title && (
              <FontText
                fontSize={18}
                lineHeight={150}
                color={COLOR.gray1}
                fontWeight={500}>
                {section_data?.home_title}
              </FontText>
            )}
            {!!section_data?.home_sub_title && (
              <div style={{marginTop: 2}}>
                <FontText color={COLOR.gray888888}>
                  {section_data?.home_sub_title}
                </FontText>
              </div>
            )}
          </div>
          <div
            onClick={() => {
              throttleOpenCombinationModal({product_ist: list});
            }}
            className={styles.recipeContainer}>
            <div className={styles.title}>
              <FontText lineHeight={150} color={COLOR.gray7A7A7A}>
                모든 재료 보기
              </FontText>
            </div>
            <div className={styles.itemList}>
              {(list || []).slice(0, 3).map((_product, _index) => (
                <div key={_index} className={styles.item}>
                  <Img src={_product.item_thumbnail_img} />
                  {_index === 2 && (list || []).length > 3 && (
                    <div className={styles.more}>
                      <FontText color={COLOR.white} fontWeight={600}>
                        +{(list || []).length - 3}
                      </FontText>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div onClick={goDetail} className={styles.bottom}>
          <div>
            <FontText color={COLOR.white} fontWeight={500}>
              더 알아보기
            </FontText>
            <Svgs.ChevronIcon width={16} stroke={COLOR.white} rotate={270} />
          </div>
        </div>
      </div>
    </div>
  );
}
