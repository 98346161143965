import React, {useLayoutEffect} from "react";
import {WebRouteApp} from "@navigation";
import {Route, Routes} from "react-router-dom";
import {Layout, DownloadModal} from "@components";
import {useHackleTrack} from "@hook";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";

export default function WebNavigation() {
  const {hackleTrack} = useHackleTrack();
  const {landing} = useRecoilValue(getParameterState);

  useLayoutEffect(() => {
    if (landing?.type === "blog") {
      hackleTrack({
        key: "blog_to_landing",
        properties: {blog_no: landing?.blog_no || "-"},
      });
    }
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <Layout>
              <WebRouteApp />
            </Layout>
          }
        />
      </Routes>
      <DownloadModal />
    </>
  );
}
