import {Svgs} from "@assets";
import {FontText, Img} from "@components";
import {APP_NAVIGATE, IProductReview} from "@data";
import {useDownload, useRNPost} from "@hook";
import {COLOR, formatNickname} from "@utils";
import moment from "moment";
import React from "react";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";
import styles from "../style.module.scss";

export function TopReviewSection({
  previewReview,
  review_cnt,
}: {
  previewReview: IProductReview;
  review_cnt: number;
}) {
  const {isApp} = useRecoilValue(getParameterState);
  const {RNpostMessage} = useRNPost();
  const {openDownloadAppModal} = useDownload();

  function goDetail({
    screenId,
    id,
    item_no,
    data = {},
  }: {
    screenId: APP_NAVIGATE;
    id: number;
    item_no?: number;
    data?: {[key: string]: string | number};
  }) {
    if (isApp) {
      if (previewReview.no) {
        RNpostMessage({action: "navigate", screenId, id, item_no, data});
      }
    } else {
      openDownloadAppModal();
    }
  }

  return (
    <div className={styles.topReviewSection}>
      <div className={styles.container}>
        <div
          onClick={() => {
            if (previewReview.no && previewReview.item_no)
              goDetail({
                screenId: APP_NAVIGATE.REVIEW_LIST,
                id: previewReview.no,
                data: {no: previewReview.item_no},
              });
          }}
          className={styles.title}>
          <div className={styles.left}>
            <div className={styles.sectionTitle}>
              <FontText fontSize={16} fontWeight={700}>
                먹어본 아이들의 반응
              </FontText>
            </div>
            <FontText fontSize={12} color={COLOR.gray3}>
              ({review_cnt > 9999 ? "9999+" : review_cnt}개의 후기)
            </FontText>
          </div>
          <div className={styles.chevron}>
            <Svgs.ChevronIcon rotate={270} />
          </div>
        </div>
        <div
          className={styles.contentContainer}
          onClick={() => {
            if (previewReview.no && previewReview.item_no)
              goDetail({
                screenId: APP_NAVIGATE.REVIEW_DETAIL,
                id: previewReview.no,
                item_no: previewReview.item_no,
                data: {
                  no: previewReview.no,
                  item_no: previewReview.item_no,
                },
              });
          }}>
          {previewReview.thumbnail_img && (
            <Img src={previewReview.thumbnail_img} />
          )}
          <div className={styles.textContainer}>
            <FontText lineHeight={150} color={COLOR.gray2} numberOfLines={3}>
              {previewReview.content}
            </FontText>
            <div className={styles.bottom}>
              <div className={styles.badge}>
                <FontText fontSize={10} fontWeight={500}>
                  {previewReview.payment_cnt && previewReview.payment_cnt > 99
                    ? "99회+"
                    : previewReview.payment_cnt
                    ? previewReview.payment_cnt + "회 "
                    : "0회"}
                  구매
                </FontText>
              </div>
              <div className={styles.nickname}>
                <FontText fontSize={12} fontWeight={500}>
                  {formatNickname(previewReview.member?.nickname)}
                </FontText>
              </div>
              <FontText fontSize={12} fontWeight={500} color={COLOR.gray5}>
                {moment(previewReview.createdAt).format("YY.MM.DD")}
              </FontText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
