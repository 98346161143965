import {useTrack, HackleEvent} from "@hackler/react-sdk";
import React from "react";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";

type TStrBoolean = "TRUE" | "FALSE";

type THackleEvent =
  | "first_open"
  | "view__home"
  | "click__cart_home"
  | "click__banner"
  | "login"
  | "create__member"
  | "view__create_pet"
  | "create__pet"
  | "view__search"
  | "view__mypage"
  | "click__material_detail"
  | "click__recipe_detail"
  | "click__add_to_cart"
  | "view__cart"
  | "click__purchase_cart"
  | "view_check_out"
  | "completed__order"
  | "completed__order_products"
  | "view__post_detail"
  | "click__post_detail"
  | "click__post_share"
  | "click__post_all_cart"
  | "click__product_share"
  | "regist_card"
  | "view__product_detail"
  | "click__purchase"
  | "view__checkout"
  | "swipe__recipe"
  | "swipe__recipe_last_page"
  | "blog_to_landing";

interface IHackleInit extends HackleEvent {
  key: THackleEvent;
}

interface IFirstOpen extends IHackleInit {
  key: "first_open";
}
interface IViewHome extends IHackleInit {
  key: "view__home";
  properties: {
    phone_number?: string;
    is_first_visit?: boolean;
    pet_name?: string;
    pet_age?: number;
    pet_kind?: string;
    pet_type?: string;
    pet_size?: string;
    is_member?: boolean;
    nickname?: string;
    order_cnt?: number;
    address_si?: string;
    address_gu?: string;
    address_dong?: string;
    created_at?: string;
  };
}
interface IClickCartHome extends IHackleInit {
  key: "click__cart_home";
  properties: {product_no: string};
}
interface IClickBanner extends IHackleInit {
  key: "click__banner";
  properties: {page_name: string; banner_id: string; order?: string};
}
interface ILogin extends IHackleInit {
  key: "login";
  properties: {
    phone_number?: string;
    pet_age?: number;
    pet_kind?: string;
    pet_type?: string;
    pet_size?: string;
    order_cnt?: number;
    address_si?: string;
    address_gu?: string;
    address_dong?: string;
  };
}
interface ICreateMember extends IHackleInit {
  key: "create__member";
  properties: {phone_number?: string};
}
interface IViewCreatePet extends IHackleInit {
  key: "view__create_pet";
  properties: {phone_number?: string; pre_page_name?: string};
}
interface ICreatePet extends IHackleInit {
  key: "create__pet";
  properties: {
    phone_number?: string;
    pet_name?: string;
    pet_birthday?: string;
    pet_weight?: number;
    pet_kind?: number;
    pet_type?: number;
    pet_sex?: string;
    pet_allergy?: string;
    pet_disease?: string;
    pet_eating_habits?: number;
  };
}
interface IViewSearch extends IHackleInit {
  key: "view__search";
  properties: {phone_number?: string};
}
interface IViewMypage extends IHackleInit {
  key: "view__mypage";
  properties: {phone_number?: string};
}
interface IClickMaterialDetail extends IHackleInit {
  key: "click__material_detail";
}
interface IClickRecipeDetail extends IHackleInit {
  key: "click__recipe_detail";
}
interface IViewProductDetail extends IHackleInit {
  key: "view__product_detail";
  properties: {
    is_member?: boolean;
    phone_number?: string;
    pre_page_name?: string;
    order?: number;
    product_no: string;
    category_no: string;
    view_price: number;
    price: number;
    rating?: number;
    is_delivery?: string;
    product_name?: string;
  };
}
interface IClickAddToCart extends IHackleInit {
  key: "click__add_to_cart";
  properties: {
    is_member?: boolean;
    phone_number?: string;
    pre_page_name?: string;
    order?: number;
    product_no: string;
    category_no: string;
    view_price: number;
    price: number;
    rating?: number;
    is_delivery?: string;
    product_name?: string;
  };
}
interface IClickPurchase extends IHackleInit {
  key: "click__purchase";
  properties: {
    is_member?: boolean;
    phone_number?: string;
    pre_page_name?: string;
    product_no: string;
    category_no: string;
    view_price: number;
    price: number;
    rating?: number;
    is_delivery?: string;
    product_name?: string;
  };
}
interface IViewCart extends IHackleInit {
  key: "view__cart";
  properties: {
    phone_number?: string;
    is_meet_minimum_order_amount?: boolean;
    product_no?: string;
  };
}
interface IClickPurchaseCart extends IHackleInit {
  key: "click__purchase_cart";
  properties: {
    pre_page_name?: string;
    product_no: string;
    total_price: number;
    is_delivery?: string;
    is_meet_minimum_order_amount?: boolean;
  };
}
interface IViewCheckOut extends IHackleInit {
  key: "view_check_out";
  properties: {
    pre_page_name?: string;
    product_no: string;
    card?: string;
    total_price: number;
  };
}
interface ICompletedOrder extends IHackleInit {
  key: "completed__order";
  properties: {
    order_no: string;
    payment_method?: string;
    total_price: number;
    use_point?: string;
    use_coupon?: string;
    delivery_fee: number;
    discounted_price: number;
    is_first?: boolean;
    recipient_name?: string;
    recipient_phone?: string;
    recipient_address?: string;
    order_cnt?: number;
    address_si?: string;
    address_gu?: string;
    address_dong?: string;
    buy_count?: number;
  };
}
interface ICompletedOrderProducts extends IHackleInit {
  key: "completed__order_products";
  properties: {
    order_no: string;
    pre_page_name?: string;
    product_no: string;
    category_no: string;
    view_price: number;
    price: number;
    rating?: number;
  };
}
interface IViewPost extends IHackleInit {
  key: "view__post_detail";
  properties: {post_no: string; isApp: TStrBoolean};
}
interface IClickPostDetail extends IHackleInit {
  key: "click__post_detail";
  properties: {post_no: string; isApp: TStrBoolean};
}
interface IClickPostShare extends IHackleInit {
  key: "click__post_share";
  properties: {post_no: string; isApp: TStrBoolean};
}
interface IClickPostAllCart extends IHackleInit {
  key: "click__post_all_cart";
  properties: {post_no: string; isApp: TStrBoolean; isSubscribe: TStrBoolean};
}
interface IClickProductShare extends IHackleInit {
  key: "click__product_share";
  properties: {product_no: string; isApp: TStrBoolean; is_member: TStrBoolean};
}
interface IRegistCard extends IHackleInit {
  key: "regist_card";
  properties: {phone_number?: string};
}
interface IViewCheckout extends IHackleInit {
  key: "view__checkout";
  properties: {
    pre_page_name?: string;
    product_no: string;
    total_price: number;
    is_delivery?: string;
  };
}
interface ISwipeRecipe extends IHackleInit {
  key: "swipe__recipe";
  properties: {
    product_no: string;
  };
}
interface ISwipeRecipeLastPage extends IHackleInit {
  key: "swipe__recipe_last_page";
  properties: {
    product_no: string;
  };
}
interface IBlogToLanding extends IHackleInit {
  key: "blog_to_landing";
  properties: {
    blog_no: string;
  };
}

type THackleTrack =
  | IFirstOpen
  | IViewHome
  | IClickCartHome
  | IClickBanner
  | ILogin
  | ICreateMember
  | IViewCreatePet
  | ICreatePet
  | IViewSearch
  | IViewMypage
  | IClickMaterialDetail
  | IClickRecipeDetail
  | IClickAddToCart
  | IViewCart
  | IClickPurchaseCart
  | IViewCheckOut
  | ICompletedOrder
  | ICompletedOrderProducts
  | IViewPost
  | IClickPostDetail
  | IClickPostShare
  | IClickPostAllCart
  | IRegistCard
  | IViewProductDetail
  | IClickPurchase
  | IViewCheckout
  | IClickProductShare
  | ISwipeRecipe
  | ISwipeRecipeLastPage
  | IBlogToLanding;

export default function useHackleTrack() {
  const {isApp} = useRecoilValue(getParameterState);
  const track = useTrack();

  function hackleTrack(value: THackleTrack) {
    try {
      track({
        ...value,
        properties: {
          isApp: isApp ? "TRUE" : "FALSE",
          ...(value.properties || {}),
        },
      });
    } catch (error) {
      console.error("useHackleTrack hackleTrack error", error);
    }
  }

  return {
    hackleTrack,
  };
}
