import {Svgs} from "@assets";
import {IProductReview} from "@data";
import {COLOR, formatNickname, handleClickBubbleAndCapture} from "@utils";
import {Img} from "@components";
import moment from "moment";
import React from "react";
import styles from "./style.module.scss";

interface Props {
  item: IProductReview;
  goDetail?: () => void;
  goWritePage?: () => void;
  goDeclarationPage?: () => void;
  isEditAble?: boolean;
  isWriteUser?: boolean;
}

export default function ReviewItem({
  item,
  goDetail,
  goWritePage,
  isEditAble,
  isWriteUser,
  goDeclarationPage,
}: Props) {
  const photoList = item.photo_list || [];
  const photoLen = photoList.length;

  return (
    <div className={styles.container} onClick={goDetail}>
      <div className={styles.topContainer}>
        <div className={styles.titleRow}>
          <div className={styles.userRow}>
            <Img src={item.member?.profile_img} />
            <div>
              <div className={styles.starContainer}>
                <div className={styles.emptyStar}>
                  <Svgs.ScoreSvg width={80} />
                </div>
                <div className={styles.fillStar}>
                  <div style={{width: `${Number(item.score || 0) * 20}%`}}>
                    <Svgs.ScoreSvg width={80} fill={COLOR.green} />
                  </div>
                </div>
              </div>
              <div className={styles.bottomRow}>
                <div className={styles.nickname}>
                  {formatNickname(item.member?.nickname || item.member?.name)}
                </div>
                <div className={styles.dot} />
                <div className={styles.date}>
                  {moment(item.createdAt).format("YY.MM.DD")}
                </div>
                <div className={styles.dot} />
                <div className={styles.buyCnt}>
                  {(item.payment_cnt || 0) > 99
                    ? "99회+"
                    : `${item.payment_cnt?.toLocaleString() || 1}회`}{" "}
                  구매
                </div>
              </div>
            </div>
          </div>
          {isWriteUser ? (
            <>
              {!!isEditAble && (
                <div
                  className={styles.editBtn}
                  onClick={e => {
                    handleClickBubbleAndCapture(e);
                    goWritePage?.();
                  }}>
                  수정
                </div>
              )}
            </>
          ) : (
            <div
              className={styles.declarationBtn}
              onClick={e => {
                handleClickBubbleAndCapture(e);
                goDeclarationPage?.();
              }}>
              신고
            </div>
          )}
        </div>
      </div>
      {(item.selectedOption || []).length > 0 && (
        <div className={styles.selectedOption}>
          {(item.selectedOption || []).map((option, i) => (
            <div key={i} className={styles.item}>
              <div className={styles.categoryTitle}>
                {option.category_title}
              </div>
              <div className={styles.optionTitle}>{option.option_title}</div>
              {i < (item.selectedOption || []).length - 1 && (
                <div className={styles.dot} />
              )}
            </div>
          ))}
        </div>
      )}
      <div className={styles.content} style={{flex: 1}}>
        <span>{item.content}</span>
      </div>
      {photoLen > 0 && (
        <div className={styles.photoContainer}>
          {photoList.slice(0, 2).map((photo, i) => (
            <div key={i} className={styles.item}>
              <Img src={photo.thumbnail_img_url || photo.img_url} />
              {i === 1 && photoList.length > 2 && (
                <div className={styles.more}>+{photoLen - 2}</div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
