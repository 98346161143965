import {APP_NAVIGATE} from "@data";
import React from "react";
import {useRecoilValue} from "recoil";
import {getParameterState} from "recoils/atoms";

type TAction =
  | "back"
  | "navigate"
  | "share"
  | "toast"
  | "loaded"
  | "linking"
  | "insert_cart"
  | "insert_all_cart"
  | "insert_cart_subscribe"
  | "closeCheckpointTooltip"
  | "closeMaterialTooltip"
  | "closeSubscribeTooltip"
  | "closeNoneSubscribeTooltip"
  | "closeBottomReviewTooltip"
  | "closeBottomNoneReviewTooltip"
  | "closeFrequencyCoachMarkHome"
  | "closeFrequencyCoachMark"
  | "openIsproductGuaranteeHide"
  | "closeIsproductGuaranteeHide"
  | "closeFirstAccessDetail"
  | "combination_modal";

interface IProps {
  action: TAction;
  screenId?: APP_NAVIGATE;
  id?: number;
  product?: any;
  data?: any;
  item_no?: number;
}

export default function useRNPost() {
  const {isApp} = useRecoilValue(getParameterState);

  function RNpostMessage(params: IProps) {
    try {
      if (isApp) {
        window.ReactNativeWebView?.postMessage?.(JSON.stringify(params));
      }
    } catch (error) {
      console.error("postMessage error", error);
    }
  }

  return {
    RNpostMessage,
  };
}
