import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 24,
  fill = COLOR.check,
}: {
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none">
      <circle cx="12" cy="12" r="10" fill={fill} />
      <path d="M6.5 12L10.5 16L17 9.5" stroke="white" strokeWidth="1.5" />
    </svg>
  );
}
