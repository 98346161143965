import {envUtil, getParameterObject} from "@utils";
import {useEffect, useState} from "react";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";

const GATracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const {isApp} = getParameterObject({
    url: window.location.search,
  });

  useEffect(() => {
    if (!envUtil.IS_DEV && !isApp) {
      ReactGA.initialize(envUtil.GA_TRACKING_ID);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized && !isApp) {
      ReactGA.set({page: `${location.pathname}${location.search}`});
      ReactGA.send("pageview");
    }
  }, [initialized, location]);

  return null;
};

export default GATracker;
