import {isDefined} from "@utils";
import React from "react";
import styles from "./style.module.scss";

interface IProps {
  src?: string;
  className?: string | string[];
}

export default function Img({src, className}: IProps) {
  const classList = [
    styles.img,
    ...(Array.isArray(className) ? className : [className]),
  ].filter(isDefined);

  if (!src) {
    return <></>;
  }

  return <img src={src} className={[styles.img, ...classList].join(" ")} />;
}
