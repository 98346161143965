import {Svgs} from "@assets";
import {FontText} from "@components";
import {IProductDetailStorage, TProductItemDetail} from "@data";
import {COLOR} from "@utils";
import React from "react";
import styles from "../style.module.scss";

export function StorageItem({data}: {data: TProductItemDetail[]}) {
  const list = (data.filter(_data => _data?.type === "storage") ||
    []) as IProductDetailStorage[];
  const item = list[0];

  if (!item || (item?.data_json?.data || []).length === 0) {
    return <></>;
  }

  return (
    <div className={styles.storageItem}>
      <div className={styles.container}>
        <FontText fontSize={16} lineHeight={150} fontWeight={700}>
          보관 방법
        </FontText>
        <div className={styles.listContainer}>
          {(item?.data_json?.data || []).map((_item, index) => {
            return (
              <div
                key={index}
                className={[
                  styles.item,
                  _item.icon === "check" && styles.check,
                  _item.icon === "close" && styles.close,
                ].join(" ")}>
                <div className={styles.iconContainer}>
                  {_item.icon === "check" && (
                    <Svgs.CheckSvg stroke={COLOR.middleGreenColor} />
                  )}
                  {_item.icon === "close" && (
                    <Svgs.CloseIconSvg fill={COLOR.red} />
                  )}
                </div>
                <div className={styles.textContainer} style={{flex: 1}}>
                  <FontText
                    fontSize={16}
                    lineHeight={150}
                    fontWeight={600}
                    color={COLOR.gray3}>
                    {_item.title}
                  </FontText>
                  {!!_item.sub_title && (
                    <div className={styles.subTitle}>
                      <FontText
                        fontSize={16}
                        lineHeight={150}
                        color={COLOR.gray4}>
                        {_item.sub_title}
                      </FontText>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
