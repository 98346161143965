import {
  HeaderProps,
  TNavigation,
  IMemberData,
  TDeliveryType,
  IToast,
  IHomeSection,
  IDBBanner,
} from "@data";
import {atom} from "recoil";

export const userState = atom<IMemberData | undefined>({
  key: "userStateKey",
  default: undefined,
});

export const headerState = atom<HeaderProps>({
  key: "headerStateKey",
  default: {},
});

export const footerState = atom<boolean>({
  key: "footerStateKey",
  default: false,
});

export const navigationState = atom<TNavigation>({
  key: "navigationStateKey",
  default: "home",
});

export const getParameterState = atom<{
  isHeader: boolean;
  isBottom: boolean;
  OS: string;
  isApp: boolean;
  delivery_type?: TDeliveryType;
  safeArea?: {
    top?: number;
    bottom?: number;
  };
  landing?: {type?: string; blog_no?: string};
}>({
  key: "getParameter",
  default: {
    isHeader: true,
    isBottom: true,
    OS: "",
    isApp: false,
    safeArea: {top: 0, bottom: 0},
  },
});

export const toastState = atom<IToast | undefined>({
  key: "toastState",
  default: undefined,
});

export const productListState = atom<IHomeSection[]>({
  key: "productList",
  default: [],
});

export const homeTabSectionItemListState = atom<IHomeSection[]>({
  key: "homeTabSectionItemList",
  default: [],
});

export const bannerListState = atom<IDBBanner[]>({
  key: "bannerList",
  default: [],
});
