import moment from "moment";

const weekday: {
  [key in number]: string;
} = {
  1: "월",
  2: "화",
  3: "수",
  4: "목",
  5: "금",
  6: "토",
  0: "일",
};

export function dateFormatMoment(date: moment.Moment) {
  try {
    const _weekday = moment(date).day();
    const _week = weekday[_weekday];
    return `${date.format("M월 D일")}(${_week})`;
  } catch (error) {
    console.log("dateFormatMoment error", error);
    return "";
  }
}

export function dateFormat(date: Date) {
  try {
    return moment(date).format("M월 D일");
  } catch (error) {
    console.log("dateFormat error", error);
    return "";
  }
}

export function dateWeekdayOrderFormat(date: Date) {
  try {
    const _date = moment(date).format("MM/DD");
    const _weekday = moment(date).day();
    const _week = weekday[_weekday];
    return `${_date} (${_week})`;
  } catch (error) {
    console.log("dateFormat error", error);
    return "";
  }
}

export function dateYearWeekdayOrderFormat(date: Date) {
  try {
    const _date = moment(date).format("YY/MM/DD");
    const _weekday = moment(date).day();
    const _week = weekday[_weekday];
    return `${_date} (${_week})`;
  } catch (error) {
    console.log("dateFormat error", error);
    return "";
  }
}

export function dateWeekdayFormat(date: Date) {
  try {
    const _date = dateFormat(date);
    const _weekday = moment(date).day();
    const _week = weekday[_weekday];
    return `${_date} ${_week}요일`;
  } catch (error) {
    console.log("dateFormat error", error);
    return "";
  }
}

export function dateWeekdaySimpleFormat(date: Date) {
  try {
    const _date = dateFormat(date);
    const _weekday = moment(date).day();
    const _week = weekday[_weekday];
    return `${_date}(${_week})`;
  } catch (error) {
    console.log("dateFormat error", error);
    return "";
  }
}

export function timeFormat(date: Date) {
  try {
    const time = moment(date).get("h");
    const _ampm = time >= 12 ? "오후" : "아침";
    const _time = time > 12 ? time - 12 : time;
    return `${_ampm} ${_time}시`;
  } catch (error) {
    console.log("dateFormat error", error);
    return "";
  }
}

export function fullDateFormat(date: Date) {
  try {
    const _date = dateFormat(date);
    const _weekday = moment(date).day();
    const _week = weekday[_weekday];
    const time = timeFormat(date);
    return `${_date} ${_week}요일 ${time}`;
  } catch (error) {
    console.log("fullDateFormat error", error);
    return "";
  }
}

export function generateDateArray({
  startDate,
  endDate,
}: {
  startDate: moment.Moment;
  endDate: moment.Moment;
}) {
  const dateArray = [];
  let currentDate = moment(startDate);

  while (currentDate <= endDate) {
    dateArray.push(moment(currentDate));
    currentDate = moment(currentDate).add(1, "day");
  }

  return dateArray;
}
