import {COLOR} from "@utils";
import React from "react";

export default function Svg({
  width = 19,
  stroke = COLOR.white,
  fill,
}: {
  width?: number;
  stroke?: COLOR;
  fill?: COLOR;
}) {
  return (
    <svg
      width={width}
      height={(Number(width) / 19) * 18}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.25" stroke={stroke} strokeWidth="1.5" />
      <line
        x1="14.0607"
        y1="13"
        x2="18"
        y2="16.9393"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
