import {FontText, Footer, Img, SignOnCouponSection} from "@components";
import {
  IDBBanner,
  IDBHomeTabList,
  IDBItemCategory,
  IHomeSection,
  ScreenId,
} from "@data";
import {useCommon, usePageScroll} from "@hook";
import {api, COLOR} from "@utils";
import _ from "lodash";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useNavigationType} from "react-router-dom";
import Slider from "react-slick";
import {useRecoilState} from "recoil";
import {
  bannerListState,
  homeTabSectionItemListState,
  productListState,
} from "recoils/atoms";
import BannerItem from "./BannerItem";
import {
  SectionDetailViewType1,
  SectionDetailViewType2,
  SectionDetailViewType3,
} from "./SectionDetailViewType";
import {BannerSkeletonView, ProductSkeletonView} from "./SkeletonView";
import styles from "./style.module.scss";
import {IPageScrollItem} from "hook/usePageScroll";

export interface ICategoryItem extends IDBItemCategory {
  navigate?: () => void;
}

interface IScrollHomeSection {
  divId?: string;
  scrollX?: number;
}

export default function HomePage() {
  const action = useNavigationType();
  const {homeTabList} = useCommon();
  const {getPageScroll, setPageScroll, setPageScrollList, scrollToPrevious} =
    usePageScroll();
  const [homeTabNo, setHomeTabNo] = useState<number>();
  const [bannerList, setBannerList] =
    useRecoilState<IDBBanner[]>(bannerListState);
  const [sectionList, setSectionList] =
    useRecoilState<IHomeSection[]>(productListState);
  const [tabSectionList, setTabSectionList] = useRecoilState<IHomeSection[]>(
    homeTabSectionItemListState,
  );
  const [isBannerSkeletonLoading, setIsBannerSkeletonLoading] = useState(false);
  const [isProductSkeletonLoading, setIsProductSkeletonLoading] =
    useState(false);
  const [bannerIndex, setBannerIndex] = useState(0);
  const [scrollHomeSection, setScrollHomeSection] = useState<
    IScrollHomeSection[]
  >([]);

  const TAB_LIST: IDBHomeTabList[] = [{name: "주피's pick"}, ...homeTabList];
  const homeTab = TAB_LIST.find(data => data.no === homeTabNo);
  const scrollRef = useRef<HTMLDivElement>(null);

  const visibleSectionList = homeTabNo ? tabSectionList : sectionList;

  async function getBannerList() {
    try {
      setIsBannerSkeletonLoading(true);
      const {data, success} = await api.v1.banner.bannerList();
      if (success) {
        setBannerList(data);
      }
      setIsBannerSkeletonLoading(false);
    } catch (error) {
      console.error("getBannerList error", error);
      setIsBannerSkeletonLoading(false);
    }
  }

  async function getProductList({home_tab_no}: {home_tab_no?: number}) {
    try {
      if (home_tab_no) {
        setTabSectionList([]);
      }

      setIsProductSkeletonLoading(true);
      const {data, success} = await api.v2.product.sectionList({
        home_tab_no,
      });
      if (success) {
        // 관리자에서 설정한 순서 무시하고 품절상품을 제일 뒤로 정렬
        const sectionListData = (data || []).map(item => ({
          ...item,
          detail: (item.detail || []).sort((a, b) => {
            const a_stock =
              ((a.stock || 0) > 0 ? a.stock : a.stock_date?.stock_count) || 0;
            const b_stock =
              ((b.stock || 0) > 0 ? b.stock : b.stock_date?.stock_count) || 0;
            const a_ordering = Number(
              (a.sectionDetail || [])[0]?.ordering || 0,
            );
            const b_ordering = Number(
              (b.sectionDetail || [])[0]?.ordering || 0,
            );

            if (
              (a_stock > 0 && b_stock > 0) ||
              (a_stock === 0 && b_stock === 0)
            ) {
              return a_ordering - b_ordering;
            } else if (a_stock === 0 && b_stock > 0) {
              return 1;
            } else if (a_stock > 0 && b_stock === 0) {
              return -1;
            }
            return a_ordering - b_ordering;
          }),
        }));

        if (!home_tab_no) {
          setSectionList(sectionListData);
        } else {
          setTabSectionList(sectionListData);
        }
        setIsProductSkeletonLoading(false);
      }
    } catch (error) {
      console.error("getProductList error", error);
      setIsProductSkeletonLoading(false);
    }
  }

  function onRefresh() {
    getBannerList();
    getProductList({});
  }

  function scrollEvent() {
    setPageScroll({
      screenId: ScreenId.HOME,
      scroll: scrollRef.current?.scrollTop,
    });
  }

  function setBlurPageScroll() {
    const newScrollList: IPageScrollItem[] = scrollHomeSection
      .map(item => ({
        screenId: ScreenId.HOME,
        divId: item.divId,
        scroll: item.scrollX,
      }))
      .filter(item => !!item.divId);
    setPageScrollList(newScrollList);
  }

  useLayoutEffect(() => {
    const prevScroll = getPageScroll({screenId: ScreenId.HOME});
    if (prevScroll === 0) {
      onRefresh();
    } else {
      scrollToPrevious({ref: scrollRef, screenId: ScreenId.HOME});
    }
  }, []);

  useEffect(() => {
    return () => {
      setBlurPageScroll();
    };
  }, [scrollHomeSection]);

  useEffect(() => {
    const prevScroll = getPageScroll({screenId: ScreenId.HOME});
    if (prevScroll === 0 || (prevScroll > 0 && action !== "POP")) {
      if (!homeTabNo) {
        setTabSectionList([]);
      } else {
        getProductList({home_tab_no: homeTabNo});
      }
    }
  }, [homeTabNo]);

  return (
    <>
      {/** 탭목록 */}
      {(TAB_LIST || []).length > 1 && (
        <div className={styles.topTabList}>
          {TAB_LIST.map((home_tab, index) => {
            const isActive = home_tab.no
              ? homeTabNo === home_tab.no
              : !homeTabNo;
            return (
              <div
                key={index}
                onClick={() => setHomeTabNo(home_tab.no)}
                className={[styles.tabItem, isActive && styles.active].join(
                  " ",
                )}>
                <FontText
                  lineHeight={150}
                  fontWeight={500}
                  color={isActive ? COLOR.green : COLOR.gray4}>
                  {home_tab.name}
                </FontText>
              </div>
            );
          })}
        </div>
      )}

      <div
        className={styles.scroll}
        ref={scrollRef}
        onScroll={scrollEvent}
        style={{backgroundColor: homeTab?.background_color ?? COLOR.white}}>
        {/** 배너 */}
        {!homeTabNo && bannerList.length > 0 ? (
          <>
            {isBannerSkeletonLoading ? (
              <BannerSkeletonView />
            ) : (
              <div className={styles.bannerContainer}>
                <Slider
                  swipeToSlide={true}
                  speed={500}
                  autoplay
                  autoplaySpeed={4000}
                  slidesToShow={1}
                  slidesToScroll={1}
                  dots={false}
                  afterChange={setBannerIndex}
                  focusOnSelect={false}>
                  {bannerList.map(item => (
                    <BannerItem key={_.uniqueId()} item={item} />
                  ))}
                </Slider>
                {bannerList.length > 1 && (
                  <div className={styles.bannerPage}>
                    <FontText
                      fontSize={12}
                      lineHeight={150}
                      color={COLOR.white}>
                      {bannerIndex + 1} / {bannerList.length}
                    </FontText>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {!homeTab ? (
              <BannerSkeletonView />
            ) : (
              <div
                className={[
                  styles.etcTabContainer,
                  homeTab.thumbnail_img && styles.isImg,
                ].join(" ")}>
                {!!homeTab.thumbnail_img && <Img src={homeTab.thumbnail_img} />}
                <div className={styles.textContainer}>
                  {!!homeTab.gray_title && (
                    <FontText fontSize={28} color={COLOR.grayWarm}>
                      {homeTab.gray_title}
                    </FontText>
                  )}
                  {!!homeTab.title && (
                    <FontText fontSize={28} color={COLOR.black}>
                      {homeTab.title}
                    </FontText>
                  )}
                  {!!homeTab.bold_title && (
                    <FontText
                      fontSize={28}
                      fontWeight={700}
                      color={COLOR.black}>
                      {homeTab.bold_title}
                    </FontText>
                  )}
                  {!!homeTab.sub_title && (
                    <div style={{paddingTop: 12}}>
                      <FontText fontWeight={500} fontSize={18}>
                        {homeTab.sub_title}
                      </FontText>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {/** 상품섹션 */}
        {isProductSkeletonLoading ? (
          <ProductSkeletonView />
        ) : visibleSectionList.length === 0 ? (
          <div
            className={[styles.productSection, styles.emptySection].join(" ")}>
            <FontText align="center">
              네트워크가 불안정해요.
              <br />
              다시 시도해주세요
            </FontText>
          </div>
        ) : (
          <div className={styles.productSection}>
            {visibleSectionList.map((item, index) => {
              const divId = homeTabNo
                ? `tabSectionList_${item.no || index}`
                : `SectionList_${item.no || index}`;
              return (
                <div key={index}>
                  {[1, 2].includes(item.view_type || 0) && (
                    <>
                      <div
                        className={[styles.contentTitle, styles.view1and2].join(
                          " ",
                        )}>
                        <FontText fontSize={18} fontWeight={500}>
                          {item.title}
                        </FontText>
                        <div style={{height: 4}} />
                        {!!item.sub_title && (
                          <FontText lineHeight={150} color={COLOR.gray4}>
                            {item.sub_title}
                          </FontText>
                        )}
                      </div>
                      <div>
                        {item.view_type === 1 && (
                          <SectionDetailViewType1 list={item.detail || []} />
                        )}
                        {item.view_type === 2 && (
                          <SectionDetailViewType2
                            list={item.detail || []}
                            divId={divId}
                            setScroll={scrollX => {
                              setScrollHomeSection([
                                ...scrollHomeSection.filter(
                                  _item => _item.divId !== divId,
                                ),
                                {divId, scrollX},
                              ]);
                            }}
                          />
                        )}
                      </div>
                    </>
                  )}
                  {[3].includes(item.view_type || 0) && (
                    <div
                      className={[styles.view3Content].join(" ")}
                      style={{backgroundColor: homeTab?.background_color}}>
                      {(!!item.title || !!item.sub_title) && (
                        <div
                          className={[styles.contentTitle, styles.view3].join(
                            " ",
                          )}>
                          {!!item.title && (
                            <>
                              <FontText fontSize={18} fontWeight={500}>
                                {item.title}
                              </FontText>
                              <div style={{height: 4}} />
                            </>
                          )}
                          {!!item.sub_title && (
                            <FontText color={COLOR.gray888888}>
                              {item.sub_title}
                            </FontText>
                          )}
                        </div>
                      )}
                      {!!item.no && (
                        <SectionDetailViewType3
                          section_data={item}
                          section_no={item.no}
                          setItemDetail={itemList => {
                            // setCombinationModalData({
                            //   isVisible: true,
                            //   itemList,
                            // });
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <Footer />
      </div>
      <SignOnCouponSection />
    </>
  );
}
