import {Svgs} from "@assets";
import {FontText} from "@components";
import {ISelectItemMaterial} from "@data";
import {COLOR, isDefined} from "@utils";
import React from "react";
import styles from "../style.module.scss";

export function MaterialItem({
  data = [],
  setVisibleMaterialModal,
}: {
  data?: ISelectItemMaterial[];
  setVisibleMaterialModal?: () => void;
}) {
  if (data.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.materialItem}>
      <div className={styles.box}>
        <div
          className={styles.titleContainer}
          onClick={setVisibleMaterialModal}>
          <div className={styles.title}>
            <FontText fontSize={16} fontWeight={700}>
              원재료
            </FontText>
          </div>
          <Svgs.QuestionCircleSvg width={20} />
        </div>
        <div className={styles.contentContainer}>
          {(data || []).map((info, index) => {
            if (!info.materialInfo?.title) {
              return undefined;
            }

            const isOrganic = info.organic_yn === "Y";
            const isAntiBioticFree = info.antibiotic_free_yn === "Y";
            const isNatural = info.natural_yn === "Y";
            const isDomestic = info.domestic_yn === "Y";
            const content_percent = Number(
              Number(info.content_percent || 0)
                .toFixed(2)
                .replace(/(\.\d*?[1-9])0+$/, "$1"),
            );

            const badgeList = [
              isOrganic ? COLOR.soilColor : undefined,
              isAntiBioticFree ? COLOR.orange : undefined,
              isDomestic ? COLOR.mintColor : undefined,
              isNatural ? COLOR.purple : undefined,
            ].filter(isDefined);

            return (
              <div key={index} className={styles.item}>
                {badgeList.length > 0 && (
                  <div className={styles.dotContainer}>
                    {badgeList.map((color, j) => (
                      <div
                        key={j}
                        className={styles.dot}
                        style={{backgroundColor: color}}
                      />
                    ))}
                  </div>
                )}
                <FontText fontWeight={500} color={COLOR.gray3}>
                  {info.materialInfo?.title}
                  {content_percent > 0 && ` ${content_percent}%`}
                </FontText>
              </div>
            );
          })}
        </div>
        <div className={styles.bottom}>
          <div className={styles.bottomItem}>
            <div
              className={styles.dot}
              style={{backgroundColor: COLOR.soilColor}}
            />
            <FontText lineHeight={150} color={COLOR.gray4}>
              유기농
            </FontText>
          </div>
          <div className={styles.bottomItem}>
            <div
              className={styles.dot}
              style={{backgroundColor: COLOR.orange}}
            />
            <FontText lineHeight={150} color={COLOR.gray4}>
              무항생제
            </FontText>
          </div>
          <div className={styles.bottomItem}>
            <div
              className={styles.dot}
              style={{backgroundColor: COLOR.mintColor}}
            />
            <div className={styles.text}></div>
            <FontText lineHeight={150} color={COLOR.gray4}>
              국내산
            </FontText>
          </div>
          <div className={styles.bottomItem}>
            <div
              className={styles.dot}
              style={{backgroundColor: COLOR.purple}}
            />
            <div className={styles.text}></div>
            <FontText lineHeight={150} color={COLOR.gray4}>
              자연산
            </FontText>
          </div>
        </div>
      </div>
    </div>
  );
}
