import React from "react";

export default function Svg({
  width = 16,
  fill = "white",
}: {
  width?: number;
  stroke?: string;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={(width / 16) * 15}
      viewBox="0 0 16 15"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4586 0.192956C11.7159 0.45023 11.7159 0.867355 11.4586 1.12463L9.28471 3.29853C8.50299 4.08026 8.36906 5.26447 8.88294 6.18411L12.7005 2.36653C12.9578 2.10925 13.3749 2.10925 13.6322 2.36653C13.8895 2.6238 13.8895 3.04093 13.6322 3.2982L9.81442 7.11597C10.7342 7.6304 11.9189 7.49664 12.7009 6.71467L14.8748 4.54076C15.132 4.28349 15.5492 4.28349 15.8064 4.54076C16.0637 4.79804 16.0637 5.21516 15.8064 5.47244L13.6325 7.64634C12.3335 8.9454 10.3152 9.08693 8.85946 8.07093L8.35271 8.57768C8.34301 8.58739 8.33308 8.59672 8.32294 8.60569L13.0541 13.3369C13.3113 13.5942 13.3113 14.0113 13.0541 14.2686C12.7968 14.5259 12.3797 14.5259 12.1224 14.2686L9.48243 11.6286L7.47025 13.6408C7.0702 14.0409 6.40181 13.9639 6.10313 13.4834L5.00204 11.7121L2.15822 14.772C1.91052 15.0385 1.49368 15.0537 1.22717 14.806C0.960658 14.5583 0.945404 14.1415 1.1931 13.875L4.08388 10.7646C4.15033 10.6931 4.22895 10.6397 4.31358 10.6046L0.255897 4.07701C-0.160634 3.40694 -0.0606264 2.53822 0.497269 1.98032C0.521724 1.95587 0.547301 1.93322 0.573833 1.91237C0.59142 1.88939 0.610725 1.8673 0.631748 1.84627C0.889022 1.589 1.30615 1.589 1.56342 1.84627L1.68583 1.96868L1.6977 1.98032L7.39303 7.67577C7.402 7.66564 7.41134 7.65571 7.42104 7.64601L7.9279 7.13914C6.91251 5.68344 7.05422 3.66568 8.35304 2.36686L10.5269 0.192956C10.7842 -0.0643186 11.2013 -0.0643186 11.4586 0.192956ZM8.55076 10.697L1.6044 3.75059L6.92994 12.3178L8.55076 10.697Z"
        fill={fill}
      />
    </svg>
  );
}
