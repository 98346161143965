import {Skeleton} from "@components";
import React from "react";
import styles from "./style.module.scss";

export function ProductSkeletonView() {
  return (
    <div className={styles.productSkeleton}>
      <div className={styles.title}>
        <Skeleton width={162} height={24} borderRadius={4} />
        <div style={{height: 6}} />
        <Skeleton width={124} height={16} borderRadius={4} />
      </div>
      <div className={styles.itemContainer}>
        {[...Array(4).keys()].map(i => {
          return (
            <div key={i} className={styles.item}>
              <div>
                <Skeleton borderRadius={16} ratio={0.8} />
                <div style={{height: 8}} />
                <Skeleton borderRadius={4} ratio={10} />
                <div style={{height: 6}} />
                <Skeleton borderRadius={4} ratio={8} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function BannerSkeletonView({
  height = 183,
  width = 375,
  borderRadius = 0,
}: {
  height?: number;
  width?: number;
  borderRadius?: number;
}) {
  return (
    <div style={{width, height, borderRadius}}>
      <Skeleton width={width} height={height} />
    </div>
  );
}
